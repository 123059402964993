import imgFloril from '../assets/img/floril-paccha.png'
function Autor() {
  return (
    <div class="container" style={{marginTop:'20px'}}>
      <section id="bibliografia" style={{ "text-align": "center" }}>
        <h2>OLIVIO FLORIL PACCHA P.</h2>
        <input type="image" img src={imgFloril} class="img-fluid" alt="photo" />
        <h3>Sobre mi</h3>
        <p>
          Soy un apasionado escritor y amante de la lectura.
          Mi pasión por la creación de contenido escrito se combina con un interés profundo en la literatura.
          Dedico mi tiempo a explorar nuevas historias y a expresar mis propias ideas a través de la escritura.
          Encuentro inspiración en los libros que leo y disfruto de sumergirme en mundos literarios diversos.
          El amor por la lectura y la escritura es evidente, mi dedicación a compartir historias y conocimiento con los demás.
          Soy un entusiasta de las palabras y un narrador incansable, dispuesto a llevar a los lectores a emocionantes aventuras a través de mis escritos.
        </p>
        <p>Nace en la Parroquia de El Cisne, en el Cantón y Provincia de Loja- Ecuador</p>
        <p>Reside en la ciudad de Loja. Ciudadela «Los Cirpés» N°. 23-74</p>
        <h3>Contacto</h3>
        <p>Phono: Domicilio 2545854// Cell:  091221719</p>
        <p>email: florilpaccha@yahoo.es </p>
      </section>
    </div >
  );
}

export default Autor;
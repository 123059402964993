import Intro from '../pages/Intro';
import Autor from '../pages/Autor';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portada from '../pages/portada';


function RoutesApp() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Portada />}></Route>
                    <Route path="/autor" element={<Autor />}></Route>
                    <Route path="/introduccion/:variable" element={<Intro />}></Route>
                    <Route path="/libropdf/libro.pdf" />
                </Routes>
            </BrowserRouter>
        </div >
    );
}

export default RoutesApp;
import React from "react";

const Intro = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="col-sm-10">
        <object
          data="https://librosloja.com/libropdf/libro.pdf"
          type="application/pdf"
          width="100%"
          height="800px"
        >
          <p>
            Este navegador no soporta la visualización de PDFs. Descargue el
            archivo para leerlo.
          </p>
        </object>
      </div>
    </div>
  );
};
export default Intro;

import './App.css';
import RoutesApp from './component/routes-app';

import NavBar from './component/nav-bar';

function App() {
  return (
    <div  class="mb-3">
      <NavBar></NavBar>
      <RoutesApp></RoutesApp>
    </div>
  );
}

export default App;

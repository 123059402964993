const dataMenu = [
  {
    section: 1,
    title: "INTRODUCCIÓN",
    ref: "/introduccion",
    parrafos: [
      <span>
        La libertad y la democracia, bienes invaluables intrínsecos al género humano, han sido motivo de debate y controversia desde tiempos inmemoriales. Su nivel de logro y realización a nivel universal es consecuencia del proceso de evolución y perfeccionamiento. Cuando los ciudadanos eligen a sus gobernantes, no siempre implica que escogemos un auténtico régimen demócrata; o que ya somos libres en forma cabal: muchos estamos subyugados por dogmatismos ideológicos-políticos o religiosos; o quizá somos cautivos de las redes sociales. Igualmente, la democracia en un estado de derecho, no siempre garantiza elegir a los representantes idóneos a través de votación popular. El mayor o menor grado de libertad y democracia radica en nuestro nivel de crecimiento espiritual y consciencia social.
      </span>,
      <span>
        Muchos líderes populistas de distintas tendencias emplean demagógicamente las categorías: pueblo, democracia, justicia social, fraternidad, igualdad, en sus arengas políticas, y ofrecen “el paraíso terrenal” a cambio del voto. Prometen inescrupulosamente mejorar las condiciones de vida del pueblo en el marco de la libertad y la democracia, pero su accionar es lo contrario: fascistas, como Mussolini; comunistas, fanáticos del partido único como Stalin; golpistas y dictadores como Francisco Franco han justificado en nombre de la libertad su accionar político; mientras el pueblo ha quedado arruinado, sin derecho a indemnización alguna. 
      </span>,
      <span>
        ¡Resulta insólito! Muchos dictadores gozaron de aceptación social y llegaron al poder en forma democrática. Hitler, con su discurso de la superioridad de la raza aria, el 30 de enero de 1933, tomó el gobierno de Alemania con un amplio respaldo popular; Hugo Chávez, con su sueño revolucionario socialista, en los tres períodos obtuvo una alta acogida ciudadana; y en su tercer período alcanzo más del 62% de votantes en Venezuela.  De esta manera, el propio pueblo es el responsable de su suerte, a veces adversa por la indiferencia y limitado empoderamiento ciudadano. Necesitamos el nacimiento de un nuevo y distinto arquetipo político, que nos convoque a realizar la verdadera revolución: aquella que inicia en el propio individuo y trasciende a la realidad social.
      </span>,
      <span>
        Una democracia irreal basada en el desequilibrio de poderes, comúnmente consecuencia de populismos peligrosos ha lesionado la libertad individual y la institucionalidad democrática. La libertad, como derecho inalienable, inherente e imprescriptible de hombres y mujeres, es inaceptable de negociación. Cervantes, en su obra el Quijote establecía: este don precioso, regalo del cielo, es incomparable a los tesoros de la tierra y el mar; y que: “por la libertad, así como por la honra, se puede y debe aventurar la vida; y, por el contrario, el cautiverio es el mayor mal que puede venir a los hombres” … Su preservación y vigencia sólo es factible dentro de una democracia limitada con sus pesos y contrapesos: ello coadyuva al desarrollo social e individual armónico y frena la embestida de megalómanos desequilibrados que pretenden experimentar con sistemas socio-políticos retrógrados. 
      </span>,
      <span>
        Después de la caída del Muro de Berlín y el fin de la Guerra Fría (1989); sumado a ello, las lecciones que nos dejó el aislamiento de la pandemia del covid-19 (2020 2021); y, sobre todo, el inimaginable adelanto científico tecnológico gestado en el planeta que, a decir de muchos, los humanos ya somos poseedores de poderes atribuidos sólo a las deidades. En este contexto, no es posible continuar con dogmatismos: ni de “izquierda” ni “derecha” que han derrochado la energía y la creatividad humana. El diálogo intelectual civilizado de ambas tendencias ideológicas, permitirá tomar sus más acrisolados criterios. Ello posibilitará canalizar nuestro esfuerzo para emprender tareas más trascendentales que concierne al ciudadano universal actual: la preservación del planeta; el desarrollo de las potencialidades psíquicas – espirituales de los seres humanos; y el perfeccionamiento de la justicia, la libertad y la democracia.
      </span>,
    ],
  },
  {
    section: 2,
    title: "LA CONCIENCIACIÓN POLÍTICA CIUDADANA",
    ref: "/conciencia-politica-ciudadana",
    parrafos: [
      <p>
        Aún no existen sistemas socio - políticos perfectos en el planeta, todo se encuentra en construcción y perfeccionamiento.  Conciliar conceptos que sostienen la libre economía de mercado con aquellos del socialismo o comunismo, resulta como pretender conceptuar la “verdadera religión” desde la óptica del sacerdote, pastor, rabino, imán o ateo: todos pretenden tener la razón; indudablemente, el debate sensato y sereno entre líderes devendría en un discernimiento religioso más claro que enrumbe la religiosidad del pueblo. De igual manera, políticos de izquierda y derecha a través de su fogosa oratoria excluyente, han creado muros que parecen infranqueables; aun cuando, algunos de sus principios ideológicos han cambiado con el paso del tiempo, o han sido adoptados indistintamente. Demagogos de ambas tendencias en su intento por captar el poder, se refieren a las categorías: libertad y democracia en forma irresponsable; al decir del politólogo, Rodrigo Borja: “para etiquetar sus ofertas del paraíso terrenal a la vuelta de la esquina”. Y así: fascistas, comunistas, golpistas y dictadores como: Hitler, Mussolini; Stalin, Franco entre otros, en su nombre han justificado su sistema de gobierno, pero su accionar difiere de su acepción semántica. 
      </p>,
      <p>
        El pensamiento de Aristóteles: “El hombre es por naturaleza un animal político", encierra una verdad irrefutable, ya que la política está inmersa en el accionar humano en todas sus formas; por tanto, debo partir diferenciando entre las categorías: “lo político” y “la política”. La primera, es la capacidad racional de acoplar el comportamiento humano en nuestras relaciones artístico - sociales que nos distinguen de los animales. En tanto, la política es la ciencia o método para gobernar el Estado, que comúnmente se orienta hacia la conquista del poder. En otras palabras, la política es aquella doctrina ideológica que procura el liderazgo de un grupo humano para la conducción del Estado, a objeto de asistir al pueblo en la administración de sus bienes y recursos, promoviendo su aprovechamiento y optimización. Además, este término también hace alusión a las obligaciones establecidas por las partes frente a un acuerdo; es decir, las directrices que rige la actuación de una persona o entidad en un asunto determinado.
      </p>,
      <p>
        Por otra parte, el término ideología considerado como: “el conjunto de ideas fundamentales que caracterizan el pensamiento de una persona, colectividad o época, de un movimiento cultural, religioso o político, etc.” (Circulo de Lectores, 2009)1; hace relación a los valores, creencias y principios que configuran nuestra visión del mundo sobre diversos escenarios, lo cual obviamente se encuentra en correlación con el desarrollo intelectual y las tradiciones del individuo.
      </p>,
      <p>
        Si los humanos somos seres políticos que habitamos en sociedad, nos corresponde aprender a convivir colectivamente en sujeción a nuestras convicciones ideologías políticas y religiosas. El respeto deviene del conocimiento de los límites establecidos en las leyes y demás códigos morales que versan sobre la justicia, la libertad y la democracia.  Estos tres tesoros supremos complementarios, son innegociables. Nadie debe descansar y claudicar en su búsqueda, si se hubieren vulnerado o perdido.  Estos bienes soberanos inspiraron a Espartaco, Gandhi, Mandela, Dalai Lama y millones de héroes, heroínas y mártires defensores en sus respectivos contextos históricos.  El propio pensamiento filosófico de Aristóteles (384 – 322 a.C.) que trasciende hasta nuestros días, recalcaba la vigencia de la justicia y la política que serían posteriormente los fundamentos de la libertad y la democracia, cuando expresaba:
      </p>,
      <li>
        La naturaleza arrastra pues instintivamente a todos los hombres a la asociación política. El primero que la instituyó hizo un inmen servicio, porque el hombre cuando ha alcanzado toda la perfección posible, es el primero de los animales, es el último cuando vive sin leyes y sin justicia. En efecto, nada hay más monstruoso que la injusticia armada. El hombre ha recibido de la naturaleza las armas de la sabiduría y de la virtud, que debe emplear sobre todo para combatir las malas pasiones. Sin la virtud es el más perverso y el más feroz, porque solo tiene los arrebatos del amor y del hambre. La justicia es una necesidad social, porque el derecho es la regla de vida para la asociación política, y la decisión de los justos es lo que constituye el derecho (De Ascárate, 2005) .
      </li>,
      <p>
        En este contexto, es vital en el ciudadano la formación de su pensamiento lógico y positivo a través de una educación coherente, alejada de adoctrinamientos y fanatismos, que le permita adoptar una ideología apropiada en cualquier campo del convivir humano. El estudio de la Cívica, Lógica y Ética en el bachillerato han coadyuvado en la formación humanística de la juventud; más no todo currículo en los centros de formación escolar poseen estos contenidos en sus planes de estudios. Es tiempo de reconocer sus beneficios y gestionar su vigencia en todo sistema educativo. Igualmente, las nociones de Filosofía, Psicología y Metafísica resultan importantes en el perfil universitario. Sólo la formación humanística moldea la personalidad del ciudadano/a y coadyuva a configurar un perfil político idóneo; quienes carecen de aquello, parecen excluirse y sentirse indiferentes dentro de su entorno social. 
      </p>,
      <p>
        La ideología, o manera de concebir el mundo y la sociedad constituye el marco referencial social, el cual influye directamente en la consecución de nuestros propósitos, metas y objetivos para alcanzar el tipo de vida que aspiramos. Entonces, un ajuste ideológico-político del ciudadano, es trascendental para la adopción responsable de nuestra opción socio-política y religiosa. Esta formación posee similar importancia al cultivo de los valores humanos en la niñez: contribuye a forjar al ser verdaderamente libre, ético, humano, responsable y feliz. En otras palabras, la concienciación ideológica-política del pueblo, en relación al cultivo de la libertad, la democracia y la justicia, permitirá no solamente una convivencia civilizada; sino además: trascender como raza humana superior.
      </p>,
    ],
  },
  {
    section: 3,
    title: "Derechos Ciudadanos y Democracia",
    ref: "/DerechosCiudadanosYDemocracia",
    parrafos: [
      <p>
        El ser humano como una entidad bio-psico-social, por derecho de consciencia, tiene el privilegio de nacer y desarrollase en libertad. Esta potestad natural básica alcanzada gracias a su proceso evolutivo y lucha consciente, fue codificada en 1789 en La Asamblea Nacional Constituyente redactada en Francia, en la inmortal “Declaración de los Derechos del hombre y del Ciudadano”. La cual, en su Artículo 1, establece: "Los hombres nacen y permanecen libres e iguales en derechos"…  
      </p>,
      <p>
        La Asamblea General de las Naciones Unidas (1948), en su Resolución 217, suscribió la “Declaración Universal de los Derechos Humanos”, inspirado en el texto de la Declaración de Los Derechos del hombre y el Ciudadano referido. He aquí un extracto del histórico texto de tan célebre declaración de connotación internacional.
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/d82KR5sbZDU?start=0&end=305"
          frameborder="0"
          allowfullscreen
          title="Hostiria de los derechos humanos"
          ></iframe>
      </div>,
      <section name="derechosCaracterPersonal">
        <h5>Derechos de carácter personal</h5>
        <li>
          {" "}
          “Todos los seres humanos nacen libres e iguales en dignidad y derechos
          y, dotados como están de razón y conciencia, deben comportarse
          fraternalmente los unos con los otros”.
        </li>
        <li>
          {" "}
          “Nadie estará sometido a esclavitud ni a servidumbre, la esclavitud y
          la trata de esclavos están prohibidas en todas sus formas”. “Nadie
          será sometido a torturas ni a penas o tratos crueles, inhumanos o
          degradantes” ...
        </li>
      </section>,
      <h6>Derechos del individuo en relación con la comunidad</h6>,
      <li>
        “Toda persona tiene derecho a la propiedad, individual y
        colectivamente”.
      </li>,
      <h6>
        Derechos de pensamiento, conciencia, religión y libertades políticas
      </h6>,
      <li>
        “Toda persona tiene derecho a la libertad de pensamiento, de conciencia
        y de religión; este derecho incluye la libertad de cambiar de religión o
        de Creencia, así como la libertad de manifestar su religión o su
        creencia, individual y colectivamente, tanto en público como en privado,
        por la enseñanza, la práctica, el culto y la observancia”.
      </li>,
      <li>
        “Todo individuo tiene derecho a la libertad de opinión y de expresión;
        este derecho incluye el de no ser molestado a causa de sus opiniones, el
        de investigar y recibir informaciones y opiniones, y el de difundirlas,
        sin limitación de fronteras, por cualquier medio de expresión” (Naciones
        Unidas.)
      </li>,
      <p>
        La libertad de actuar y decidir acorde a nuestras propias convicciones,
        reside en nuestro pensamiento soberano; se refiere a la autonomía de
        optar, incluso para la inacción. Pero es obvio, quien no trabaja tendrá
        limitaciones económicas, no poseerá bienes para sí; nadie le dará
        aquello que el individuo se niega a dar. Los límites de la libertad se
        encuentran justo donde inicia el derecho de las demás personas. Al
        respecto, el filósofo británico, John Stuart Mill (1806 -1873), en su
        libro: “Essay on Liberty”, en relación a este derecho humano, establece
        que nadie: gobierno, sociedad, religión, costumbre, tradición, moral, o
        ley, tiene derecho a interferir en la soberanía individual; y que: “la
        única razón legítima por la que una comunidad puede imponer límites a
        cualquiera de sus integrantes es la de impedir que se perjudique a otros
        miembros” (Bisbal,2006).
      </p>,
      <p>
        Por otra parte, democracia, puede sintetizarse con el criterio:<br></br>
        ,
        <em>
          El sistema político basado en el reconocimiento del principio, de que
          toda autoridad emana del pueblo, caracterizado por la participación de
          éste en la administración del estado. Ella garantiza las libertades
          básicas del individuo (expresión, reunión, asociación), así como la
          efectividad de la libre elección para los cargos de gobierno y la
          posibilidad de control, por parte del pueblo de la gestión gubernativa
          (Enciclopedia Salvat).
        </em>
      </p>,
      <p>
        La democracia, con su espíritu de gobierno del pueblo y para el pueblo,
        tuvo su origen en el siglo V a.C., en Grecia. En Atenas, todos los
        hombres libres podían presentar propuestas y tomar parte en las
        votaciones de la asamblea popular. No estaban facultados participar en
        ella los esclavos, los extranjeros y las mujeres. Esta forma de gobierno
        fue pretendida en la Roma antigua, aunque no se logró consolidar
        exitosamente por las características socioeconómicas del imperio. A
        partir de la Edad Media se fue extendiendo paulatinamente en casi la
        totalidad de países del planeta.
      </p>,
      <p>
        La democracia como bien supremo del pueblo, se ha adoptado como la forma
        de gobierno de toda sociedad civilizada. En ciertos estados existen
        sistemas autocráticos, como: China, Corea del Norte, Cuba y Nicaragua,
        entre otros, donde ciertos sectores sociales de aquellas sociedades
        constantemente procuran alcanzar la democracia plena. En algunos países
        musulmanes, aún se observan ciertas dictaduras y régimen teocráticos;
        pero es innegable que la mayoría de pueblos civilizados prefieren una
        sociedad de libertad, derechos y oportunidades. Indudablemente, existen
        democracias más perfeccionadas que otras, lo cual nos alerta que ella es
        un bien social perfectible; y por tanto implica que: todos somos
        responsables de cuidarla, mejorarla y fortalecerla.{" "}
      </p>,
      <p>
        Hoy, cercanos a la tercera década del siglo XXI, la sociedad capitalista
        de libre mercado, acompañada de una moderada intervención estatal,
        parece más propicia para perfeccionar la democracia; pues, este modelo
        socio-político provee mayor convergencia y participación de los diversos
        grupos sociales que conforman el estado. Además, las actuales
        Constituciones democráticas a nivel internacional codifican importantes
        avances en la consagración de los derechos básicos de los ciudadanos: la
        tolerancia al credo político, la libertad de culto y pensamiento, el
        derecho de asociación y libre empresa, etc. No pretendo afirmar que los
        países identificados con el sistema de economía libre posean niveles
        sociales inmejorables; no obstante, ahí se observa un campo más propicio
        para la perfección y la preservación de la libertad y la democracia.
      </p>,
    ],
  },
  {
    section: 4,
    title: "La Derecha e Izquierda Política",
    ref: "/LaDerechaeIzquierdaPolitica",
    parrafos: [
      <p>
        En teoría, el término "derecha" es el modelo que defiende la
        preservación del orden social establecido, pretende mantener los valores
        tradicionales, la protección de la moral y la libertad del individuo. En
        tanto que “izquierda”, se ha definido como aquella posición política que
        tiene como eje central la defensa de la igualdad social. Ambos términos
        fueron acuñados en el fragor de la Revolución Francesa, el 11 de
        septiembre de 1789. Los diputados de la Asamblea Nacional Constituyente
        discutían un artículo para la nueva Constitución. Los adictos al rey
        creían en el poder absoluto del monarca y su potestad de veto a las
        leyes de la Asamblea. Estos se ubicaron a la derecha del presidente de
        la Asamblea. Quienes rechazaban este criterio sostenían que el rey debía
        tener un derecho limitado, y la soberanía debía estar por encima de la
        autoridad real; y la ubicación que tomaron fue a lado izquierdo. En
        síntesis, el término "izquierda" se asoció a la opción de un cambio
        político y social; y "derecha", con quienes se oponían a tales cambios.
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/T_yu5uQadeA?start=16&end=191"
          frameborder="0"
          allowfullscreen
          title="Partidos de izquierda y derecha"
          ></iframe>
      </div>,
    ],
  },
  {
    section: 5,
    title: "La Derecha Política",
    ref: "/LaDerechaPolitica",
    parrafos: [
      <p>
        El término derecha posee menor número de acepciones en relación al
        vocablo izquierda; ello ha dificultado una definición estricta de esta
        palabra en cuanto al entramado político de un País. La primera
        connotación sobre la derecha política se asocia con aquella que defiende
        la preservación del orden social establecido; que aboga por la
        protección de la moral, los principios y las libertades del individuo.
        También se la conoce como ideología conservadora; lo cual significa
        conservar el acervo cultural que proviene de la esencia de la sociedad,
        prescindiendo aquellos rasgos que la degradan. Se sustenta en la
        preservación de los valores de las sociedades, no con un criterio
        simplista; sino con la idea de mantener viva nuestra identidad. Debe
        reconocerse que todo lo que se conoce hoy proviene del pasado: sin
        transmisión no hay cultura y por tanto no hay historia.
      </p>,
      <p>
        En cuanto a la acepción masculina del término derecho, se entiende como
        la facultad de exigir aquello que la autoridad o la ley establece a
        nuestro favor. Otra acepción semántica de esta palabra, es el criterio
        de poseer la característica de ser razonable, justo o legítimo. Con ello
        se deduce que eventualmente ambos términos: derecho y derecha se
        relacionan de alguna manera, con la idea de que estamos facultados a
        poseer o conservar legítimamente un bien, estatus o derecho en forma
        justificada.{" "}
      </p>,
    ],
  },
  {
    section: 6,
    title: "La Izquierda Política",
    ref: "/LaIzquierdaPolitica",
    parrafos: [
      <p>
        En cuanto al significado de izquierda política, existen varios
        planteamientos teóricos en torno a los métodos para alcanzar la igualdad
        social que pondera esta tendencia, mismos que han cambiado a través del
        tiempo. La Izquierda Revolucionaria creía en la revolución armada para
        implantar el Socialismo. Otros menos extremistas proponían una
        concienciación a gran escala de obreros campesinos e intelectuales para
        fundar aquella sociedad justa… No obstante, algunos paradigmas políticos
        de izquierda resultaron contradictorios con el paso del tiempo, dado que
        la manida expresión: “igualdad social”, no ha devenido en justicia y
        libertad; tampoco en calidad de vida. Esto explica que algunas
        izquierdas cambiaron su denominación; mientras otras han pasado a la
        obsolescencia.{" "}
      </p>,
      <p>
        La eterna pugna entre ricos y pobres, el prototipo de mandar y obedecer,
        llamada por los marxistas “lucha de clases”, es antigua como la
        sociedad. Pero el “criterio científico” empleado por la izquierda para
        afrontar la injusticia y exclusión social, data a partir de los aportes
        teóricos de sus directos impulsores: Carlos Marx y Federico Engels. A
        ello se añade el aporte teórico-práctico de Vladimir Ilich Lenín y Mao
        Tse-Tung, y miles de intelectuales en el planeta que han teorizado en
        torno al tema. Estos planteamientos proponen cambiar los aspectos socio
        – políticos y económicos del Estado; es decir, revolucionar la base y
        superestructura de la sociedad, para cambiar el régimen económico de
        producción capitalista e implantar la “sociedad exenta de clases”: el
        comunismo, denominada “fase superior del socialismo”.{" "}
      </p>,
    ],
  },
  {
    section: 7,
    title: "Populismo: Incoherencia ideológica",
    ref: "/PopulismoIncoherenciaIdeologica",
    parrafos: [
      <p>
        Aun cuando resulta difícil configurar un concepto claro sobre esta
        ideología, la demagogia es el principal distintivo de esta tendencia
        política. Otras características comunes del populismo, constituyen: la
        retórica nacionalista; la inculpación a los adversarios de los problemas
        del País; el liderazgo caudillista; el posicionamiento del culto al
        ídolo; la satanización a los políticos de carrera; la suspicacia a las
        instituciones públicas existentes; su capacidad de movilización, etc. Si
        bien, su poder se fundamenta en el pueblo, esencialmente en los
        extractos sociales de escasos recursos económicos; sus líderes prefieren
        ser aceptados por sus electores, en lugar de buscar el progreso integral
        del País.
      </p>,
      <p>
        Inicialmente, el populismo se concebía como una ideología de izquierda,
        defensora del control total o parcial de los bienes del estado, a través
        del estatismo e intervencionismo para alcanzar su conocido discurso de
        justicia social. Por tanto, la diferencia entre el socialismo y el
        populismo de izquierda, reside en que la primera; posee un mayor nivel
        de organización y concienciación teórica a través de la formación
        marxista de sus bases; mientras que el segundo, usa el mismo discurso
        para llegar al poder, sin importarle la verdadera transformación social.
        Modernamente, esta corriente ha desarrollado múltiples matices
        ideológico – políticos, con el manido criterio de luchar por el sector
        marginal, sin descartar los principios capitalistas. Se habla entonces
        de populismos de izquierda y derecha. Muchas figuras políticas
        destacadas pertenecientes a esta línea, poseen seguidores incluso fuera
        de las fronteras de sus países. En la segunda década del siglo XXI, los
        caudillos populistas de derecha: Donald Trump en la USA; Marine Le Pen
        en Francia y Mauricio Macri en Argentina, han sido catalogados por sus
        detractores como pertenecientes a la extrema derecha. Lo incuestionable
        de su sello característico constituye: preservar el poder y hegemonía
        política a través de la popularidad.{" "}
      </p>,
      <p>
        En este contexto, el populismo no puede ser catalogado ideológicamente
        como doctrina de izquierda, derecha o centro, porque existen caudillos
        que pertenecen a todas estas tendencias políticas. Al respecto un
        estudioso del tema, sostiene: <br></br>
        <li>
          Cuando los politólogos escriben acerca del populismo, a menudo
          empiezan por intentar definirlo, como si se tratara de un término
          científico comparable a entropía o fotosíntesis. Esto es un error. No
          existe un conjunto de rasgos que defina exclusivamente a las personas,
          los movimientos y los partidos llamados populistas (…) Como suele
          ocurrir en el lenguaje coloquial, y más aún en el lenguaje coloquial
          político, así también los distintos partidos y personas denominados
          populistas presentan parecidos familiares entre sí, pero no existe una
          serie de características exclusivamente comunes a todos ellos (Judis,
          Cómo la Gran Recesión transformó la política en Estados Unidos y
          Europa. 16 -17.)
        </li>
      </p>,
      <p>
        Entonces, el discurso emotivo cargado de epítetos excluyentes, nos
        anticipa que el perfil del emisor podría pertenecer a los rasgos de un
        populista, a veces psicopático, de quien debemos ser cautelosos. Algunos
        politólogos consideran al populismo “una ideología débil”, que pretende
        implantar en el mundo el criterio de que el pueblo honrado pugna con la
        élite corrupta por sus reivindicaciones. Muchas veces, ciertos líderes
        que se identifican con esta corriente política pueden parecer vinculados
        a ideologías formales o “fuertes”: más el vocabulario frecuente de sus
        discursos saturados de emotividad, en favor o en contra del
        “socialismo”, “anti - imperialismo”, “racismo”, “nacionalismo”,
        “antisemitismo”; entre otros, les delata su perfil. Un gobierno o
        partido populista tiene como estrategia política fundamentar su
        propuesta a través de un discurso mediático-coyuntural atractivo para el
        pueblo, pero su esencia es la manipulación y el engaño. Por ello, el
        término populismo se ha empleado, usualmente como sinónimo de demagogia.
        El politólogo Jan-Werner Müller, sostiene que el populista establece en
        sus proclamas ser el único representante del pueblo; y a sus
        contendores, los considera “ilegítimos”. Similar a cualquier agrupación
        política totalitaria que, en base a las armas pretendía imponer sus
        proyectos políticos “justos” a nombre del pueblo, difíciles de definir y
        cuantificar. Ahora, los populistas pretenden tomar el poder político del
        estado a través del voto popular, considerándose voceros/as de los
        marginados, sin ellos, gane quien gane el pueblo perderá.
      </p>,
      <p>
        La diferencia entre un líder o lideresa perteneciente a una determinada
        ideología formal; sea de derecha, izquierda o centro (excepto las
        doctrinas extremas), y un populista, radica en la forma y fondo del
        mensaje. Los primeros, a menudo proponen una solución incluyente,
        basados en los principios establecidos en su doctrina política y plan de
        gobierno. Sus discursos son moderados e integradores. El populismo
        carece de ideología definida y plan consensuado: es sólo un estilo de
        hacer política y gobernar. Se caracterizan por su mensaje caudillista
        excluyente, basado en una argumentación explosiva llena de calificativos
        que incitan al odio; con un afán subyacente de promover el culto a la
        personalidad. Su retórica ficticia se ha comparado como el curandero que
        pretende sanar a un paciente diagnosticado de una dolencia grave, donde
        su demagogia ilusoria sólo germina en la ignorancia.{" "}
      </p>,
      <p>
        El populismo tuvo su origen en Rusia y Estados Unidos a finales del
        siglo XIX; a partir de aquella época hasta la actualidad, esta corriente
        política se ha desarrollado en muchos países del globo terráqueo. En
        América Latina, luego de las dictaduras anteriores a la década de los
        80, se observa un resurgir de esta tendencia, que algunos estudiosos lo
        denominan Neopopulismo. Su discurso demagógico es básicamente similar al
        populismo: “exaltación al nacionalismo”, “defensa de la población
        vulnerable” y, “la hostilidad a los ´enemigos del pueblo´”. Aun cuando,
        esta corriente no pertenece únicamente a una tendencia de izquierda,
        algunos políticos y estudiosos del tema lo relacionan con el Neo -
        socialismo, seguidores del fascismo italiano; y posteriormente, con el
        Socialismo del Siglo XXI que emergió luego del foro de Sao Paulo de
        1990.{" "}
      </p>,
      <p>
        Existen distintos tipos de populismos, identificables por su discurso
        demagógico - emotivo, que atribuye los males del Estado a variables
        excluyentes. Su tarea en una lid electoral consiste en acusar y
        satanizar a los grupos estigmatizados: “judíos”, “comunistas”,
        “neoliberales” “derechistas”, “yanquis”, “nazis”, “oligarcas”, etc… Se
        niegan a reconocer los méritos de los adversarios políticos y comúnmente
        desconocen la percepción ideológica de la masa. Muddle (1916), sostiene
        que mientras los críticos condenan al populismo por su mediocridad, su
        fortaleza radica en forzar a las élites a discutir problemas que ellos
        prefieren ignorar, y señala que la democracia liberal se equivoca con
        respecto a los derechos de las minorías y el imperio de la ley (The
        Economist) . Es decir, el discurso populista dice conocer la solución a
        los problemas acuciantes de su País; más, cuando el caudillo llega al
        poder, éstos son ignorados o no le confieren la trascendencia
        manifestada.
      </p>,
      <p>
        Indudablemente, el terreno propicio que da origen al populismo es el
        descontento popular mediático de los gobiernos coyunturales. No hemos
        comprendido que, el crecimiento socio - político económico del país
        requiere el compromiso bipartito: mandantes y gobernantes, el cual no
        resulta de la noche a la mañana. Ello implica el establecimiento de
        metas nacionales de largo aliento atinentes a las políticas de estado;
        durante los objetivos de mediano alcance, serán evaluados al final de
        cada período presidencial. Por tanto, la propuesta socio - económico -
        política del candidato estará basada en la realidad social concreta, no
        en criterios antojadizos superficiales; su ejecución, debe poseer
        consensos mínimos y prácticos con énfasis en la educación.{" "}
      </p>,
      <p>
        Por parte del pueblo, la insatisfacción ciudadana en nuestros
        gobernantes no debe llevarnos a creer en la ley del menor esfuerzo, y
        ser arrasados por la verborrea trivial de quienes ofrecen “milagros”. No
        podemos transformarnos en adictos apostadores a la ruleta política
        coyuntural; permitiendo que las ofertas de campaña de boquis sueltos
        politiqueros influyan en nuestra decisión cívica al sufragar. Si a pesar
        de ello, un demagogo ha logrado captar la voluntad popular a través de
        la falacia; y en el proceso administrativo sus ofertas iniciales
        cambian; se maquillan o se adoptan otras distintas a su propuesta
        inicial, es imprescindible la reacción del pueblo. El soberano no debe
        ser burlado: debe ser temido y ensalzado. Cuando acurra lo contrario, el
        pueblo en forma unánime, sin caer en el vandalismo, se levantará cual
        gigante dormido y exigirá la revocatoria del mandato.{" "}
      </p>,
      <p>
        En otras palabras, el advenimiento del populismo radica en la voluntad
        del propio pueblo soberano; y, si su mandato o soberanía radica en él,
        nada consiguen los dictadores y demagogos sin su venia.
      </p>,
      <p>
        Es tiempo de parar la demagogia de los políticos timadores. El pueblo,
        el “monarca sin cetro ni corona”, ¡no debe seguir siendo objeto de
        burlas! más, para su liberación definitiva, se requiere el despertar
        cívico ciudadano a través de su concienciación.{" "}
      </p>,
    ],
  },
  {
    section: 8,
    title: "Caudillos populistas en la historia de Latinoamérica:",
    ref: "/CaudillosPopulistasEnLaHistoriaDeLatinoamerica",
    parrafos: [
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MZYEFNMdxG4?start=12&end=359"
          frameborder="0"
          allowfullscreen
          title="Populismo vs republica."
          ></iframe>
      </div>,
      <p>
        En Latinoamérica, al igual que el resto del mundo, se han sucedido en el
        gobierno de los países caudillos populistas con trayectorias políticas
        controversiales. He aquí una muestra de este tipo de mandatarios durante
        el siglo XX y XXI. <br></br>
        <li>
          Venezuela: Carlos Andrés Pérez (1989 – 1993), Hugo Chávez (1999
          -2013), y Nicolás Maduro (2013- continúa).
        </li>
        <li>
          Ecuador: José M. Velazco Ibarra en sus 5 mandatos, y Rafael Correa
          (2007- 2017).
        </li>
        <li>Bolivia: Evo Morales (2006 - 2019).</li>
        <li>
          Brasil: Getulio Vargas en sus múltiples gobiernos, Lula (2002 - 2006)
          y Dilma Rousseff (2011 - 2016).
        </li>
        <li>Chile: l Michelle Bachelet (2006 - 2010 y 2008 - 2018).</li>
        <li>
          Argentina: Perón (1946 - 1951; 1951 - 1955), Néstor Kirchner (2003
          -2007); y Cristina Fernández de Kirchner (2007 -2015).
        </li>
        <li>Costa Rica: Rafael Ángel Calderón Guardia (1940 - 1944).</li>
        <li>Puerto Rico: Luis Muñoz Marín (1949 - 1965).</li>
        <li>México: Lázaro Cárdenas (1934 - 1940) (Conceptos) .</li>
      </p>,
    ],
  },
  {
    section: 9,
    title: "EXTREMISMO IDEOLÓGIO POLÍTICO Y POPULISMO",
    ref: "/ExtremismoIdeologicoPoliticoYPopulismo",
    parrafos: [
      <h5>La Ultra - Derecha</h5>,
      <p>
        Comúnmente la ultra derecha se encuentra identificada con una ideología
        monárquica, con tendencia al militarismo – nacionalista y expansionista.
        Quienes profesan esta ideología son partidarios de la meritocracia, la
        seguridad y el racismo. Muchos de los grupos de derecha ultra -
        conservadora comparten con la de extrema izquierda sentimientos de
        aversión hacia la homosexualidad, la prohibición del aborto y, la férrea
        disciplina dentro del ámbito social; en cuanto a lo económico, carecen
        de una política definida que parece variar de acuerdo a las
        circunstancias. A veces se observan en estos grupos, colectivos
        religiosos extremistas de diversos credos opuestos a la separación de la
        iglesia con el estado, e inclinados a la instauración de estados
        teocráticos.{" "}
      </p>,
      <p>
        La tendencia política de la derecha radical o “ultraderecha”, no se
        encuentra definida por parte de los politólogos y estudiados del tema,
        debido a la variabilidad de sus principios doctrinarios a través del
        tiempo, como lo señala esta cita:
        <i>
          (…) ni la derecha ni la izquierda política logran dotarse de
          contenidos pragmáticos continuos y relativamente estables. Por
          ejemplo, los liberales eran la izquierda en tiempos de Organización
          Nacional, y son la derecha en tiempos del menemismo. En Europa, la
          derecha era nacionalista hasta la II Guerra Mundial; pero poco después
          creó la Unión Europea, máximo exponente contemporáneo de la superación
          del estado Nacional (A. Rodríguez 90) .{" "}
        </i>
      </p>,
      <p>
        Es decir, el pensamiento ideológico - político está sometido a un
        constante cambio y transformación a través del tiempo. Entonces, es
        iluso aferrarnos a sus principios que por lo general resulta en
        controversia y enemistad. Como bien lo establece un conocedor del tema:
        “Muchas de las posiciones políticas que ahora consideramos de derecha
        fueron de izquierda en otro momento” (O. Rodríguez) . Mientras, los
        detractores de la extrema derecha le atribuyen poseer un discurso
        antidemocrático: “fascista", “nacionalista”, “militarista”, “xenófobo” y
        “racista”.{" "}
      </p>,
      <p>
        La derecha radical se encuentra relacionada con el Tradicionalismo y
        Conservadurismo, lo cual hace relación a la defensa de los valores
        religiosos tradicionales. El primero, se refiere al apego por mantener
        las costumbres y la organización social e institucional del pasado, que
        a veces puede desbordar en fanatismo. La religión constituye el nexo
        entre los miembros de un grupo humano, pero puede resultar en
        controversia con otro de distinta creencia religiosa. El conservadurismo
        por su parte, hace relación a los conservadores aferrados a su cultura e
        identidad étnica, como principio de su desarrollo. Los defensores de
        esta doctrina, sostienen que el orden instituido ha sido establecido por
        Dios; por tanto, es antinatural promover cambios al orden social
        establecido. Por ello, a quienes profesan esta ideología se les ha
        tildado de reaccionarios o contrarrevolucionarios.{" "}
      </p>,
      <p>
        Por otra parte, este paradigma ideológico, está relacionado con el
        Nacionalismo y Proteccionismo. El Nacionalismo, Patriotismo o
        patriotería es aquella doctrina que reivindica el derecho de la
        autonomía de una nación, con apego y particular distinción a todo cuanto
        le pertenece. En tanto el Proteccionismo enfatiza la defensa de los
        países débiles ante los fuertes, defiende la producción nacional, a
        veces con un criterio expansionista que le permita mantener su estatus.
        Por ello se ha vinculado a los extremistas de derecha con prácticas de
        racismo y xenofobia, con el criterio de rechazar lo foráneo y defender
        la “pureza nacional”; al punto de culpar a los extranjeros de la
        delincuencia, desempleo y otros problemas nacionales.{" "}
      </p>,
      <p>
        Los extremistas de derecha se oponen al marxismo – leninismo y
        materialismo dialéctico e histórico; igualmente al denominado
        “socialismo científico”, por considerarlos “incoherentes” y
        “desfasados”. Quienes adoptan la ideología de derecha radical se oponen
        a las doctrinas colectivistas; defienden apasionadamente las
        tradiciones: la propiedad privada, la libertad individual, la libertad
        de culto y de pensamiento. Sin embargo, muchos caudillos de esta
        tendencia extrema, han resultado implacables con sus oponentes. Muchas
        veces, esto ha concluido en dictaduras populistas de diversos matices
        que han lesionado seriamente la libertad y democracia en sus estados.
        Aquello se confirma a través de una muestra de caudillos de ultraderecha
        que gobernaron durante el siglo XX, en América: Augusto Pinochet (1973 -
        1990 en Chile; Alfredo Stroessner (1954 - 1989) en Paraguay; Maximiliano
        Hernández Martínez (1931 - 1944) en El Salvador; Leónidas Trujillo (1931
        - 1961) en República Dominicana; Jorge R. Videla (1976 - 1981) en
        Argentina. En Europa: Benito Mussolini (1922 - 1943) en Italia; Adolf
        Hitler (1933 - 1945) en Alemania. En África: Idi Amin Dada (1971 - 1979)
        en Uganda.{" "}
      </p>,
      <p>
        Esta corriente se fortaleció a mediados del siglo XX, como contraparte
        dialéctica a la implantación del comunismo en Rusia, luego del triunfo
        de la Revolución Bolchevique en 1917. En América actualmente se la ha
        considerado un fenómeno marginal, dado que su influencia política es
        bastante limitada. Su ideario radical va desde promover la superioridad
        racial, hasta atacar a los grupos de distinta orientación sexual. Los
        extremistas de derecha también se identifican con el Ku Klux Klan, y los
        Neonazis. Los primeros como organización secreta, adictos a la
        supremacía de la raza blanca, apareció luego de la guerra de Secesión en
        los Estados Unidos. Aparte del racismo, promueven la xenofobia, el
        antisemitismo, la homofobia, el anticatolicismo y el anticomunismo. Los
        Neonazis por su parte, buscan revivir el nazismo y aplauden las
        prácticas de exterminio hitlerianas. Poseen una representación
        organizada en muchos países, y,{" "}
        <i>
          “Toma prestados elementos de la doctrina nazi, incluyendo el
          ultranacionalismo, el racismo, la xenofobia, el capacitismo, la
          homofobia, el antiziganismo, el antisemitismo, el anticomunismo y el
          antifeminismo buscando de esta manera el inicio del Cuarto Reich”
          (wikipedia) .{" "}
        </i>
      </p>,
      <p>
        En cuanto al Nazismo y Fascismo, ambas conservan estrecha similitud
        respecto a sus presupuestos políticos; en lo atinente a sus ideologías
        absolutistas, ultranacionalistas, antidemocráticas y anticomunistas.
        Algunos estudios han catalogado a estas 2 ideologías como derecha
        populista, lo cual es apropiado por su demagogia clientelar patriotera;
        pero quizá más adecuado resulte identificarla con la derecha radical
        populista, debido a sus características doctrinarias totalitarias. Ambas
        ideologías tienen mucha similitud, incluso sus mentores originariamente
        se relacionaron con el socialismo de sus países. De ello se concluye que
        los discursos demagógicos de la izquierda o derecha populista sólo están
        mediatizados por el clientelismo político.
      </p>,
    ],
  },
  {
    section: 10,
    title: "Nazismo",
    ref: "/Nazismo",
    parrafos: [
      <p>
        Esta palabra proviene de la contracción del término alemán
        Nationalsozialismus o Partido Nacional Socialista Obrero, ideología del
        régimen que gobernó Adolf Hitler en Alemania de 1933 - 1945. Esta
        doctrina se originó en 1920 y creció significativamente por los años 30.
        Su base ideológica se sustenta en el nacionalismo, el racismo y el
        criterio de superioridad de la raza aria. El nazismo como dictadura
        violenta de partido único, inició con una intensa campaña
        propagandística de sus postulados y la exaltación nacionalista
        pangermánica. A esto se suma: el militarismo revanchista, el
        anticomunismo y el antisemitismo. La crisis económica de 1929 coadyuvó a
        cimentar la idea de la supuesta conspiración de judíos y comunistas
        contra el estado alemán. Así pasaron del control de la cultura a través
        de los medios de comunicación, a la instauración de la brutal dictadura
        y persecución de todo tipo de oposición.
      </p>,
      <p>
        Hitler, seguidor del dictador italiano Benito Mussolini, aprovechando la
        frustración alemana por su derrota en la Primera Guerra Mundial
        (I.G.M.), finalmente logró posesionar en el pueblo sus sueños de
        grandeza: “llevar a la gloria a Alemania”. Su cumplimiento requería un
        proceso de expansión a través de la guerra y la limpieza racial de los
        arios alemanes, entonces, rechazó todo el sistema democrático imperante
        y exhortó a sus ciudadanos prepararse para la lucha armada. La primera
        medida fue realizar una “limpieza” de sus inmediatos detractores por
        medio de la Gestapo y, la descalificación a través de su fácil verborrea
        a quienes criticaban su ley marcial. Su pensamiento político lo expresó
        en su libro, “Mi Lucha”, donde exaltaba la valentía y rechazaba el voto
        democrático mayoritario, diciendo: “la mayoría ha sido siempre, no sólo
        abogado de la estupidez, sino también abogada de las conductas más
        pusilánimes; y así como cien necios no suman un hombre listo, tampoco es
        probable que una resolución heroica provenga de cien cobardes” (Hitler,
        Consideraciones Políticas Resultantes de mi Estadía en Viena.) .
      </p>,
      <p>
        El nazismo es considerado un hijo o hermano ideológico del fascismo; ya
        que ambos comparten el nacionalismo y el populismo por su demagogia y
        cinismo. El primero exaltaba la supremacía de la raza aria y el combate
        a los judíos que, a criterio de Hitler colonizaban cautelosamente
        Alemania pretendiendo expandir el comunismo, como lo habían logrado en
        Rusia.
      </p>,
      <p>
        Las características ideológicas del populismo y el nazismo se
        identifican plenamente por su totalitarismo. En el presente Siglo, los
        autores del libro “El engaño populista” al confrontar las
        características de la política nazi con aquellas del “Socialismo del
        Siglo XXI”, sorprendentemente han encontrado clara similitud. Al
        respecto manifiestan: <br></br>
        <i>
          Es casi como si los socialistas del siglo XXI y todos sus seguidores
          intelectuales y políticos hubieran hecho copy-paste (copia y pega) de
          las ideas de Hitler y Mussolini. ¿Cómo se explica esto? La razón es
          que el fascismo y el nazismo son doctrinas colectivistas inspiradas en
          buena medida en el socialismo marxista. Ambas creen defender al
          ‘pueblo’ de los abusos de las oligarquías nacionales y extranjeras. De
          este modo, similar a los socialistas del siglo XXI: tanto los nazis
          como los fascistas detestan la libertad individual, y reivindican un
          rol casi absoluto del estado. A su decir: el partido y su líder
          representan al pueblo y los trabajadores.
        </i>
        <br></br>
        <i>
          Lenín, Stalin, Hitler; Chávez, Mao Mussolini y Castro, por nombrar
          algunos, son, en esencia representantes de la misma ideología
          totalitaria. De hecho, la sigla NSDAP del partido nazi era una
          abreviación del Partido Nacionalista Obrero Alemán
          (Nationalsozialistische Deutsche Arbeiterpartei”. Y Mussolini militó
          en el partido socialista italiano, antes de fundar su propio
          movimiento.” (Kaiser y Álvarez) .
        </i>
      </p>,
    ],
  },
  {
    section: 11,
    title: "Fascismo",
    ref: "/Fascismo",
    parrafos: [
      <p>
        Este movimiento político de extrema derecha de carácter nacionalista
        totalitario, fue fundado en Italia por Benito Mussolini en 1919, después
        de la Primera Guerra Mundial. Esta ideología constituyó la respuesta a
        las constantes huelgas y paros laborales promovidos por socialistas e
        izquierdistas motivados por la revolución rusa de 1917. Mussolini,
        inicialmente contó con limitado apoyo popular hasta que estratégicamente
        dio un viraje radical a la ultra – derecha y, su lucha anticomunista le
        transformó en un caudillo más contundente. De esta manera captó el apoyo
        económico de la burguesía y demás representantes del sistema de
        producción capitalista italiana, empobrecida y anarquizada en aquellos
        años. Su excesivo nacionalismo devino en una especie de idolatría al
        Estado; y, el cumplimiento de su política sólo era posible por medio de
        una dictadura totalitaria. Por tanto, el posicionamiento del culto a su
        personalidad inició con el control absoluto de los medios de
        comunicación.
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/e7iqwokK-xg?feature=oembed?start=0&end=266"
          frameborder="0"
          allowfullscreen
          title="Que es el fasismo y en que se diferencia de la derecha."
          ></iframe>
      </div>,      
      <p>
        Los fascistas dicen rescatar los valores religiosos, pero desconocen los
        derechos humanos consagrados en la Revolución Francesa, son partidarios
        del régimen de partido único; es decir, la disolución de los demás
        partidos y la eliminación de los sistemas electorales democráticos. En
        este contexto, al iniciar la tercera década del Siglo XX, con asombro
        los italianos escucharon la denominada ecuación revolucionaria:
        “Socialismo más nacionalismo igual a fascismo”. Pues, esta ideología
        había surgido:<br></br>
        <i>
          (…) de las entrañas de uno de los sectores de la izquierda europea más
          radicalmente partidarios de la revolución social y la dictadura del
          proletariado. Una corriente que ya se estaba gestando cuando nació
          Mussolini y que fue magníficamente perfilada por Georges Sorel cuando
          exigió la construcción de un socialismo capaz de derrocar al
          capitalismo de la noche a la mañana, un socialismo violento y
          nacional, sin remilgos, para lo cual habría de renunciar al mito de la
          lucha de clases y optar por el mito nacional, el que llega a los
          corazones, el que reclama deberes para con la patria (Bolinaga) .
        </i>
      </p>,
      <p>
        No obstante, la evidente contradicción de principios ideológicos deviene
        en confusión entre sus defensores en el campo político. Incluso, algunos
        estudiosos del tema sostienen que el fascismo no fue una ideología
        política, sino únicamente un conjunto de reglas para el ejercicio del
        poder… La fórmula fascista establecía: “todo en el Estado, nada fuera
        del Estado, nada contra el Estado”. Esta confusión ideológica se deduce
        del discurso del propio Mussolini cuando expresaba: <br></br>
        <i>
          Los fascistas tenemos el valor de descartar todas las teorías
          políticas tradicionales, y somos aristócratas y demócratas,
          revolucionarios y reaccionarios, proletarios y anti - proletarios,
          pacifistas y anti - pacifistas. Basta con tener un solo punto fijo: la
          nación. El resto es obvio”. Esto se complementa con otro extracto de
          su propio discurso: “Hemos creado nuestro mito. El mito es una fe, es
          pasión. No es necesario que vaya a ser una realidad. Es una realidad
          por el hecho de que es un estímulo, una esperanza, una fe; de que es
          coraje. ¡Nuestro mito es la nación, nuestro mito es la grandeza de la
          nación! (Bolinaga) .
        </i>
      </p>,
    ],
  },
  {
    section: 12,
    title: "La Extrema Izquierda",
    ref: "/LaExtremaIzquierda",
    parrafos: [
      <h5>Principios Teóricos del Socialismo y El Comunismo</h5>,
      <p>
        Nadie puede negar la existencia de las clases sociales en que se
        encuentran estratificadas los grupos humanos pertenecientes a un estado.
        Esto es, las condiciones socio-económicas existente entre personas, que
        ha criterio del marxismo se encuentran en pugna por el control de los
        medios de producción… No se puede esconder la explotación laboral
        existente, y descartar la perpetua lucha entre explotadores y explotados
        a lo largo de la historia. Tampoco se puede desconocer, el hecho de que:
        “el dinero se convierte en capital, éste en fuente de plusvalía; y a su
        vez, es fuente de acumulación adicional” (Marx 689) . Aquello da paso a
        la acumulación astronómica de riqueza que insulta la dignidad de los
        desheredados.
      </p>,
      <p>
        A partir de la Revolución Francesa en 1789, se observa el impulso hacia
        la concienciación popular, promovida por politólogos y estudiosos de las
        causas de la desigualdad y demás problemas sociales. Los representantes
        del denominado Socialismo Utópico, aun cuando sus tesis no se
        fundamentan en el materialismo histórico, lograron establecer las bases
        del denominado socialismo, que posteriormente se denominará socialismo
        científico. En este contexto histórico se destacan los importantes
        aportes de Saint - Simon, Charles Fourier y Robert Owen; quienes tomando
        a su vez el criterio de utopistas de la época (Tomas Moro y otros),
        formularon las primeras ideas socialistas. Es decir, se refirieron a la
        creación de sociedades “igualitarias” sin la presencia de clases
        sociales, que luego serán recogidas en las obras de Engels y Marx.{" "}
      </p>,
      <p>
        <b>Saint – Simón,</b>teorizó en cuanto a la evolución histórica de la
        lucha de clases y su influencia en el ámbito económico social. Propuso a
        las élites económicas tomar conciencia de la situación socio-económica
        de los trabajadores, a objeto de construir una sociedad más justa. Su
        ideal social fue: la conformación de un gobierno liderado por las élites
        económicas con atención en los problemas sociales. Los seguidores de su
        escuela influyeron en la expansión del naciente pensamiento de izquierda
        europeo. De aquella época data la crítica a la propiedad privada y la
        burguesía, como antagonista a los intereses de los trabajadores.{" "}
      </p>,
      <p>
        El inglés <b>Robert Owen, </b>otro precursor del Socialismo utópico del
        S. XIX, fue un exitoso industrial reformista; quien atribuía a la
        educación la clave para el cambio de paradigmas, y que según su criterio
        haría posible la edificación de una sociedad armónica y sin conflictos.
        La experiencia adquirida como administrativo de la fábrica Lew Lanark en
        Escocia, fue su éxito. En 1823 propuso un sistema de comunas para
        superar los conflictos sociales en Irlanda, pero sus ideas chocaron con
        los de las élites políticas- económicas y la iglesia. Su discurso
        también puede considerarse feminista; dado su criterio de que la
        igualdad de ambos sexos era indispensable para fundar una sociedad
        igualitaria. Además, develó las falencias de la institución del
        matrimonio y el carácter retrógrado de la religión… Owen, pasó de ser
        inicialmente un exitoso empresario y admirado por su filantropía, a ser
        posteriormente despreciado y calumniado. Aquello, básicamente por haber
        realizado un experimento comunista en Estados Unidos. Al perder su
        influencia en las élites y romper con la iglesia, se vinculó a la clase
        obrera y promovió reformas laborales a favor de los adultos, las mujeres
        y el trabajo infantil (Robert) . Su influencia en los futuros teóricos
        del Socialismo es decisiva por su oposición a la propiedad privada; y,
        además, procurar una sociedad sin propietarios ni intermediarios. Muchos
        le consideran, el primer comunista-anarquista del planeta (quienes creen
        el rol innecesario del estado en la sociedad). Su teoría tuvo
        repercusión en los pedagogos libertarios y los teóricos socialistas
        como: Proudhon, Bakunin, Engels y Marx.
      </p>,
      <p>
        La idea de la posesión comunitaria exenta de propietarios se remonta a
        la antigüedad, pero la historia de la corriente socialista moderna, como
        primera etapa de la sociedad comunista, surgió en Europa como un
        movimiento de los trabajadores durante la Revolución industrial. Los
        aportes al tema, a partir de finales del siglo XVIII e inicios del siglo
        XIX, fueron sintetizados por los alemanes Karl Marx y Friedrich Engels,
        quienes lograron concretarla con el nombre de Socialismo Científico.
        Estos estudiosos sociales debieron sobrepasar tortuosos debates y
        contradicciones, incluso con teóricos de su propia línea, como ocurrió
        con su coterráneo alemán Grün; y el filósofo político y revolucionario
        francés, Pierre Proudhon. Por ello, esta teoría que inicialmente fue
        acuñado por Engels difiere de las corrientes socialistas existentes en
        la época. Sus defensores sostienen que, esta alcanzó tal grado de
        cientificidad, por encontrarse basada en el método científico. Al
        respecto, los autores del libro “Ciencia de la Sociedad”, señalan:
        <i>
          El marxismo es un sistema armonioso de puntos de vista científicos
          sobre las leyes generales del desarrollo de la Naturaleza y la
          sociedad, sobre el triunfo de la revolución socialista, así como de
          las vías que conducen a la construcción del socialismo y el comunismo.
          (G. Shajnazárov, A. Boborykin y Y. Krazy, El Estado Socialista 9) .
        </i>
      </p>,
      <p>
        Esta teoría conocida también como “marxismo-leninismo” ha tenido muchos
        defensores y detractores. La “lucha de clases y la propiedad común” es
        la fundamentación teórica de esta corriente socialista; la cual a partir
        de su concepción ha desarrollado un profuso debate a nivel mundial con
        millones de libros y seguidores en pro y en contra. No obstante, entre
        los teóricos del tema se encuentran pocos obreros o trabajadores. Sus
        tutores lo conforman los “intelectuales de escritorio”, y algunos
        revolucionarios que decidieron tomar las armas para derribar el sistema
        de desigualdad social.
      </p>,
      <p>
        En el sistema socialista, de acuerdo a sus teóricos se sienta las bases
        para la supresión de la propiedad privada sobre los medios de
        producción; por tanto, la clase explotadora es eliminada. Las
        divergencias entre las clases sociales desaparecen, igualmente las
        diferencias entre trabajadores de la ciudad y del campo: la clase obrera
        en sus diversas modalidades; campesinos e intelectuales, etc. La
        instauración del socialismo, creían se iniciaba con la preparación para
        la denominada: “dictadura del proletariado” orientado por su partido,
        que se concretaría en su fase superior, el comunismo. La estructura
        económica está orientada por un Plan Nacional, donde se supone, todos
        los trabajadores tienen asegurado el derecho al trabajo. Con el
        principio socialista: “cada uno según sus capacidades; y a cada uno
        según su trabajo”, se crea las condiciones de mayor productividad para
        pasar al Comunismo, y la superación entre el trabajo intelectual y
        manual (Rosental 429) . Además, aspiraban que el nivel cultural, así
        como la ciencia y el arte, alcancen su pleno apogeo en este sistema,
        dado que todos podrían desarrollar sus talentos y capacidades. El cerco
        capitalista se sustituiría por el socialista; su fin era la extinción
        paulatina del Estado y el triunfo del comunismo a nivel mundial.
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ua7XJfI4yHo?feature=oembed?start=0&end=336"
          frameborder="0"
          allowfullscreen
          title="Extrema izquierda politica."
          ></iframe>
      </div>,
    ],
  },
  {
    section: 13,
    title: "El Legado Socialista de Lenin y Stalin",
    ref: "/ElLegadoSocialistaDeLeninYStalin",
    parrafos: [
      <p>
        A finales del S. XIX, Lenin Vladimir Ilitch Ulianov, fascinado por la
        teoría del socialismo que repuntaba en Europa en la época, fundó el
        primer círculo marxista a fin de promover el estudio de esta teoría y
        dar sus propios aportes teóricos a las masas rusas [*]. A inicios del
        S.XX, la creación del partido Bolchevique que aglutinó trabajadores,
        obreros y campesinos, preparó el camino hacia la toma del poder, bajo el
        liderazgo del caudillo leninista.{" "}
      </p>,
      <p>
        <i>
          [*] Lenin un fanático del marxismo y el Materialismo Dialéctico,
          combatió todo tipo de idealismo y metafísica y todas las formas de
          religión. Entre sus obras puede mencionarse: fundación del periódico
          Iskra (chispa); “El Imperialismo como último Estadio del Capitalismo”;
          “Materialismo y Empiriocriticismo”; “El Estado y la Revolución”, entre
          otros. El hecho de haber fundado en Rusia el primer país socialista
          del mundo con mano dura (la huelga era considerada una traición al
          Soviet, estableciéndose la pena de muerte), hizo que luego de la
          muerte de Lenin, los comunistas del mundo reconocieran al marxismo -
          leninismo como un modelo político a seguir en su lucha por implantar
          el comunismo.
        </i>
      </p>,
      <p>
        La política errática del Zar, Nicolás II; sumado a ello, el desenlace de
        la Primera Guerra Mundial facilitó la caída del zarismo y el triunfo de
        los revolucionarios rusos; y con él, el establecimiento del Primer
        Estado Socialista del planeta en 1917 (Rosental) . Tras la muerte de
        Lenin en 1924, el socialismo se diversificó en varias corrientes de
        pensamiento izquierdista, como: marxismo – leninismo, trotskismo,
        maoísmo, entre otros. Su legado de líder comunista a nivel
        internacional, representa para sus seguidores el genuino marxista que
        llevó a la praxis la filosofía de la “auténtica izquierda
        revolucionaria”.<br></br>
        <i>
          (…) de hecho Stalin levantó una dictadura personal en una economía
          socialista en desarrollo. Creció el omnipotente aparato represivo,
          comenzaron las búsquedas de ‘enemigos del pueblo’, se implantó la
          práctica de presionar a la gente para que se calumniara a sí misma y a
          otros inocentes. A menudo no había opción: o ser verdugo o ser
          víctima. Y parecía que el organizador principal no tenía nada que ver
          con las múltiples delaciones recíprocas, los procesos judiciales
          demostrativos y las ejecuciones de los ‘enemigos del pueblo’.
        </i>
        <br></br>
        <i>
          Stalin exterminó a millones de los mejores hijos del País, de los más
          talentosos y capaces. Aún falta precisar la verdadera magnitud de las
          pérdidas. El poder personal del caudillo, caprichoso e incontrolado se
          extendió a todas las esferas de la vida: la economía y la ciencia, la
          literatura y el arte. (Popov 43) .{" "}
        </i>
      </p>,
      <p>
        La izquierda radical vinculada al comunismo se enmarca en un sistema de
        gobierno donde se identifican dos clases sociales: gobernantes y
        gobernados; es decir, quienes detentan el poder, y el pueblo
        empobrecido. La persecución es el método de sosegar y encasillar la
        aspiración popular a su forma dogmática de entender la sociedad. Los
        bienes de producción son propiedad del Estado, el cual planifica y
        controla todo. Las personas constituyen “números” carentes de criterio
        deliberante. Quienes osen discrepar con las políticas del Estado o
        pretendan reclamar democracia al régimen, son considerados traidores, y
        por tanto, perseguidos a través del aparato represivo del estado.
      </p>,
      <p>
        Las aspiraciones fundamentales de los comunistas constituían: la toma
        del poder político total por parte del buró o partido socialista o
        comunista para dirigir el estado y, la entrega de los medios de
        producción a los obreros, trabajadores y campesinos. Éstos deben ofrecer
        el producto total de su trabajo al estado y evitar que los “burgueses” u
        “oligarcas” se apropien de la plusvalía que produce. Ello busca la
        eliminación de todas las formas de estratificación social existente en
        la sociedad capitalista. Pero los/as antípodas ponemos en duda la
        supervivencia del comunismo, y hemos realizado múltiples
        cuestionamientos a sus defensores, sintetizados en los siguientes
        argumentos: ¿Se encuentra la plusvalía del trabajo obrero en el sistema
        comunista bien administrado por el estado? ¿Se ha mejorado de verdad la
        calidad de vida de la mayoría de la población con este sistema? ¿No
        existen burócratas pipones (personas que perciben salario sin trabajar)
        en el estado socialista? ¿Se ha eliminado la corrupción a todo nivel en
        los estados socialistas? Las réplicas de sus adeptos frente a estas
        interrogantes comúnmente son silenciadas, desviadas o disimuladas con la
        frase: “aquello se va mejorando en el proceso”.{" "}
      </p>,
      <p>
        Al evaluar el nivel de vida de la gente en los países que adoptaron la
        alternativa comunista es claro observar poco avance en el ámbito social,
        y algunos retrocesos en ciertos campos. Si hiciésemos un ejercicio
        mental retrospectivo general en torno a la historia de la URSS. [*], sin
        la Revolución Bochevique de 1917 que ensalzó al Socialismo, se podría
        suponer que: a) Los zares hubieran continuado en el poder, es decir, no
        se hubiese adoptado el socialismo; b) Los movimientos obreros y
        trabajadores hubiesen luchado contra la explotación laboral y habrían
        alcanzado unas pocas reivindicaciones sociales; c) No hubiese ocurrido
        la hambruna extrema registrada en la historia socialista rusa llamada:
        “Holocausto ucraniano”, acaecida en los años 1932 -1933; d) No hubiera
        surgido la figura de Stalin, el déspota dictador que persiguió y
        exterminó a todos quienes se oponía al socialismo soviético; y e) con
        seguridad, Hitler no hubiera invadido Rusia; tampoco se hubiese
        sacrificado millones de ciudadanos rusos en la S.G.M… Mientras los
        defensores del socialismo tendrían múltiples razones para justificar su
        vigencia de más de 7 décadas en la URSS.; y quizá señalarían que el
        protagonismo ruso era de suma trascendencia, porque gracias a su
        liderazgo militar a nivel internacional, permitió el equilibrio político
        entre el mundo socialista y capitalista.<br></br>
        <i>
          [*] El 26 de diciembre de 1991 se disolvió la Unión Soviética como
          superpotencia del bloque socialista, y fue reconocida como Rusia a
          nivel internacional. Este colapso económico - territorial se produjo
          por las reformas (Perestroika) propuestas por Mijaíl Gorbachov, debido
          a la decadencia económico – política del país socialista. El traspaso
          de la economía socialista a la de libre mercado (similar a China),
          tuvo fuerte resistencia de los conservadores del Partido Comunista, y
          marcó el fin de la Guerra Fría. El rápido intento de transición
          provocó traumas en el entramado industrial y agrícola del País; lo
          cual devino en paralizaciones, inflación y aumento de la pobreza con
          las consiguientes protestas sociales.
        </i>
      </p>,
    ],
  },
  {
    section: 14,
    title: "La Izquierda Revoluciona en la Guerra Fría",
    ref: "/LaIzquierdaRevolucionaenlaGuerraFria",
    parrafos: [
      <p>
        Al concluir la Segunda Guerra Mundial, con el triunfo de los aliados
        sobre los países del eje y la caída de Berlín en 1945, el planeta se
        polarizó en torno a las Superpotencias vencedoras. El bloque de países
        socialistas encabezados por la URSS; y las naciones pertenecientes al
        mundo libre, vinculadas al sistema capitalista liderados por Estados
        Unidos de Norteamérica. La rivalidad entre la libre economía de mercado
        y el marxismo–leninismo se enfrentaron por varias décadas, en una
        conflagración no declarada, denominada “Guerra Fría”. Los marxistas
        fundaron movimientos y partidos políticos; e incluso grupos clandestinos
        armados a fin de extender e implantar su ideología a nivel planetario.
        De esta manera se inició la carrera armamentista sin precedentes en la
        historia usando astronómicas cantidades de dinero para la defensa; ello
        requirió sofisticados sistemas de espionaje: donde lo real se confundió
        con la ficción.{" "}
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ZeGOmJMVqbU?start=30&feature=oembed?start=0&end=256"
          frameborder="0"
          allowfullscreen
          title="Socialismo el socialismo explicado en dos minutos."
          ></iframe>
      </div>,
      <p>
        A mediados del siglo XX surgen los denominados movimientos y partidos
        políticos de “extrema izquierda”, “izquierda revolucionaria”, o
        “izquierda radical”, identificados con el marxismo que pugnan por
        implantar el Socialismo – Comunismo en algunos países. El caldo de
        cultivo para la profusa difusión de esta ideología fue la marginación,
        la corrupción y la pobreza imperantes básicamente en los países
        subdesarrollados. En Cuba de 1959, el movimiento guerrillero liderado
        por Fidel Castro derrocó al dictador Batista e implementó la dictadura
        socialista de partido único, que duró alrededor de 48 años en el poder.{" "}
      </p>,
      <p>
        La experiencia latinoamericana aprendida de aquellos países que
        adoptaron la alternativa socialista nos da cuenta del fiasco de sus
        medidas sociales, económicas y políticas… Así, el proletariado no ha
        eliminado “la clase burguesa” como se aseguraba; sólo hubo cambio de
        roles: “los camaradas” que surgieron del buró del partido se
        convirtieron en la nueva clase privilegiada. El excesivo control, el
        dogmatismo, la mordaza y el atentado a las libertades han terminado con
        la crítica constructiva, la confrontación de ideas y el debate
        inteligente. En la mayoría de regímenes socialistas sus asambleístas o
        parlamentarios no les es permitido oponerse a las políticas del buró o
        caudillo; quienes así proceden son tildados de traidores a la Patria y
        perseguidos.{" "}
      </p>,
      <p>
        En África, así como en América latina la efervescencia socialista de
        finales del siglo XX fue disminuyendo poco a poco; y los focos
        revolucionarios que han sobrevivido están negociando la paz. La figura
        del guerrillero de la década de los 60s, 70s, y 80s, han perdido
        credibilidad; sus símbolos y emblemas fueron arrumados, incluso
        detestados por miles de arrepentidos. Las juventudes revolucionarias
        confundidas de estas décadas, no lograron su cometido. Su vano
        sacrificio dio paso a gobiernos populistas o dictaduras militares, la
        mayoría de derecha, con la consiguiente persecución y muerte de muchos
        idealistas de izquierda. Era la cara opuesta de las sociedades
        comunistas, donde se perseguía a quienes pensaban diferente; es decir,
        era la réplica de lo que ocurre en esos regímenes con otro discurso [*].
        <br></br>
        <i>
          [*] Algunas dictaduras sanguinarias en América Latina se caracterizan
          por la toma del poder a través de golpes de estado, persecución a la
          guerrilla izquierdista, y delitos de lesa humanidad. Entre las más
          conocidas, anotamos: En Argentina, Jorge Rafael Videla (1976 – 1981),
          caracterizada por las desapariciones forzadas, asesinatos de civiles y
          quema de literatura de opositores. En Uruguay, el gobierno estuvo
          conformada de una junta militar y civiles presidida por Juan María
          Bordaberry (1973 -1976). En Chile, Augusto Pinochet Ugarte (1973 –
          1990), tomó el poder luego del golpe de Estado tras la muerte del
          Socialista Allende en 1973, se caracteriza por perseguir básicamente a
          los grupos de izquierda. En Bolivia, la dictadura del general Hugo
          Banzer (1971 - 1978). En Paraguay, el general Alfredo Stroessner (1954
          -1989), gobernó durante treinta y cinco años, siendo reelecto 8 veces
          por ser el único candidato en elección. En el Perú, Alberto Fujimori
          (1990 - 2000), el mejoramiento de la economía fue eclipsada por la
          censura de la prensa, persecuciones y delitos de lesa humanidad. En
          Nicaragua, Anastasio Somoza (1967 -1972) (1974 – 1979).{" "}
        </i>
      </p>,
      <p>
        La juventud universitaria fue azuzada por una especie de moda
        revolucionaria marxista hacia la construcción del nuevo sistema social,
        exenta de explotadores y explotados*. Mas, aquella retórica izquierdista
        no llegó a la praxis. Sus ideólogos a diferencia del Che, eran
        intelectuales de oficina. Esta aclamación al sacrificio injustificado
        acaeció en dictaduras sangrientas que llevó al retroceso de la
        modernización política en los países subdesarrollados; el nivel de vida
        de muchos marginados se deterioró aún más. Al respecto el escritor y
        novelista peruano, Vargas Llosa, expresa:<br></br>
        <i>
          En Cuba, Fidel Castro (1959 – 2008), impuso un régimen comunista
          dictatorial de partido único, controló los medios de comunicación y
          eliminó todo tipo de disidencia en la isla.
          https://de10.com.mx/top-10/2017/01/16/10-dictadores-que-atemorizaron-latinoamerica.
        </i>{" "}
        <br></br>
        <i>
          La revolución cubana que el Che Guevara ayudó a forjar, luego de una
          gesta de la que fue el segundo gran protagonista, ofrece ahora un
          aspecto patético, de pequeño enclave opresivo y retrógrado, cerrado a
          piedra y lodo a toda forma de cambio, donde la brutal caída de los
          niveles de vida de la población parece ir en relación directamente
          proporcional con el aumento de las purgas internas (…).
        </i>
        <br></br>
        <i>
          Por ello, y mucho más, el balance político y moral de lo que Ernesto
          Guevara representó – y de la mitología que su gesta y sus ideas
          generaron – es tremendamente negativo y no debe sorprendernos la
          declinación acelerada de su figura. Ahora bien, dicho todo esto, hay
          en su personalidad y en su silueta histórica, como en las de Trotski,
          algo que siempre resulta atractivo y respetable, no importa cuán
          hostil sea el juicio que nos merezca la obra. ¿Se debe ello a que fue
          derrotado, a que murió en su ley, a la rectilínea coherencia de su
          conducta política? Sin duda. (Vargas Llosa) .
        </i>
      </p>,
      <p>
        Miles de emigrantes empobrecidos, perseguidos y desplazados por el
        sistema, huyen de aquellos países en procura de mejores condiciones de
        vida. Mientras algunos fanáticos, por ignorancia o conveniencia, aún se
        niegan a comprender que la libertad y la democracia son el oxígeno para
        el emprendimiento, el trabajo y la prosperidad de la población. Una
        sociedad en democracia incluyente tolera la criticidad y es contraria a
        la egolatría; por tanto, debemos estar claros que ningún partido
        político, menos un “mesías” debe imponer su ideología verticalmente *.
        La historia ha demostrado que otorgar poderes ilimitados a un caudillo
        deviene en agresión y violencia social. Un cubano crítico del castrismo,
        analiza como el régimen ha degradado a sus adversarios políticos, e
        incluso a sus propios correligionarios: <br></br>
        <i> * El subrayado es nuestro</i>
        <br></br>
        <i>
          (…) Miles de los que antaño aplaudieron en la Plaza de la Revolución
          los largos discursos de Fidel Castro o pelearon en guerras civiles en
          África, hoy sobreviven como pueden… Ahí están, vendiendo periódicos,
          maní o cigarrillos sueltos.
        </i>
        <br></br>
        <i>
          A otros les va peor. La demencia senil los ha consumido y se dedican a
          pedir limosna o hurgar en latones de basura (…) ¿No les dicen nada los
          nombres de Vladimiro Roca, Martha Beatriz Roque Cabello y Félix Bonne
          Carcassés? En los Noventa fueron de los opositores más activos que
          apostaban por la democracia y las libertades políticas y económicas.
          En el verano de 1997 redactaron un lúcido documento titulado La Patria
          es de Todos… Por ese legajo coherente e inclusivo recibieron violencia
          verbal y física por parte del régimen y su Policía secreta. Y fueron a
          la cárcel. Diecisiete años después del lanzamiento de La Patria es de
          Todos, ya ancianos y con un rosario de achaques, a duras penas
          sobreviven. (I. García) .
        </i>
        <br></br>
        <i>
          (*) Algunas dictaduras sanguinarias en América Latina se caracterizan
          por la toma del poder a través de golpes de estado, persecución a la
          guerrilla izquierdista, y delitos de lesa humanidad. Entre las más
          conocidas, anotamos: En Argentina, Jorge Rafael Videla (1976 – 1981),
          caracterizada por las desapariciones forzadas, asesinatos de civiles y
          quema de literatura de opositores. En Uruguay, el gobierno estuvo
          conformada de una junta militar y civiles presidida por Juan María
          Bordaberry (1973 -1976). En Chile, Augusto Pinochet Ugarte (1973 –
          1990), tomó el poder luego del golpe de Estado tras la muerte del
          Socialista Allende en 1973, se caracteriza por perseguir básicamente a
          los grupos de izquierda. En Bolivia, la dictadura del general Hugo
          Banzer (1971 - 1978). En Paraguay, el general Alfredo Stroessner (1954
          -1989), gobernó durante treinta y cinco años, siendo reelecto 8 veces
          por ser el único candidato en elección. En el Perú, Alberto Fujimori
          (1990 - 2000), el mejoramiento de la economía fue eclipsada por la
          censura de la prensa, persecuciones y delitos de lesa humanidad. En
          Nicaragua, Anastasio Somoza (1967 -1972) (1974 – 1979). En Cuba, Fidel
          Castro (1959 – 2008), impuso un régimen comunista dictatorial de
          partido único, controló los medios de comunicación y eliminó todo tipo
          de disidencia en la isla.
          https://de10.com.mx/top-10/2017/01/16/10-dictadores-que-atemorizaron-latinoamerica.
        </i>
      </p>,
      <p>
        Los países que han adoptado el socialismo tras una revolución armada o a
        través del voto popular, luego de vivir sus experiencias a veces
        traumática, han vuelto al cause democrático en lapsos de tiempo
        relativamente cortos. Pues, cuando una sociedad carece de democracia; o
        se ha impuesto restricciones a las libertades, siempre habrá
        confrontación. La revolución requiere otro proceso de cambio, la cual
        inicia con nuestra propia concienciación individual y el cumplimiento de
        nuestras obligaciones cívicas sin presión ni adoctrinamiento. Este
        cambio de roles democráticos y ciudadanos iniciará con una innovación
        curricular en el sistema educativo que permita impulsar el diálogo
        genuino, hacia la adopción de compromisos ciudadanos conscientes.{" "}
      </p>,
      <h5>Insurgencia Extremista </h5>,
      <p>
        El término insurgencia hace relación a aquella manifestación o rebelión
        violenta de rechazo a la autoridad establecida. De acuerdo a los
        propósitos que se persigan y el nivel de beligerancia, varía
        significativamente. Este tipo de subversión no hace alusión a una huelga
        o un amotinamiento civil pasajero; se refiere a aquel tipo de
        resistencia armada, o insurrección tendiente a deponer a un gobernante o
        cambiar el sistema social vigente. Generalmente, la insurgencia está
        organizada por uno o varios líderes que enfrentan de manera organizada a
        la autoridad política nacional o extranjera instituida. La estrategia
        insurgente pretende enfrentar a las fuerzas convencionales del estado en
        forma efectiva a través del empleo coordinado de sus fuerzas no
        convencionales. La palabra subversión se refiere al agente capaz de
        alterar el orden establecido: sea de naturaleza moral, política o
        social; es decir, se califica de subversivo a quien pretende descomponer
        el orden público instituido.{" "}
      </p>,
      <p>
        Los estudiosos del tema destacan 3 tipos de movimientos insurrectos: la
        insurgencia, la subversión, y el terrorismo. Comúnmente la insurgencia
        es una rebelión o levantamiento popular violento. Una manifestación de
        rechazo a la autoridad, que puede ir desde la desobediencia civil, hasta
        la resistencia armada. La subversión por otro lado se refiere a aquel
        movimiento revolucionario, al cual se le atribuye la desestabilidad
        política o social de un país. Es decir, los grupos subversivos
        generalmente constituyen aquellos grupos armados irregulares que operan
        al margen de la ley, y pretenden emplear la fuerza para cambiar el
        sistema político en el poder. El terrorismo por su parte es “la forma
        violenta de lucha política mediante la cual se persigue la destrucción
        del orden establecido o la creación de un clima de temor e inseguridad
        susceptible de intimidar a los adversarios o a la población” (Circulo de
        Lectores) .
      </p>,
      <p>
        La insurgencia y subversión casi siempre está vinculado a grupos
        guerrilleros de extrema izquierda; y en menor escala se observa a
        agrupaciones de derechas. Su mensaje de lucha: “con y por el pueblo”,
        pretende captar la simpatía de las masas marginadas con quienes se
        identifican. Pero la credibilidad de los insurgentes comúnmente
        disminuye a medida que se ejecutan actos terroristas como medida extrema
        para la consecución de sus objetivos, los cuales son repudiados por la
        mayoría de la población.
      </p>,
      <h5>Grupos Guerrilleros</h5>,
      <p>
        La razón de la lucha guerrillera es básicamente de carácter ideológica;
        su demanda es imponer un determinado sistema político, económico –
        social, e incluso religioso en una región o país determinado. La
        guerrilla posee una táctica militar de ataque a través de sus fuerzas
        irregulares, poco numerosas y dispersas en un terreno bien conocido sin
        ser localizados, comúnmente liderados por un “comandante”. Los
        guerrilleros se subdividen en grupos pequeños; su estrategia es fustigar
        al enemigo uniformado a través de ataques sorpresivos y rápidos. Sus
        blancos no constituyen la población civil, de ahí su diferencia con el
        terrorismo. Son expertos en la intercepción e infiltración de los
        sistemas de comunicación, en el campo enemigo; así como la destrucción
        de la infraestructura del estado y la confiscación de armas y
        provisiones gubernamentales.{" "}
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/DASG03lMX-o?feature=oembed?start=55&end=510"
          frameborder="0"
          allowfullscreen
          title="Movimientos Guerrilleros en LATAM"
          ></iframe>
      </div>,
      <p>
        Los rebeldes revolucionarios que adoptan la opción de la guerra de
        guerrillas se basan en el criterio de compensar su inferioridad militar
        por medio de la lucha irregular. Su estrategia de guerra planea
        debilitar al enemigo a través de una serie de ataques de bajo nivel; en
        tanto su entrenamiento insurgente les permite replegarse y ocultarse
        luego de cada ataque, con ello creen ganar tiempo hasta lograr cotejar a
        las fuerzas regulares del estado para obtener la victoria definitiva. La
        actividad guerrillera fue ejercida desde tiempos inmemoriales en las
        distintas culturas: en diversas modalidades lo usaron los bárbaros,
        romanos, árabes y españoles; e incluso se empleó en el antiguo pueblo
        hebreo hace miles de años.{" "}
      </p>,
      <p>
        Los grupos armados regulares se han enfrentado a otros de carácter
        irregular en distintos contextos históricos, tales como: la campaña
        militar por la independencia americana; la revolución cubana que llevó a
        Fidel Castro al poder; la Vietnamita que logró derrotar al ejército
        norteamericano. Estos ejemplos de conflagraciones pueden señalarse como
        enfrentamientos bélicos donde se involucran las fuerzas armadas
        regulares contra tropas irregulares. En tanto, las operaciones bélicas
        de la insurgencia de las FARC en Colombia, y Sendero Luminoso en el
        Perú, constituyen otra muestra de la actividad guerrillera perteneciente
        a la línea del marxismo – leninismo o maoísmo con la presencia de actos
        terroristas.{" "}
      </p>,
      <p>
        En Latinoamérica, los movimientos guerrilleros se han vinculado a la
        extrema izquierda de orientación marxista; mismos que se fortalecieron
        después de la Segunda Guerra Mundial, concretamente en el transcurso de
        la guerra fría [*]. Sus motivaciones ideológicas enfatizan la necesidad
        de proscribir la inequidad social que sufren amplios sectores de la
        población. El proselitismo ideológico y demás “actos compasivos” con los
        sectores sociales empobrecidos donde operan, les permite contar con la
        simpatía de esta población y le confiere mayor sentido a su lucha.
        Ernesto Guevara sostenía: “Es importante destacar que la lucha
        guerrillera es una lucha de masas, es una lucha del pueblo: la
        guerrilla, como núcleo armado, es la vanguardia combatiente del mismo,
        su gran fuerza radica en la masa de la población”
        (https//latinoamericanos.file.wordpress.com.) . <br></br>
        <i>
          [*] Guerra no declarada entre el bloque Capitalista y Comunista que se
          desarrolló después de la S.G.M., y concluyó en 1989. La competencia
          entre las 2 potencias (USA y U.R.S.S) por el liderazgo mundial; la
          anexión de países aliados; y la consecución de mercados, devino en una
          pugna política - ideológica de impredecibles consecuencias. Sus
          características primordiales fueron: la carrera armamentista, el
          espionaje y sabotaje.
        </i>
      </p>,
      <p>
        Las nuevas democracias recién consolidadas en Latinoamérica (a un siglo
        de su independencia del coloniaje europeo), sufrieron las secuelas de la
        SGM. La mayoría del pueblo económicamente limitado tuvo que experimentar
        la explotación laboral por parte de algunos terratenientes, empresarios
        y demás acaudalados inconscientes, lo cual coadyuvó la expansión
        marxista. Los Estados Unidos de América en respuesta a la difusión del
        comunismo internacional intensificó su política pro-imperialista, a
        objeto de consolidar su hegemonía mundial. El Pentágono y la C.I.A.,
        coherentes al Plan Marshall y la Doctrina Truman, procuraban impedir la
        intromisión del comunismo en Europa y el Nuevo Continente, empleando
        diferentes estrategias para frenar el avance de esta ideología en el
        planeta. En este contexto, más allá del método equívoco empleado por la
        guerrilla, los defectos y limitaciones de muchos de sus líderes
        guerrilleros, debe reconocerse su patriotismo, desprendimiento y tesón
        por combatir la injusticia social.
      </p>,
      <h5>La Narco - Guerrilla</h5>,
      <p>
        Cuando los postulados de justicia social de algunos grupos guerrilleros
        se mezclan con las astronómicas ganancias del narcotráfico, estos
        ideales desaparecen. La lucha quijotesca de equidad social que ciertos
        grupos rebeldes dicen defender se degenera, por loable que sean sus
        fines. Entonces, diversas motivaciones imprevisibles como aquellas de
        carácter económica - política, han llevado a los guerrilleros a
        asociarse con otros sectores que operan al margen de la ley como el
        narco-tráfico. La discusión en torno al surgimiento de la
        narco-guerrilla colombiana ha señalado que la exigencia común, tanto
        para los mafiosos como para los subversivos de izquierda, se
        evidenciaron en el rechazo a la extradición que venía impulsaba del
        gobierno en la década de los 80. A ello se suma, las necesidades
        logísticas que uno y otro sector requerían para operar. Otros criterios
        señalan que, la aparición del “Narco-paramilitarismo” de derecha,
        contrarios a los insurgentes de izquierda, llevaron a éstos a pactar con
        la mafia. Es decir, la narco - guerrilla surgió por variadas
        motivaciones coyunturales, mismos que carecen de definición política e
        ideológica.{" "}
      </p>,
      <p>
        En este contexto, se ha afirmado que, la propia Revolución Cubana ya
        estuvo salpicada del narco – tráfico. Fidel Castro luego del desembarco
        - naufragio del yate Granma y el ataque sorpresivo del ejército de
        Batista, quedó debilitado en su ascenso a la Sierra Maestra, con una
        tropa guerrillera reducida carente de todo. Entonces, se dio cuenta que
        su única alternativa era aceptar la colaboración de Crescencio Pérez, un
        productor de marihuana de la zona, quien le envió a su arriero Guillermo
        García Frías, experto en acarrear los cargamentos ilegales para que le
        ofreciera ayuda. El mafioso aparte de brindarle alimentos para sus
        soldados le sirvió de guía; a cambio, los rebeldes debían constituirse
        en su defensa en aquellos lugares inhóspitos donde poseía sus
        plantaciones. Más tarde, Acuña y Cordero lugartenientes de Pérez se
        sumaron a la tropa revolucionaria.{" "}
      </p>,
      <p>
        Otro pacto entre la guerrilla y el narcotráfico que resultó efímero, es
        comentado por otra fuente al establecer que: luego de que las Fuerzas
        Armadas Revolucionarias de Colombia – FARC, sirvieran a José Gonzalo
        Rodríguez Gacha (el “Mexicano”) como vigilantes de sus cultivos de coca
        en el Magdalena Medio, por el año 1983, se convirtieron en enemigos dado
        el robo y destrucción de sus laboratorios. El narcotraficante, para
        llevar a cabo una contundente represalia contra los insurgentes, se alió
        a los ganaderos de la región a fin de financiar a los grupos
        paramilitares. Gacha, llamado “El Ministro de Guerra de Pablo Escobar”,
        contrató mercenarios británicos e israelíes para entrenar a su tropa y
        llegó a poseer un ejército de más de mil subordinados. Así se convirtió
        en el principal comandante del “narco-paramilitarismo”, en su afán de
        vengarse de las FARC y su brazo político – ideológico Unión Patriótica.
        En esta ofensiva se dice cayeron alrededor de 800 miembros de esta
        agrupación de izquierda; y se creó el grupo MAS (muerte a
        secuestradores). Se ha asegurado en algunos documentos serios que, los
        atentados perpetrados a políticos, magistrados, narcos, militares,
        policías y sicarios, adjudicados al Cartel de Medellín, dejo alrededor
        de 5000 víctimas en la llamada “guerra narcoterrorista” (Infobae) .
      </p>,
      <h5>El Terrorismo</h5>,
      <p>
        Constituye aquella forma violenta de lucha ideológica que persigue la
        creación de un estado de conmoción e inseguridad total, para intimidar
        al enemigo y/o población en general, a objeto de anarquizar el orden
        instituido. Es característica del terrorismo el empleo de métodos
        criminales y sanguinarios, que incluyen: matanzas de inocentes,
        torturas, y demás estrategias inhumanas para lograr sus propósitos
        económicos, políticos o religiosos. Los terroristas renuncian competir
        con el Estado a nivel militar; en lugar de ello, lo desafían por medio
        del efecto psíquico de terror que producen sus acciones violentas. Por
        ello, El Departamento de Estado de los EEUU definió a este flagelo
        social, como: “el uso calculado de violencia ilícita para inculcar miedo
        con la intención de coaccionar o intimidar gobiernos y sociedades en la
        búsqueda de objetivos que por lo general son políticos, religiosos o
        económicos” (Ramírez Olga) .
      </p>,
      <p>
        El terrorismo y su método de lucha violenta tiene una vigencia de
        antigua data; ha sido empleado por sectores de izquierda, de derecha,
        grupos religiosos fanáticos, colonialistas, separatistas, entre otros.
        Incluso algunos regímenes en el poder han fomentado el terrorismo de
        estado, donde los actos de terror son perpetrados por el propio
        gobierno. Este tipo de abusos del poder coactivo del Estado se ejecuta a
        través de secuestros, torturas, asesinatos de civiles sin juicio previo;
        o sin las garantías del debido proceso. Para ello se crean
        organizaciones terroristas clandestinas de apoyo al dictador egotista, y
        son justificados por éste a través de una gigantesca maquinaria
        propagandística, en su “lucha patriótica contra la violencia opositora”.
        El terrorismo estatal se genera por medio de diversos mecanismos; donde
        los partidarios del sector gubernamental, constituyen la mejor defensa
        del régimen. Comúnmente su objetivo es propiciar obediencia a través del
        temor; y pretende la efectiva colaboración de la población para cumplir
        su política populista. Aquello es instaurado sistemáticamente a través
        del acoso a la prensa libre, que la considera parcializada y corrupta;
        seguido de amenazas, torturas y persecuciones a los opositores que
        concluye con el establecimiento de un estado criminal.{" "}
      </p>,
      <div>
      <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/A7-ruPOMY78?feature=oembed?start=8&end=434"
          frameborder="0"
          allowfullscreen
          title="Esto es un megalómano"
          ></iframe>
      </div>,
      <p>
        El terrorismo en todas sus formas ha sido condenado en toda la sociedad;
        no sólo porque sus métodos perversos de “lucha” repercuten a inocentes
        que desconocen del conflicto; sino porque la lógica de los mafiosos
        violentos resulta absurda para quienes no compartimos sus ideales. Un
        narco - terrorista comúnmente no posee una identificación ideológica
        definida; excepto, el poder económico y vanagloria. Algunos, en su
        egolatría son orgullosos de su actividad delincuencial. Esto se
        desprende de la frase de Pablo Escobar, capo de la mafia sudamericana,
        en las postrimerías del S. XX, quien al sentirse vinculado al tráfico
        ilegal decía no importarle tal identificación; pero que: “pretendan
        presentarme como socio de la guerrilla, eso sí que no lo acepto, pues
        lesiona mi dignidad personal". Aunque muchos creen que era partidario de
        la guerrilla izquierdista colombiana, como lo advierte esta cita:
        <br></br>
        <i>
          Las conexiones del narcotráfico no se limitaban a las organizaciones
          políticas legales. Abarcaban también el mundo de las guerrillas.
          Escobar, según su hijo, simpatizaba con el M-19 (Movimiento 19 de
          abril), de tendencia socialista. Las relaciones eran tan cordiales que
          el grupo le entregó la espada de Simón Bolívar, que había robado en
          una de sus operaciones propagandísticas (Matínez Hoyos) .
        </i>
      </p>,
      <p>
        Los actos terroristas causantes de zozobra en la sociedad pocas veces
        han sido reconocidos por sus perpetradores, o lo han hecho demasiado
        tarde; no ha habido consciencia del daño causado a sus víctimas y
        familiares. Cuatro años después de la firma de la Paz, entre el Gobierno
        colombiano y el grupo marxista de las FARC, el 15 de septiembre de 2020,
        algunos ex – guerrilleros indultados han reconocido sus actos de terror
        y han pedido disculpas públicas a la sociedad colombiana. ¡Lástima por
        las vidas cercenadas prematuramente! Ingrid Betancouth, una mártir
        secuestrada por la guerrilla ha calificado el martirio del secuestro
        como un asesinato macabro; el cual debe ser proscrito del planeta
        tierra.{" "}
      </p>,
      <p>
        Desde esta óptica, el Siglo XX no sólo fue el espacio de las mayores
        guerras en la tierra y otros flagelos sociales; también fue el escenario
        de sacrificados defensores de la justicia social; aunque no todos
        lograron niveles significativos de consecución. Muchos soñadores de la
        igualdad adoptaron las armas para alcanzar sus objetivos; mientras otros
        emplearon la persuasión y la protesta pacífica para alcanzar su
        cometido. Dentro de los luchadores pertenecientes al primer grupo,
        destacamos: Ernesto 'che' Guevara, Mao Tse Tung, Pedro Antonio Martín,
        “Tirofijo” o “Manuel Marulanda” (creador de las FARC), y Thomas Sankara,
        Presidente de Burkina Faso. Entre aquellos quienes se inclinaron por la
        protesta pacífica, anotamos: Martin Luther King, Nelson Mandela, Tendzin
        Gyatso, (actual Dalai Lama), y Mahatma Gandhi…{" "}
      </p>,
      <p>
        Ernesto Guevara considerado para muchos, un héroe de la reivindicación
        socialista latinoamericana, ayudó a Fidel a tomar el poder en Cuba. Su
        teoría revolucionaria del foquismo con el que creyó propagar el
        comunismo en Sudamérica, fracasó. En contraste, el discurso de M. L.
        King: “I have a dream” (Tengo un Sueño), considerado la mejor pieza
        oratoria de los Estados Unidos, definitivamente tuvo trascendencia
        internacional, y dio resultados positivos. Aquello demuestra que la
        lucha pacífica por medio de la persuasión emprendida por estos grandes
        luchadores sociales: King, Gandhi, Mandela y otros, sí puede transformar
        la sociedad y hacerla más humana.
      </p>,
    ],
  },
  {
    section: 15,
    title: "LIDERAZGO IDEOLÓGICO - POLÍTICO",
    ref: "/LiderazgoPoliticoPrimitivo",
    parrafos: [
      <p>
        De acuerdo a los teóricos marxistas, el proceso de hominización y
        desarrollo científico-técnico acaecido en el planeta, ha surgido de
        acuerdo al avance de las fuerzas productivas y la “lucha de clases”, el
        cual ha dado lugar al aparecimiento de los denominados: modos de
        producción. Quizá no todos compartamos la explicación en torno a la
        manera en que se constituyeron estas formas productivas a través del
        tiempo en el planeta; más resultan didácticas al realizar esta micro
        síntesis, en el proceso de evolución del liderazgo
        ideológico-político-social a nivel universal. Este razonamiento sostiene
        que la sociedad humana en forma inexorable ha evolucionado a través de
        los modos de producción: Comunidad Primitiva, Esclavismo, Feudalismo,
        Capitalismo, Socialismo y Comunismo.{" "}
      </p>,
    ],
  },
  {
    section: 16,
    title: "Liderazgo Político Primitivo",
    ref: "/LiderazgoPoliticoPrimitivo",
    parrafos: [
      <p>
        En la Era del Paleolítico Inicial, el homo sapiens de la Comunidad
        Primitiva aprendió a elaborar sus armas en base de la piedra tallada.
        Ahí se observa la inexistencia de excedentes en estos grupos humanos; es
        decir, los bienes que producían eran de consumo inmediato. Sus
        relaciones sociales eran comunitarias sin la presencia de clases
        sociales, ni explotación laboral. En el Neolítico, dado su experiencia
        vivencial y sus armas mejoradas en base de la piedra pulida; los
        cazadores y pescadores nómadas reconocieron un liderazgo genuino basado
        en la capacidad de defenderse de las fieras y/o enfrentarse físicamente
        a rivales circunvecinos.{" "}
      </p>,
      <p>
        Las vicisitudes de nuestros ancestros humanos en su lucha por su
        sobrevivencia permitieron avances significativos en su proceso de
        aprendizaje. El instinto gregario de las hordas primitivas en relación
        con el desarrollo de sus procesos mentales, permitieron avances en
        ciertos campos. En este contexto, la necesidad propició nuevos saberes:
        el progreso en el proceso de comunicación y lenguaje en general; el
        conocimiento de la semilla y la siembra con la ayuda de rústicas
        herramientas de labranza; el descubrimiento del fuego y una variedad de
        utensilios domésticos; pero, sobre todo, el mejoramiento de sus armas de
        defensa y ataque. Estos descubrimientos ancestrales devinieron en la
        primera división social del trabajo, y el salto desde “la economía
        predatoria de caza y recolección, a la creadora, con cultivo y
        domesticación animal. Es decir, podríamos pensar en una historia antigua
        del hombre, hasta el Mesolítico, y una historia moderna, desde el
        Neolítico, acaso con una media entre ambas” (Pericot 22) .
      </p>,
      <p>
        Las poblaciones en los grupos primitivos se multiplicaron y aparecieron
        tribus dirigidas por líderes primigenios, seleccionados de manera
        natural por sus dones de servicio y criterio de defensa, fuerza y poder.
        Asimismo, tuvieron lugar las primeras disputas y guerras tribales por
        territorios, cuencas hidrográficas y zonas faunísticas… Más tarde,
        surgieron grupos humanos que pretendían dominar a otros, como resultado
        de la aparición del excedente: las exuberantes cosechas y demás
        actividades que factibilizaron la acumulación de bienes. Es decir, el
        surgimiento del sentimiento de codicia coadyuvo la transición de la
        Comunidad primitiva al Esclavismo; y con ello, la aparición de la
        explotación del hombre por el hombre.{" "}
      </p>,
      <p>
        Con el surgimiento de la ambición humana, consecuencia de la aparición
        de los excedentes y la acumulación de bienes, trajo concomitantemente el
        aparecimiento de líderes egocéntricos y megalómanos. La codicia por el
        poder de caudillos vanidosos, devino en una especie de regresión en el
        proceso de hominización en nuestros ancestros. La frase célebre
        atribuida a Plauto: “el hombre es el lobo para el hombre, sino no lo
        reconoce como su semejante” (Asinaria: “Lupus est homo homini, non homo,
        quom qualis sit non novit”), pareció evidenciarse con la instauración de
        la explotación humana por parte de sus congéneres a través de métodos
        crueles y degradantes. Muchos seres humanos fueron sometidos a
        esclavitud y explotados sin recibir pago alguno; a veces constituían
        botines de guerra. Sus horarios y condiciones de trabajo estaban
        establecidos por la voluntad del amo, quien sancionaba al siervo a su
        propia discreción. A veces eran castigados con azotes por pretender
        huir, o incumplir sus tareas; otras veces eran golpeados, marcados o
        encadenados.{" "}
      </p>,
      <p>
        El surgimiento de pequeños reinos trajo la necesidad de expedir leyes
        para legalizar la esclavitud. El estado regulaba y controlaba la vida
        social y política de los ciudadanos a través de normas y prescripciones
        jurídicas; mientras la sociedad dividida en clases sociales fue obligada
        a pagar tributos. La clase dominante constituida por el jefe supremo:
        sea monarca, emperador o cacique, están en la cúspide de la pirámide
        social, compartían sus privilegios con los amos y nobleza en general.
        Estos comúnmente no trabajaban ni pagaban impuestos; más bien impusieron
        una verdadera dictadura al pueblo llano a través de la moral idealista,
        apoyados por el clero secular y el poder militar. Es decir, la presencia
        de líderes laicos, militares y religiosos permitió controlar y someter a
        la masa, no solamente a través de las armas, sino también a través de
        sus convicciones morales.
      </p>,
    ],
  },
  {
    section: 17,
    title: "Tiranos de la Antigüedad: Aparecimiento del Populismo",
    ref: "/TiranosDeLaAntiguedad",
    parrafos: [
      <p>
        El liderazgo político de modelo antiguo, en relación al actual, ha
        cambiado ligeramente de forma; en el fondo, mantiene sus características
        originarias. La manera de apropiarse del poder político ha evolucionado,
        desde las formas más ruines: como el engaño, la traición, el asesinato y
        la guerra; hasta los métodos más sofisticados para atraer la voluntad
        popular, como el lavado de cerebro, mensajes subliminales, etc. Esto no
        significa que en la actualidad se hayan superado aquellas prácticas
        perversas de captar el poder, ya que existen líderes políticos
        procesados por el abuso de autoridad en muchos países, lo cual confirma
        que aún subsisten tiranos y megalómanos como en la propia antigüedad.
        Indudablemente, aquello continuará hasta que nuestra moral evolucione a
        un nivel superior de consciencia; esto nos permitirá analizar la
        importancia de escudriñar el perfil ético y psicológico de nuestros
        líderes postulantes a la primera jefatura del estado.{" "}
      </p>,
      <p>
        La opresión a los débiles que data de imperios milenarios impuso una
        lógica totalitaria, mezcla de dictadura y tiranía. Cada reino e imperio,
        por regla general, tuvo muchas veces uno o varios líderes tiranos;
        gobiernos populistas despóticos de carácter unipersonal amparados en el
        ejército para someter al pueblo. Al respecto Aristóteles expresaba: era
        la profunda corrupción de la monarquía y el despotismo de uno solo, que
        gobierna irresponsablemente a los hombres mejores que él, y reina, no a
        favor del pueblo, sino en el suyo propio. Así se impuso la moral
        esclavista de los faraones, cesares, monarcas y emperadores de los
        imperios antiguos, que consideraba al amo propietario del esclavo, en
        toda la extensión de la palabra. Los vasallos, siervos o cautivos eran
        físicamente considerados propiedad de su rey, reina o amo/a, quienes
        poseían potestad de disponer de ellos/as a voluntad. La “mercancía”
        podía ser vendida, comprada, regalada o canjeada por deudas, sin derecho
        a objeción legal… Muchas potencias antiguas organizaban campañas bélicas
        de “conquista”, para apoderarse de botines de esclavos. Su
        comercialización era libre y lucrativa. La invasión, el sometimiento, la
        toma de trofeos de guerra, y el abuso a la dignidad humana eran vistos
        como normal.{" "}
      </p>,
      <p>
        Las fuentes documentales de reinos e imperios: como Egipto, Mesopotamia,
        Roma, Grecia, Persia, China, Israel, mayas y aztecas poseen diversas
        referencias sobre este tema. En Egipto, los hijos de los esclavos de
        guerra eran propiedad del faraón, no podían ser vendidos a propietarios
        privados; aunque el soberano podía donarlos a sus sacerdotes o
        generales. En ciertos lugares de las exuberantes cosechas y demás
        actividades que factibilizaron la acumulación de bienes en el mundo
        antiguo, los padres podían negociar el cautiverio de sus hijos para
        saldar deudas; o se los sometía a esta condición como castigo por haber
        caído en la delincuencia. Era común que los deudos de un acaudalado
        hereden “lotes de esclavos”; el mayor número de aquella posesión
        constituía su estatus social. En algunos pueblos africanos, mujeres y
        niños eran entregados como rehenes o garantía de una deuda; si el pago
        no se cumplía, éstos pasaban automáticamente a la esclavitud. En Sumer,
        región al sur de la antigua Mesopotamia, el esclavo se desplazaba sujeto
        a un lazo en su nariz. En Roma, los cautivos llevaban un collarín con el
        nombre de su amo; a veces usaban un amuleto contra el mal de ojo. En las
        minas y galeras, eran marcados con hierro al rojo vivo y luego
        encadenados. Asimismo, un mercado importante en la antigüedad constituyó
        Delos, una isla griega del mar Egeo; el geógrafo Estrabón, relataba que
        este lugar era capaz de recibir y dar salida hasta a 10.000 esclavos
        diarios.
      </p>,
      <p>
        Este tipo de sociedad estratificada fue reconocida en todo el mundo
        antiguo: Religiosos, sabios y filósofos, aceptaron como algo natural
        aquel ordenamiento social. Igualmente, el liderazgo de gobernantes
        narcisistas e inhumanos que arrasaban con reinos pequeños y sometían a
        la esclavitud a sus habitantes, era común en este período. Herodoto,
        como guía intelectual de la antigüedad sostenía que, los esclavos al
        poseer sólo su fuerza física y obediencia, están destinados a
        convertirse en «instrumentos vivientes». La Biblia, libro base del credo
        cristiano, justifica el sometimiento de un grupo humano sobre otro,
        cuando establece la Ley Sobre los Esclavos (La Santa biblia 77) .
        Además, relata el cautiverio de José, hijo de Jacob, quien fue vendido
        por sus hermanos y llevado a Egipto a la corte faraónica en condición de
        esclavo. Años más tarde, luego de sufrir muchas vicisitudes, debido a
        sus dotes clarividentes fue liberado y ocupó un alto rango en el
        gobierno; pero más tarde, todo el pueblo hebreo fue sometido a
        esclavitud.
      </p>,
      <p>
        La crueldad con que se trataba a los esclavos por parte de muchos
        caudillos políticos y religiosos no tuvo límites. Los derechos de los
        esclavos eran limitados; en algunas sociedades, los esclavos y
        prisioneros de guerra se ofrecía como ofrenda para calmar la ira de sus
        dioses. La barbarie esclavista llegó a los límites de lo inhumano, lo
        cual devino en controversia entre eclesiásticos y ciertos miembros de la
        nobleza. Así se da cuenta que, en la antigua Roma, el legislador
        Ulpiano, denunció como ilegal que los padres vendieran a sus hijos en
        calidad de esclavos. El emperador Diocleciano (siglo III-IV), decretó
        como delito que un prestamista pueda reducir a la esclavitud a un
        deudor; o, que alguien pudiera venderse a sí mismo en cautiverio como
        pago de una deuda (Wikipedia) .
      </p>,
      <p>
        Por otra parte, una infinidad de obras colosales se construyeron bajo el
        liderazgo de caudillos políticos antiguos en base a la explotación de
        los esclavos. En el Siglo III a.C., en China, miles de siervos y
        presidiarios construyeron la famosa Muralla China. La milenaria barrera
        protectora de alrededor de 4.000 km de longitud: de 6 a 8 metros de
        elevación, con sus torres de hasta 12 metros de altura, fue edificada en
        la frontera con Mongolia durante el reinado de Qin Shi Huandi.{" "}
      </p>,
      <p>
        Según la leyenda, “cada piedra de la Gram Muralla costó una vida humana”
        (Circulo de Lectores 965). En Buhen – Nubia, se edificó una fortaleza de
        adobe hacia 1965 a.C., con muros de 11 m de altura y 5 m de espesor. En
        Grecia, la edificación del Partenón, templo principal de la Acrópolis en
        Atenas (447-432), también utilizó mano de obra de esclavos. En Roma:
        estatuas, acueductos, puentes, edificios; y otras edificaciones se
        construyeron gracias a la mano de obra de los cautivos. El Coliseo
        Romano, donde se exhibían: las luchas entre gladiadores; los combates
        entre fieras; fieras vs humanos; o la naumaquia (espectáculo que
        simulaba un combate naval), también requirió el trabajo de los vasallos.
        De aquellas impresionantes exhibiciones se relata: “sofisticados
        aparatos hacían salir de los fosos paisajes completos, ciudades,
        bosques, montañas con sus decorados” (Larousse 56) ; todo ello, gracias
        al músculo de los esclavos.
      </p>,
      <p>
        Algunos monarcas megalómanos se consagraron como predestinados para
        conquistar y gobernar un reino. Darío I, rey persa (522 - 486 A.C),
        quien conquistó un imperio que se extendía desde Egipto hasta la India,
        para magnificar su gesta grabó en la roca de Behistún -al oeste de Irán-
        en 3 lenguas oficiales del imperio, una inscripción que inicia
        expresando: “yo soy Darío, rey de reyes, rey de los pueblos… Por
        voluntad de Ahura Mazda yo conseguí la realeza…” (National Geographic
        126) . Así mismo, reyes de los desaparecidos imperios: hitita, egipcio,
        griego, romano, y otros de Oriente se consideraron descendientes
        divinos, por lo que decretaron ser glorificados y reconocidos como
        tales; erigieron tumbas y monumentos colosales para ser recordados por
        siempre. En Egipto, a partir de la construcción de las pirámides - 2778
        - 333 años a.C. - miles de esclavos y obreros se emplearon para
        acarrear, instalar, percutir, taladrar y pulir las enormes piedras.
      </p>,
      <p>
        En este contexto, el sometimiento humano fue el pilar de la economía de
        los imperios antiguos y modernos; contradictoriamente también, el origen
        de su ruina. Algunos historiadores sostienen que la esclavitud fue la
        causa mediata de la caída del Imperio romano. Los esclavos convirtieron
        a los romanos en haraganes, ellos ejecutaban las tareas desde las más
        rudas hasta las más delicadas. Entonces, las campañas militares
        imperiales estaban direccionadas a obtener grandes cantidades de
        sometidos; solo la conquista de la Galia por César arrojó un saldo de un
        millón de esclavos. Aquello se replicaba con menor o mayor crueldad en
        otros reinos; sin embargo, la causa del abuso como práctica casi
        generalizada en los gobiernos antiguos, trajo como consecuencia
        reacciones de igual barbarie por parte de los sometidos, como confirma
        el proverbio: “Tendrás tantos enemigos cuantos esclavos poseas”.{" "}
      </p>,
      <p>
        Como efecto de la brutal explotación de monarcas tiranos y demás
        burgueses hacendados, por todas partes surgieron líderes populares que
        reclamaban reivindicaciones para los cautivos. De acuerdo a los textos
        bíblicos: los faraones en Egipto sometieron a servidumbre a los hebreos;
        entonces, Jehová escogió a Moisés y Aron como sus líderes para rescatar
        a su pueblo de la esclavitud; y fue luego de muchas peripecias que ellos
        lograron su libertad. En Grecia, el año 405 más de 20.000 mineros
        esclavos huyeron de las minas de Laurión. En el año 73 a.C., en Roma, el
        gladiador Espartaco se rebeló con 72 compañeros esclavos; la campaña fue
        apoyada por una turba numerosa que logra atrincherarse en el Vesubio,
        donde logran vencer a los ejércitos consulares y ocupar la Campania. El
        gobierno romano, en su intento por recuperar el orden imperial, otorgó
        todos los poderes al general Craso -“el triunviro”-, quien al mando de
        10 legiones de soldados derrotó al rebelde en la península de Locania,
        donde fue asesinado. Sus tropas fueron derrotadas y los sobrevivientes
        crucificados en el camino de Roma. (Pellini, La Esclavitud en Roma y
        Grecia. Rebelión de Esclavos Espartaco romano) .
      </p>,
    ],
  },
  {
    section: 18,
    title: "Populistas Megalómanos y Narcisistas",
    ref: "/PopulistasMegalomanosyNarcisistas",
    parrafos: [
      <p>
        El término populismo de acuerdo a la Real Academia Española, significa:
        “tendencia política que pretende atraer a las clases populares”; surgió
        en Rusia y los Estados Unidos de América a fines del siglo XIX. La
        multiplicidad de arengas populistas de izquierda y derecha ha hecho
        difícil una definición apropiada, como se estableció en el primer
        capítulo. No obstante, las particularidades detectadas en su discurso y
        práctica política, pueden sintetizarse en: énfasis en su retórica
        nacionalista; liderazgo caudillista a objeto de posicionar el culto a la
        personalidad; demagogia; e inculpación de los males del país a sus
        adversarios internos y externos: capitalistas, imperialistas,
        comunistas, etc. Esta caracterización básica del populismo, me permite
        afirmar con certeza que estos perfiles psicológicos se han sucedido
        desde la misma antigüedad.
      </p>,
      <p>
        En efecto, el populismo no es una ideología: es un estilo político. A
        decir de Nicolás Márquez: “Un estilo semi - delictual de hacer
        política”; es “el autoritarismo plebiscitario”. Hoy que los golpes de
        estado se han devaluado; entonces se requiere un clientelismo
        incondicional para captar el poder a través del voto. Ello requiere
        convencer a la masa que el líder “mesías” es el único que puede
        transformar esa realidad social. La consecución de la voluntad popular
        en base a la demagogia y al chantaje subliminal, les da un viso de
        “legitimidad” a estos tiranos de ego agigantado; más aquello
        generalmente deviene en desastre y retroceso social, porque no es la
        suma genuina de la voluntad del pueblo en pleno: es la lógica únicamente
        de un grupo fanatizado e irascible, azuzado con la ilógica de los/as
        demagogos/as populistas. El pueblo compuesto por todos quienes somos
        parte del Estado (independientemente del credo, religión, nivel
        económico, o postura política), es restringido por el discurso populista
        que indirectamente separa: “sólo los marginados forman parte del mismo”.
        Muy tarde la población percibe que vive en un sistema dictatorial
        vestido de democracia camuflada que a veces es más cruel que las
        dictaduras militares, como lo advierte esta cita:{" "}
      </p>,
      <p>
        <i>
          Vale decir, el déspota contemporáneo ejerce el poder realizando una
          teatralización de la institucionalidad para escudarse en ella. El
          autócrata moderno navega en una imprecisa franja gris oscilando (casi
          al límite), entre la legalidad y la ilegalidad. No cierra canales de
          televisión disidentes al viejo estilo de Fidel Castro, sino que espera
          que se venza algún plazo concesionario o se le inventan causas
          judiciales o pretextos históricos para clausurarlos o confiscarlos; no
          cambia la Corte Suprema por decreto, sino que extorsiona y amedrenta
          jueces (a través de amenazas de juicio político, presiones de aparatos
          de inteligencia, campañas de desprestigio o “escraches” efectuados por
          agitadores profesionales) hasta forzar su renuncia. El tirano
          concebido a la usanza tradicional, se eterniza en el poder por propia
          voluntad y listo, como estos arbitrios abiertamente unipersonales
          actualmente están desacreditados, el tirano del Siglo XXI reforma y
          deforma la Constitución Nacional utilizando ‘su’ Parlamento (que de
          independiente tiene bastante poco) e impone la ‘reelección indefinida’
          a través de plebiscitos efectuados en sistemas electorales agujereados
          y viciados de irregularidades y clientelismo (Márquez, Nicolaz 72 -73)
          .
        </i>
      </p>,
      <p>
        Indudablemente, estas conductas, características de aquellos dictadores
        excéntricos, psicopáticos y narcisistas se apoyan en artificios legales
        e ilegales para cautivar la voluntad del pueblo y/o sus representantes.
        Sus campañas políticas sin escrúpulos, sólo aplican el criterio del
        “todo vale” para captar el poder: los pactos, la traición, la demagogia,
        e incluso las armas. Increíblemente, aquellos alienados y fanatizados
        por la verborrea populista trivial defenderán a su caudillo con
        obstinación, y pasará mucho tiempo para que se den cuenta del error;
        mientras otros pasarán a mejor vida con su enajenación total. El costo
        de entregar el poder político a estas personalidades, es demasiado alto;
        su afán de servicio a la sociedad es mucho menor que su petulancia y
        egolatría.{" "}
      </p>,
      <p>
        Por otra parte, el perfil de un “gran populista” comúnmente está ligado
        a aquellos que sufren el “síndrome de Hubris”, relativo a los desórdenes
        de la personalidad adquiridos, que perturban a quienes poseen poder. En
        los gobernantes, la enfermedad se manifiesta en actos de grandiosidad,
        prepotencia, soberbia y arrogancia en relación a sus cogobernados y
        pueblo en general. Su imagen interna ostenta el criterio de poseer una
        capacidad superior, de aquella que realmente posee; y este elevado
        autoconcepto de sí mismos y el delirio de grandeza, propios de los
        narcisistas se desborda en megalomanía. El ego ávido de poder y
        omnipotencia le hace sentirse el ganador: el más importante; el mejor,
        antes de competir; incluso a veces la arraigada ficción de autoestima
        inflada, intrínseca del político megalómano/a, le ayuda a alcanzar sus
        fantasías. Si bien, debe reconocerse el extraordinario don de persuasión
        característico de muchos psicópatas, que le permite seducir a su
        público*; sin embargo, el egocentrismo patológico creado le impide
        arrepentirse de sus actos, o sentir compasión del sufrimiento ajeno, por
        carecer de consciencia moral.
      </p>,
      <p>
        <i>* Subrayado del autor</i>
      </p>,
      <p>
        El narcisismo, conocido desde la antigüedad, describe a quienes poseen
        un amor obsesivo hacia su persona que le hace considerase: el más
        fuerte, el mejor, el/la más bello/a, o único/a capaz de resolverlo todo.
        El nombre de esta patología proviene de la mitología griega que describe
        al engreído Narciso como un personaje de belleza singular, quien solía
        rechazar a las doncellas que se enamoraban de él… Para castigar su
        vanidad, Némesis la diosa de la venganza, hizo que se enamorara de su
        propia imagen reflejada en una fuente. La psiquiatría ha relacionado el
        narcisismo con los trastornos mentales existentes detrás de aquellos
        dictadores que se creen: “mesiánicos e iluminados”. En torno al tema,
        Mario Fumero subraya:{" "}
      </p>,
      <p>
        <i>
          Si analizamos psiquiátricamente a todos los dictadores de la historia,
          empezando por Nerón, Napoleón, Hitler, Stalin, Mao Zedong, Somoza,
          Trujillo, Pinochet, etc. Descubriremos que todos ellos padecían de
          estos dos problemas mentales, se creían los únicos que podían salvar
          al país, y, además, se consideraban los mejores e imprescindibles,
          llegando a enfermarse aún más al tener el poder, y es que las personas
          que confrontan estos problemas, al tener poder absoluto, sus síntomas
          se agravan, y se convierten en una bomba de tiempo (Fumero) .
        </i>
      </p>,
      <p>
        Las características patológicas de las personas desequilibradas
        psíquicamente, radica en el cometimiento de cualquier tipo de
        transgresión a la ley (robar, violar, o engañar, etc.), y no sentir
        culpa alguna por el daño causado. Son expertos en usar máscaras de
        religiosidad, filantropía y heroicidad. Su falsa imagen es convencer a
        su público que están interesados en actividades loables; cuando en
        realidad realizan actividades contrarias a lo que predican. Comúnmente
        estas personas no sienten amor por nadie; lo único que les motiva y
        realmente desean, es el poder. No aceptan sugerencias de nadie y se
        ofenden con mucha rapidez. Su egocentrismo y orgullo excesivo les hace
        adoptar su propia agenda para lograr sus objetivos. Su perfil
        psicológico – panorámico se resume, en la adopción de una conducta
        antisocial: manipuladora, mentirosa, cruel, agresiva, e incapaz de
        sentir compasión al sufrimiento ajeno. Son fríos, serenos, observadores;
        pero, sobre todo elocuentes y convincentes. El pastor psicólogo
        Stamateas, subraya que:
        <p>
          <i>
            El psicópata es un experto en el arte de usar máscaras, manipular,
            mentir y engañar sin escrúpulos; (…) suelen ser individuos sumamente
            locuaces: siempre tienen a flor de piel respuestas rápidas, tienden
            a ser muy convincentes, saben expresarse con encanto y son capaces
            de ´vendernos´ cualquier realidad que, obviamente, los haga quedar
            bien a ellos.{" "}
          </i>
        </p>
        <p>Además, añade:</p>
        <p>
          <i>
            El sujeto que padece este tipo de patología tiene en todo momento la
            sensación de que es mejor que los demás, posee un egocentrismo
            desproporcionado y el sentimiento de que puede hacer cualquier cosa,
            cómo y cuando quiera. Su meta permanente es buscar el poder y el
            control de todos los que están a su alrededor; esa necesidad lo
            convierte en un ser incapaz de comprender que haya personas que
            tengan ideas diferentes a las suyas. Y si agregamos a estas
            conductas el hecho de que el psicópata considera al otro como simple
            objeto, entenderemos por qué se le hace tan sencillo maltratar;
            lastimar y abusar de los demás, sintiéndose con pleno derecho a
            hacerlo con impunidad (Stamateas) .
          </i>
        </p>
      </p>,
      <p>
        El estudio realizado por una psicóloga ecuatoriana (que prefirió el
        anonimato), recogido por el escritor argentino Nicolás Márquez, en torno
        a las características psicológicas del carismático populista de
        izquierda, expresidente Rafael Correa, encaja en el texto. La
        transcripción tiene el único propósito de corroborar estos asertos, en
        relación a los rasgos psicológicos de muchos políticos populistas; por
        tanto, los aspectos de carácter íntimo los he omitido a propósito. El
        extracto que transcribe el estudio del Dr. Miguel Palacio F., en torno a
        este perfil psicológico, corrobora lo que Márquez describe en su libro.
        Este informe científico, establece:
        <p>
          <i>
            Tiene ideas de megalomanía o grandeza, sintiéndose un iluminado. La
            auto referencia aduladora unida a la grandiosidad auto proclamada,
            matizan sus discursos. Los mismos están llenos de autoalabanzas y
            ensalzamientos hacia su persona y la obra inigualable que dice estar
            llevando a cabo.
          </i>
        </p>
        <p>
          <i>
            Mantiene una autoestima sobrevalorada de convicción Mesiánica, que
            lo hacen verse a sí mismo como un ser superior esperando que los
            demás se sometan sin cuestionamientos a su voluntad. No escucha
            opiniones ni acepta consejos. Conflictivo de nacimiento, abre
            frentes de pelea innecesarios por su beligerante conducta, pues es
            altamente conflictivo.
          </i>
        </p>
        <p>
          <i>
            Su pensamiento funciona mediante el mecanismo de proyección,
            endilgando a otros sus propias frustraciones, fallas y temores. Es
            un fanático de sí mismo que busca a culpables de sus fracasos, ve
            enemigos en todas partes, se siente perseguido y piensa que lo
            quieren matar cada vez que las cosas no le resultan. Está convencido
            de que los que no están con él; están en su contra. Su actividad
            intelectiva es traslocada y desbocada.
          </i>
        </p>
        <p>
          <i>
            Reacciona instintivamente en forma primaria hasta volverse agresivo
            contra quien no le da la razón, por estar convencido que sólo él la
            tiene.{" "}
          </i>
        </p>
        <p>
          <i>
            Su afectividad es cambiante, aunque generalmente mantiene un estado
            de ánimo exaltado o hipomaniaco. Superficialmente es pura risas,
            sonrisas y abrazos.{" "}
          </i>
        </p>
        <p>
          <i>
            De la aparente euforia y simpatía externa, cambia fácilmente a la
            agresión verbal o física sin límites. Actúa con lo que se denomina
            Diátesis explosivas; que en buenos términos significa que reacciona
            exageradamente con agresividad verbal o física frente a un estímulo
            insuficiente para tal reacción (…).
          </i>
        </p>
        <p>
          <i>
            Persona intolerante y prepotente que necesita imponer su voluntad,
            despreciando a quien está a su lado sin importarle su pensar. Es
            petulante y arrogante. Bravucón superlativo, necesita resaltar; ser
            el centro de atención sin compartir espacios, ni ceder
            protagonismos. Su yo se encuentra tan envanecido, que necesita
            permanentemente del mimo y el adulo para su reafirmación. Requiere
            continuamente que le den la razón o le digan que es el único que
            sabe lo que se necesita. Anárquico por naturaleza, es inadaptado a
            los sistemas y solo acepta las reglas impuestas por sí mismo. Vive
            de acuerdo a su propio código moral con un total auto convencimiento
            de ser el único poseedor de la verdad. Mantiene nula tolerancia a la
            frustración y sus deseos deben ser satisfechos inmediatamente. Tiene
            resentimientos sociales profundos por traumas psicológicos
            infantiles por ausencia de sujeto filial, por lo que ataca todo
            aquello que no tuvo pero que siempre quiso (Márquez, Nicolaz) .
          </i>
        </p>
      </p>,
      <p>
        En este contexto, a veces la personalidad psicopática de ciertos líderes
        se encuentra influenciada con la concepción de falsos paradigmas en
        torno al mundo, la sociedad y el pensamiento. Estas personalidades así
        confundidas tienen el riesgo de tomar el camino del nacionalismo
        xenófobo; ligado al discurso de los caudillos megalómanos y narcisistas.
        Estos perfiles psicológicos desequilibrados se han sucedido desde los
        imperios antiguos, y se han repetido a lo largo de la historia de la
        humanidad, muchas veces con saldos catastróficos.{" "}
      </p>,
      <p>
        Debe destacarse, que en el otro extremo se encuentran los lideres
        humanos, creativos, valientes, visionarios y patriotas, quienes han
        realizado obras filantrópicas de servicio a la sociedad. No me refiero
        únicamente a la multitud de empresarios multimillonarios como
        Rockefeller, Ford, Carnegie, Warren Buffett o Bill Gates quienes han
        aportado a la humanidad con sus obras filantrópicas, a veces incógnitas;
        sino, además líderes políticos como George Washington, Simón Bolívar o
        Nelson Mandela que constituyen verdaderos paradigmas políticos, dignos
        de ser reconocidos.
      </p>,
      <p>
        Al extraer una muestra de nuestra historia, relativo a aquellos
        populistas megalómanos mesiánicos que gobernaron algunos países del
        mundo, no habrá duda al afirmar que el pueblo raso sólo era una cifra
        carente de todo derecho. Contradictoriamente, la masa le era útil para
        trabajar, para defenderlos y para presumir sus hazañas.
      </p>,
    ],
  },
  {
    section: 19,
    title: "Calígula",
    ref: "/Caligula",
    parrafos: [
      <p>
        El emperador romano Cayo Julio César Germánico, “Calígula” (37- 41
        A.C.), inicialmente admirado en por sus actos de generosidad, es
        considerado uno de los emperadores más crueles de la antigüedad. Algunas
        de las acciones enajenadas endilgadas a este dictador son: matar por
        diversión - el haber ordenado a la gente del público sea devorada por
        los leones en la arena del circo -; torturar a las personas usando una
        sierra; intentar nombrar senador a su caballo favorito, insitatus;
        asesinar, perseguir y confiscar los bienes de los senadores por odio, a
        quienes denigró y ridiculizó en público. En otro arranque de locura,
        pretendiendo superar la hazaña del Rey Persa, Jerjes, al cruzar el
        Helesponto: donde construyó un puente flotante empleando barcos que
        conectaban los puertos de Baiae y Puteoli; por ahí atravesó a lomo de su
        caballo portando la coraza de Alejandro Magno.{" "}
      </p>,
      <p>
        Según Dion Casio, Calígula obligó al pueblo y al Senado rendirle culto
        en vida; para ello realizó apariciones públicas vestido de dios o
        semidiós (Hércules, Mercurio, Venus y Apolo). Incluso, en ciertos
        discursos públicos se refería a sí mismo como una deidad, cuando
        comparecía ante los senadores con el nombre de Júpiter. Además, se
        erigió tres templos a sí mismo: dos en Roma y uno en Mileto. Fuentes
        contemporáneas, entre ellas Filón de Alejandría y Séneca el Joven,
        describen a este emperador como un demente irascible, caprichoso,
        derrochador y enfermo sexual, quien convirtió al palacio en un burdel;
        alardeando de acostarse con las esposas de sus invitados (Cervera) .{" "}
      </p>,
    ],
  },
  {
    section: 20,
    title: "Hitler",
    ref: "/Hitler",
    parrafos: [
      <p>
        En Alemania, durante la Primera Guerra Mundial en 1918, el soldado
        Hitler perdió la visión debido a un ataque con gas mostaza en Flandes.
        El psicoanalista, Dr. Edmund Foster, quien era adverso al nazismo, trató
        este colapso nervioso al militar a través de auto - sugestión,
        denominado: “ceguera histérica”. Se relata que el futuro führer alemán,
        sufrió una recaída luego de conocer el fracaso de Alemania en la Primera
        Guerra Mundial. En este proceso de curación, el médico encendió una vela
        y le persuadió diciendo: si eres capaz de ver la llama encendida, será
        una prueba absoluta de tus cualidades extraordinarias; y “tu destino de
        conducir a Alemania hacia la victoria”... Entonces, Hitler murmuró que
        podía ver una tenue luz, y poco a poco dio más detalles de los objetos a
        su alrededor. Su visión se había restablecido por completo; y al mismo
        tiempo se había engendrado uno de los peores dictadores del mundo. Este
        pasaje nos advierte, el tipo de aberraciones que pueden crearse a través
        de sugestión o autosugestión, en alguna etapa de la vida de la persona.
      </p>,
      <p>
        Las artimañas políticas - militares del déspota y megalómano gobernante
        populista, facilitado por su elocuencia y demagogia característica, le
        permitió controlar todos los poderes: la economía, medios de
        comunicación y la cultura. De inmediato inició su campaña militar que
        dio lugar a la Segunda Guerra Mundial; la más sangrienta conflagración
        jamás vista en el planeta. El resultado de su política de terror se
        resume: alrededor de 60 millones de vidas aniquiladas en el conflicto
        bélico; odio racial que llevó al exterminio de judíos en los campos de
        concentración; y, millones de famélicos desplazados como consecuencia de
        la guerra.
      </p>,
    ],
  },
  {
    section: 21,
    title: "Saparmurat Niyázov",
    ref: "/SaparmuratNiyazov",
    parrafos: [
      <p>
        El dictador Saparmurat Niyázov (1940 - 2006) gobernó la república de
        Turkmenistán entre 1985 al 2006. Antes de la caída de la Unión Soviética
        fue líder del Partido Comunista en su País; y luego de la desintegración
        de la URSS, fue nombrado presidente de la república. Su gobierno de mano
        de hierro persiguió con crueldad a sus detractores, quienes algunos
        terminaron en el exilio. Promovió el culto a su personalidad haciéndose
        llamar 'Turkmenbashí' o "padre de todos los turcomanos"; para ello, se
        hizo otorgar los títulos y honores inimaginables. Escribió el Rujkhnamá,
        o Ruhnamá (Libro del Alma), "la constitución moral" para todos los
        turcomanos, el cual pasó a ser un texto de estudio obligatorio. Decretó
        que todo estudiante o aspirantes a un cargo debía memorizar su libro, e
        hizo levantar un monumento al texto de alrededor de 10 metros. Incluso,
        reemplazó el Juramento Hipocrático de los médicos por el “Juramento a
        Turkmenbashí”.{" "}
      </p>,
      <p>
        Entre otras excentricidades del dictador populista se resumen: cambió el
        nombre de los meses del año y de-cretó un nuevo ciclo vital, en el que
        la infancia concluía a los trece años y la adolescencia a los 25; luego
        de ésta, añadió una nueva etapa que denominó “edad profética” que iba
        hasta los 49. Decretó la construcción de estatuas de oro en su honor; y
        ordenó construir un palacio de hielo en el desierto de Karakum. Sus
        estatuas doradas y retratos son múltiples en Turkmenistán, entre los
        monumentos erigidos en vida se destaca una escultura giratoria chapada
        en oro de cinco metros de altura, orientada hacia el sol (La Nación) .
      </p>,
    ],
  },
  {
    section: 22,
    title: "Francois Duvalier",
    ref: "/FrancoisDuvalier",
    parrafos: [
      <p>
        Médico haitiano de origen humilde; luego de su graduación fue nombrado,
        Director del Servicio de Salud en 1946. Por sus dotes de asistencia a
        los sectores vulnerables se ganó el sobrenombre de “Papá Doc”. En 1957,
        Duvalier fue electo Presidente, y dos años más tarde inauguró una
        temible fuerza pública particular llamada tonton macaute, inspirado en
        las camisas negras del fascismo italiano, que tenía como fin controlar
        al ejército. La iglesia católica lo excomulgó debido a su política
        represiva y la promoción del budú. La violencia y represión pareció
        agudizarse en el País, luego de su reelección en 1961 y la expulsión del
        arzobispo de Puerto Príncipe. En 1964 se proclamó Presidente Vitalicio e
        inició el impulso al culto a la personalidad en torno a la imagen del
        dictador. Los atentados contra la vida y la expulsión fueron las
        acciones más comunes empleadas para eliminar a sus adversarios, con un
        número que sobrepasa los 30.000 asesinatos. A fin de auto glorificarse
        redactó el credo que debía ser aprendido por todos los haitianos, que
        expresaba: “doc nuestro que estas en el Palacio Nacional, mientras
        vivas, honrado sea tu nombre por las generaciones presentes y futuras,
        hágase tu voluntad en Puerto Príncipe y las provincias” (Sosa 149) .{" "}
      </p>,
    ],
  },
  {
    section: 23,
    title: "Maumar Gadafi",
    ref: "/MaumarGadafi",
    parrafos: [
      <p>
        El coronel libio Maumar Gadafi, lideró un golpe de estado que depuso al
        rey Idris, en 1969. Su discurso socialista y su simpatía política al
        líder Nacionalista Panárabe, el egipcio Gamal Abdel Nasser, cautivó la
        simpatía de las masas. Para captar el poder total, reemplazó los
        partidos políticos por el yamahiriya o “estado de las masas”, una
        combinación de islam, socialismo y democracia directa (asambleas
        populares) que proclamo en su “Libro Verde”. Inicialmente, su gobierno
        mejoró el nivel de vida de la población marginada, se declaró
        antiimperialista y apoyó los movimientos revolucionarios de la época. Su
        amistad con personas y grupos insurgentes que actuaban al margen de la
        ley, entre éstos: Carlos Ilich Ramírez (el chacal), el IRA irlandés,
        Septiembre Negro palestino, las Brigadas Rojas italianas, el Baader
        Meinhof alemán, el grupo separatista ETA; y, su declarado odio a
        Washington, hizo que Reagan lo apodara “el perro rabioso de Oriente
        Medio”.{" "}
      </p>,
      <p>
        ¡En diciembre de 1988 estalló en Lockerbie, Escocia, el avión de Pan Am,
        donde perecieron 270 personas. El hecho considerado la peor tragedia
        aérea de Gran Bretaña de la temporada, fue atribuido a agentes libios
        vinculados a Gadafi. En 1999 Gadafi entrego a 2 sospechosos para ser
        procesados. Posteriormente, el único inculpado Abdelbaset al-Megrahi fue
        liberado por el gobierno escocés alegando una supuesta enfermedad.
        Aquello despertó polémica; pues se rumoreaba que el acuerdo se habría
        dado a cambio de jugosos negocios petroleros. El liberado, a su arribo a
        Trípoli fue recibido en calidad de héroe, pero no parecía enfermo como
        se había fundamentado; poco más tarde, el exministro de Justicia libio
        aseguró tener pruebas de que Gadafi ordenó el atentado.
      </p>,
      <p>
        En 2009, el aniversario número 40 del golpe de estado fue celebrado con
        fastuosos festejos: incluyendo camellos, sobrevuelo de aviones, bandas
        militares, 400 bailarines, con un costo de 40 millones de dólares y una
        duración de 6 días. Tras los atentados del 11 de septiembre del 2001 en
        New York, Gadafi procuró lavar su imagen: fue el primer país en condenar
        los atentados y pedir una orden de arresto para el líder de Al Qaeda,
        Osama Bin Laden. Además, cortó sus lazos de amistad con líderes
        terroristas y abandonó sus proyectos nucleares. Aunque nunca admitió su
        culpa, pagó millonarias indemnizaciones a los familiares de Lockerbie.
        Como respuesta a la actitud del caudillo libio, en el 2007, Washington
        sacó a Libia de la lista de países que apoyaban al terrorismo.
      </p>,
      <p>
        En la década de los 90, el dictador libio, dejó de perseguir el
        liderazgo del mundo árabe y se abanderó de la integración africana. En
        2008, 200 reyes y líderes del continente viajaron a Libia para coronarlo
        "rey de reyes"; un año más tarde, presidió la Unión Africana. En esa
        temporada, algunos cables de noticias informaron que el coronel
        preparaba la sucesión del mando a uno de sus hijos. Poco tiempo después,
        las revueltas populares de Túnez y Egipto contagiaron a Libia; el
        régimen contestó con una brutal represión que provocó miles de muertos.
        El 20 de octubre del 2011, tras semanas de encarnizados combates, el
        Ejército del CNT (Consejo Nacional de Transición) concluyó con el asalto
        final en Sirte y la captura del dictador, quién concluyó su mandato
        herido, insultado y linchado por sus captores.{" "}
      </p>,
      <p>
        <i>
          El odio, la venganza y la impunidad rodearon un ajusticiamiento
          sumario que las nuevas autoridades libias han asumido como el
          desenlace prácticamente inevitable de la lucha contra un tirano
          enloquecido que no les había dado más opción; en otras palabras: que
          quien a hierro mata, a hierro muere (Ortiz) .
        </i>
      </p>,
    ],
  },
  {
    section: 24,
    title: "Hugo Chávez Frías",
    ref: "/HugoChavezFrías",
    parrafos: [
      <p>
        Venezuela, país sudamericano con las más grandes reservas de petróleo en
        América Latina, en las últimas décadas del siglo XX, heredó una abultada
        deuda externa con mayor incidencia en los grupos sociales vulnerables.
        Aquello se evidenció en el Caracazo de 1989, en la administración de
        Carlos A. Pérez, cuando las medidas del gobierno dieron lugar a los
        saqueos del pueblo encolerizado. Para detener el caos la policía y
        militares utilizaron la fuerza letal. La cifra oficial de fallecidos fue
        de 276; mientras la oposición se refería a más de 3.000 asesinatos.{" "}
      </p>,
      <p>
        En este contexto, la pobreza y el desempleo fueron los detonantes del
        golpe de estado de 1992 liderado por el coronel, Hugo Chávez Frías. Su
        discurso populista que se identificó con el “Socialismo del Siglo XXI”,
        caló hondo en la masa inconforme que confió en su palabra y le permitió
        llegar al poder en 1998. Más allá de una temporada de bonanza por los
        réditos de la venta del petróleo y la entrega de subsidios a los
        sectores marginales, la política chavista causó poco impacto en el
        mejoramiento de la economía del País, debido a la desorganización, y la
        rampante corrupción en las instituciones gubernamentales. Su consigna:
        “Patria, Socialismo o Muerte”, daba cuenta de su alianza con Cuba;
        asegurando que este sistema duraría para siempre. Similar a Castro, para
        convencer al pueblo inicialmente negó su ideología y, cuando se
        entronizó en el poder repetía ser socialista reiteradamente. Para
        mantener su popularidad y parar la oposición, no dudó en amordazar a la
        prensa libre; entonces, la palabra democracia referida en sus discursos,
        al igual que muchos de sus camaradas, parecía o no entenderla o haber
        cambiado su significado.{" "}
      </p>,
      <p>
        En el mentado “Libro Azul”, Chávez critica a los gobiernos populistas y
        a través de una cita subraya:{" "}
      </p>,
      <p>
        <i>
          (…) El peor enemigo de la libertad, dice Montesquieu, es el poder, (…)
          una experiencia eterna que todo hombre que tiene poder tiende a su
          abuso´; más, como el poder es necesario, sólo existe un medio para
          garantizar la libertad, a saber: encontrar una ´disposición de cosas´
          en la que ´el poder detenga al poder´, y ello sólo puede lograrse
          según su división. (Chávez 67) .
        </i>
      </p>,
      <p>
        Es decir, la incoherencia del discurso chavista ponía en claro el
        desconocimiento de su propia ideología: caracterizado por la carencia de
        libertad, abuso de poder y egolatría. Heinz Dieterich, autor del
        Socialismo del Siglo XXI, pretende innovar los vicios del socialismo
        clásico, pero no se desliga completamente de aquellos presupuestos
        marxistas que han suscitado controversia en todo el mundo. Entre sus
        innovaciones importantes: cree en el desarrollismo democrático regional;
        la economía de equivalencias; la democracia participativa y protagónica
        controlada por las fuerzas sociales del estado. Sostiene que la sociedad
        más justa resulta de la alianza entre el Socialismo y el Liberalismo: es
        decir, un renunciamiento del estatismo, por parte del primero; y la
        separación del liberalismo del capitalismo. Esta teoría parecida al
        Socialismo Democrático o Izquierda Reformista podría haberse constituido
        en una alternativa política, si sus líderes no se empecinaran por el
        culto a su personalidad: tampoco la reelección indefinida; es decir, si
        se propiciara la democracia y la libertad y se redujeran las medidas
        traumáticas a través del abuso de poder. No obstante, a Chávez le
        resultó complicado practicarlo; entonces tomó el atajo de todo populista
        fanatizado: la dictadura…
      </p>,
      <p>
        La nacionalización de empresas implicó el traspaso administrativo a sus
        camaradas y amigos cercanos. Las medidas sociales agudizaron la
        corrupción y llevaron a Venezuela al decrecimiento económico,
        convirtiendo al País en dependiente de las importaciones. La falta de
        visión y fanatismo ideológico le hizo tomar medidas desacertadas; no
        vaciló en poner a la cabeza de las áreas estratégicas en manos de leales
        incapaces que perjudicó la infraestructura energética. Parecía que el
        socialismo chavista hizo realidad la distribución equitativa de la
        pobreza a los venezolanos. La inflación de años anteriores a Chávez, de
        alrededor del 9% se disparó al 100%; y, al finalizar el 2018, el F.M.I.
        da cuenta que esta ascendió al 13.000 %. Los efectos devastadores de las
        medidas socialistas para combatir el llamado: “neoliberalismo pro –
        yanqui”, sumió a la mayoría de la población en una crisis económica
        nunca vista.{" "}
      </p>,
      <p>
        Las políticas adoptadas en forma rimbombante, en nombre de la revolución
        bolivariana, disfrazó la agudización de la crisis que se veía venir: La
        censura de prensa; la persecución a los adversarios políticos; la
        militarización; la contratación de asesores y estrategas cubanos en
        ciertos campos; la sustitución de personal sin el perfil apropiado,
        transformó a Venezuela en un estado fallido. Para encubrir los
        indicadores del decrecimiento económico, impidió la difusión de datos
        socio-económicos y persiguió a la prensa, llamándola: “derecha corrupta
        pro imperialista”. En tanto, fuentes informadas daban cuenta del número
        de desplazados por el hambre; y la cifra de homicidios por cada 100.000
        habitantes, que ubicó al País en uno de los más peligrosos del mundo. La
        denigración e insultos del gobierno a quienes protestaban, llamándolos:
        “escuálidos”, “guarimberos”; y además, la presencia de los colectivos
        armados que asesinaban a los manifestantes, tardíamente hizo comprender
        al pueblo que su democracia había colapsado.{" "}
      </p>,
    ],
  },
  {
    section: 25,
    title: "Nicolás Maduro",
    ref: "/NicolasMaduro",
    parrafos: [
      <p>
        El 5 de marzo del 2013, Nicolás Maduro, Vice - presidente del régimen
        chavista tomó el mando de la República Bolivariana de Venezuela luego
        del fallecimiento del coronel Hugo Chávez. Nada pudo la “avanzada
        medicina” cubana. Su muerte fue un despliegue de propaganda jamás vista
        en toda la región. La meta era inmortalizar la imagen de Chávez como
        héroe mundial y disfrazar la crisis que continuaba en ascenso. A partir
        de esa fecha hasta los primeros meses del 2019, pese a la sanguinaria
        persecución gubernamental surgieron multitudinarias manifestaciones
        contra el régimen socialista. En este contexto, destacados líderes
        populares venezolanos: Leopoldo López, Antonio Ledezma, María Corina
        Machado, Enrique Capriles y otros, denunciaron ante el mundo la
        corrupción e incapacidad de gobernar del régimen izquierdista
        castro-chavista. Maduro por su parte, entre sus múltiples amenazas,
        aseguraba que habría mayor desangre en Venezuela si el imperialismo
        pretende invadir este País.
      </p>,
      <p>
        Zeid Ra’ad al Hussein, un alto comisionado de las Naciones Unidas para
        los Derechos Humanos (ACNUDH), en su informe titulado: “Violaciones de
        los derechos humanos en la República Bolivariana de Venezuela” denunció
        sobre las violaciones y la represión de los cuerpos de Seguridad del
        Estado. Tras su publicación, pidió a la Corte Penal Internacional
        investigar los atentados encubiertos por el fiscal general, Tarek
        William Saab. Relató el atropello a las libertades que reprimían la
        protesta social y demás violaciones perpetradas en las detenciones a los
        opositores. Enfatizó que: “La ley Contra el Odio” ha limitado el derecho
        de reunión pacífica. Responsabilizó al fiscal por la impunidad de los
        asesinatos acaecidos durante las protestas debido a su indeficiente
        acción. También señaló, las ejecuciones extrajudiciales surgidas después
        de la implementación del Plan: “Operaciones de Liberación del Pueblo”
        (OLP), como el caso del asesinato del capitán Óscar Pérez. Así mismo,
        denuncia detenciones a inocentes, torturas y trato inhumano condenados
        por Organizaciones Internacionales: como descargas eléctricas, golpes,
        violencia sexual, asfixia, simulacros de ejecución y privación de agua
        (BBC Mundo) .{" "}
      </p>,
      <p>
        La crisis de los ciudadanos venezolanos por la escasez de alimentos y
        medicinas constituye otra forma de vulneración a sus derechos humanos.
        El pueblo tiene que resignarse hacer largas colas para comprar
        provisiones limitadas a precios controlados por el Estado; mientras
        quienes poseen dinero acuden a los supermercados o los “bachaqueros” a
        precios elevados. No obstante, pese a la aguda crisis social, económica
        y política, el gobierno se ha negado a reconocerlo: ha alegado tratarse
        de un “show del imperialismo” y demás gobiernos de derecha de la Región.
        Hasta agosto de 2018, la emigración alcanzó alrededor de 3 millones de
        personas que huyen de la pobreza, violencia y exclusión.
      </p>,
      <p>
        Una democracia irreal basada en el desequilibrio de poderes, comúnmente
        consecuencia de populismos peligrosos ha lesionado la libertad
        individual y la institucionalidad democrática. La libertad, como derecho
        inalienable, inherente e imprescriptible de hombres y mujeres, es
        inaceptable de negociación. Cervantes, en su obra el Quijote establecía:
        este don precioso, regalo del cielo, es incomparable a los tesoros de la
        tierra y el mar; y que: “por la libertad, así como por la honra, se
        puede y debe aventurar la vida; y, por el contrario, el cautiverio es el
        mayor mal que puede venir a los hombres” … Su preservación y vigencia
        sólo es factible dentro de una democracia limitada con sus pesos y
        contrapesos: ello coadyuva al desarrollo social e individual armónico y
        frena la embestida de megalómanos desequilibrados que pretenden
        experimentar con sistemas socio – políticos retrógrados.
      </p>,
      <p>
        La controversia en torno al fraude en las elecciones, que dejó fuera de
        la inscripción a los candidatos de la oposición, arreció las protestas y
        manifestaciones en Venezuela. El 23 de enero del 2019, Juan Guaidó,
        presidente de la Asamblea a través de cabildo abierto en Caracas, y en
        alusión al Art. 233 de la Constitución, tomó posesión en calidad de
        Presidente Interino, prometiendo llamar a elecciones libres. La OEA, el
        Parlamento Europeo y la mayoría de gobiernos democráticos, reconocieron
        al nuevo gobierno; mientras China, Irán, Turquía, Bolivia, Nicaragua y
        Rusia, reiteraron su apoyo a Maduro. Italia, México y Uruguay
        mantuvieron una posición intermedia y pretendieron servir de nexo hacia
        la solución del conflicto. La coalición internacional de países libres
        se encuentra realizando esfuerzos a fin de restablecer el orden
        democrático en Venezuela, para detener el abuso del poder, la
        emigración; y sobre todo: para inaugurar la paz duradera en la Región.
      </p>,
    ],
  },
  {
    section: 26,
    title: "CAPTACIÓN Y MANTENIMIENTO DEL PODER EN LOS REGÍMENES PUPULISTAS",
    ref: "/CaptacionYMantenientoDelPoderEnLosRegimenesPupulistas",
    parrafos: [
      <h5>Métodos para Tomar El Poder Político</h5>,
      <p>
        En forma general, los métodos para captar y defender el poder político
        han cambiado a través del tiempo; no obstante, ciertas formas de atraer
        la voluntad de los gobernados por medio de acciones inusuales se han
        mantenido. La tipología de rasgos psicológicos que ha cautivado a la
        gente, en torno a los líderes populistas, puede observarse desde
        conductas despóticas, ridículas y a veces estrafalarias que ha
        caracterizado a dictadores peligrosos, e incluso a algunos políticos
        menos autoritarios. El comportamiento controversial de ciertos caudillos
        se relaciona con una especie de moda contracultural, que desplaza a las
        personas formales y da paso a quienes se muestran raros o patéticos, lo
        cual se acopla apropiadamente con el populismo político. Quienes
        despiertan controversia a través de sus declaraciones; o bien, han
        tenido problemas con la iglesia o alguna autoridad visible, son los
        predilectos de muchos electores. Las formas extravagantes de vestir,
        hablar y presentarse en público son preferidas de un gran sector de
        votantes modernos, en una suerte de apoyar lo inusual. Recordemos la
        barba y el uniforme militar de Fidel Castro; el jersey de alpaca y
        chaqueta con aplicaciones indígenas de Evo Morales; o el sombrero
        chotano de Pedro Castillo, indudablemente influyeron en la popularidad
        de estos gobiernos populistas.
      </p>,
      <p>
        En el caso del populismo de izquierda, para atraer seguidores, el
        adoctrinamiento ideológico marxista a la masa es vital para fundamentar
        su arenga de odio y confrontación al capitalismo. Pero sus métodos de
        impulsar celos en la población por el nivel de vida y tecnología
        burguesas, pronto revela la incoherencia inevitable de sus discursos:
        por una parte, la antipatía y constante ataque verbal al sistema; y por
        otra, la dificultad de deshacerse del legado científico - tecnológico
        implícito. Incluso algunos socialistas, no sólo les fascina visitar los
        países desarrollados de libre empresa; sino, además, adoptan el estilo
        de vida de la gente acaudalada. Su discurso acusador requiere algunas
        frentes de disputa para inculpar a sus enemigos “burgueses lacayos”, de
        todos los males. Es una declaratoria de guerra a la institucionalidad de
        la libre empresa, que a veces no entienden su entramado económico –
        político – social, como lo asevera esta cita:{" "}
      </p>,
      <p>
        <i>
          Una guerra cruel. La crueldad de extremistas de izquierda
          contemporáneos no deriva de la fe en la justicia social para los
          desheredados del sistema, sino de la convicción utópica de que es
          necesario destruir una sociedad que no todos a veces conocen bien,
          para edificar otra, de la cual no tienen una visión clara (Kossatchev
          76) .
        </i>
      </p>,
      <p>
        Afortunadamente, en este nuevo milenio, la toma del poder político y los
        métodos de gobernanza se encuentran evolucionando en relación a las
        pautas tradicionales. Los viejos paradigmas cosificados de confrontación
        política: la verborrea y demagogia aún empleados en el proselitismo
        político, poco a poco están quedando en la obsolescencia. Hoy, las
        nuevas tecnologías de la información y la comunicación están influyendo
        en forma acelerada en la sociedad. La demagogia y terminología
        fosilizada empleada por ciertos sectores para denostar a la denominada
        “derecha”, están siendo develados; consecuentemente, la teoría
        político-económica se conocerá con mayor claridad y menor dogmatismo.
        Verbigracia:{" "}
      </p>,
      <p>
        <ol>
          El Neoliberalismo no siempre deberían ser considerado un tabú; esta
          corriente del pensamiento económico – político bien aplicado y con
          reglas claras, indudablemente coadyuvará a reducir el hambre y pobreza
          en el planeta.{" "}
        </ol>
      </p>,
      <p>
        <ol>
          Un buen estadista no se centra únicamente en la ejecución de obras
          faraónicas, para alardear su gestión; sino en el impulso de la
          educación y la siembra de valores ético - morales en los ciudadanos.
        </ol>
      </p>,
      <p>
        <ol>
          El problema de la gobernanza no se encuentra ni en la izquierda ni en
          la derecha, ya que muchos líderes pertenecientes a estos dos sectores
          políticos han dado significativos aportes a la sociedad.
        </ol>
      </p>,
      <p>
        <ol>
          La muletilla demagógica de quienes dicen representar a “los pobres”, y
          no a los ricos, ni a la banca, quedará obsoleta. Muchos de sus líderes
          que hicieron errores históricos, no pertenecieron a los explotados: Ni
          Marx ni le Lenín, ni Mao ni Fidel, fueron “proletarios”.
        </ol>
      </p>,
      <p>
        <ol>
          El pensamiento fosilizado que predica que el pasado fue mejor,
          negándose a admitir las estadísticas actuales en el planeta: en cuanto
          a la educación, esperanza de vida, pobreza extrema, etc., dará cuenta
          que el pasado tuvo mayores limitaciones en todos los campos.
        </ol>
      </p>,
      <p>
        <ol>
          La inconsciencia creada por el ego de los caudillos políticos,
          verdaderamente pueden crear eventos catastróficos; los ciudadanos
          debemos estar conscientes del peligro que asecha cuando las conductas
          ególatras colisionan. El maestro Eckhart Tolle lo previene así:
        </ol>
      </p>,
      <p>
        <i>
          “Cuando los egos se juntan, sea en las relaciones personales o en las
          organizaciones o instituciones, ocurren cosas “malas” tarde o
          temprano: drama de un tipo u otro, en forma de conflicto, problemas,
          luchas de poder, violencia física o emocional, etc. Esto incluye males
          colectivos tales como la guerra, el genocidio y la explotación, todo
          debido a la inconsciencia masificada” (…) (Eckhart) .
        </i>
      </p>,
    ],
  },
  {
    section: 27,
    title: "Carisma y Demagogia",
    ref: "/CarismaYDemagogia",
    parrafos: [
      <p>
        La condición sine qua non para el avance populista es la presencia del
        líder o lideresa carismático/a con su oratoria convincente y fogosa. Su
        éxito radica en usar la persuasión para incitar con pasión los
        sentimientos socio - políticos de las masas. Su discurso polémico,
        mordaz y a veces ilógico, pretende posesionar la solución alternativa
        frente a la debacle propiciada por “los enemigos del pueblo”. Su figura
        política es la única opción válida, porque “sabe cómo resolverlo”.
        Además, este mensaje implica diseminar el odio clasista que divide a la
        sociedad entre buenos y malos: la pugna se centra entre “culpables e
        inocentes”; como el/la “iluminado/a” carismático/a pudiera salvar la
        Patria con su verborrea demagógica.{" "}
      </p>,
      <p>
        Hitler, en 1939 firmó un pacto de no agresión con Rusia; y poco tiempo
        después traicionó el acuerdo... El dictador populista nazi a través de
        su carisma y demagogia aprovechó la frustración del pueblo para
        fanatizarlo, explotarlo y direccionarlo a favor de su conducta
        megalómana. “Mediante sucesivos y concurridos mítines, llegó a convencer
        a la mayoría de alemanes que existía una malvada alianza entre judíos y
        comunistas que terminarían por hundir a la Alemania en la más espantosas
        de las miserias” (Hitler, Mi Lucha) . Así justificó el terrorismo de
        estado que a su criterio permitía detener a los enemigos de Alemania.{" "}
      </p>,
      <p>
        Los charlatanes, poseedores del don de la elocuencia, comúnmente son
        conocedores de sus potencialidades demagógicas. Entonces, diseñan una
        campaña estratégicamente direccionada a calumniar a quienes consideran
        culpables del desastre del País: “burguesía criolla”, “capitalistas
        desalmados”, “judíos”, “comunistas”, “imperialistas”, “F.M.I” etc., para
        lograr posicionar en ciertos sectores sociales la confrontación y
        polarización. Su táctica consiste en sumar adeptos a través de la
        repetición de sus slogans y discursos apasionados a objeto de cautivar a
        los inconformes, o quienes aún no creen en su palabrería.
      </p>,
      <p>
        Un estudioso del populismo en el Ecuador, Rafael Quintero, en torno al
        carismático expresidente ecuatoriano corrobora: “El Dr. Velasco Ibarra
        sabía seguramente lo que decía cuando afirmaba que le bastaba un balcón
        en cada pueblo para ser elegido presidente” (Quintero 17) . ¡Asombroso!
        El pueblo a veces decepcionado, pero hechizado por su fogosa oratoria,
        se reiteraba en dar crédito a su demagogia. En cinco ocasiones creyó en
        sus discursos que le llevó al Palacio de Carondelet, pero sólo una vez
        concluyó el mandato íntegramente. Los abusos de poder, el desacato a la
        Constitución, la violencia verbal contra sus adversarios, la escasa
        libertad de prensa, daba paso a la convulsión social generalizada. Su
        carácter impetuoso, exigente, violento, irrespetuoso, le llevó a tomar
        medidas políticas apresuradas; o encargar las secretarías de gobierno a
        personas de dudosa solvencia moral, lo cual daba origen a la rampante
        corrupción. Así, en forma súbita se invertía la voluntad de sus
        partidarios y a veces, de sus propios amigos; con el consiguiente saldo
        negativo para el País y la disminución de la credibilidad internacional.{" "}
      </p>,
      <p>
        En el cuarto mandato velasquista, cuando el controversial político tomo
        medidas económicas se levantó una ola de protestas en el país.
        Increíblemente el apoyo popular se deterioró con rapidez inusitado, y a
        finales de 1961, el país se convulsionó…{" "}
      </p>,
      <p>
        <i>
          (…) por lo que muy hábilmente, y basado en su demagógica elocuencia,
          en histórico discurso pronunciado en la ciudad de Riobamba, para
          desviar la atención del pueblo ecuatoriano denunció la ´nulidad del
          Protocolo de Río de Janeiro´, olvidándose que en 1944 él mismo había
          admitido su validez (Aviléz) .{" "}
        </i>
      </p>,
      <p>
        Entonces, pretendiendo salvarse de la vindicta popular reprimió las
        manifestaciones, acto seguido probó un nuevo golpe de estado; ordenó la
        captura del vicepresidente Arosemena y de un grupo de legisladores. De
        inmediato las Fuerzas Armadas consideraron se había violado la
        Constitución y le exigieron la renuncia… En 4 ocasiones fue obligado a
        dejar el poder: una por destitución, 2 veces forzado a renunciar; y una
        por golpe de estado; como si ello fuera el destino de los populistas de
        poca monta.{" "}
      </p>,
      <p>
        Aquello, da cuenta que el carisma y la demagogia no bastan para
        gobernar; la noble y patriótica misión requiere: talento, equilibrio
        emocional, sentido de servicio y consensos mínimos. Comúnmente, el
        efímero bienestar que ofrece el populista se transforma en un sistema
        recesivo e hipotecado, con cuentas por cobrar al gobierno sucesor. Las
        medidas económicas tomadas por sus predecesores que fueron satanizadas a
        su tiempo, ahora le han dado otro nombre. El abultado endeudamiento
        externo; la indisciplina fiscal; el despilfarro intrascendente; las
        obras faraónicas constituidas en “elefantes blancos” edificadas para
        cubrir la corrupción, dan cuenta que no les interesa la Patria; sino la
        vanidad de ser considerados “los mejores”. Como aquel petulante sin
        dinero que, pretendiendo convertirse en el centro de atención de sus
        allegados, se endeuda para ofrecer un festín con el mejor vino, licor y
        comida. Indudablemente, la deuda será cancelada por su entorno social
        inmediato a través del tiempo... En este caso, el pueblo acabará pagando
        los excesos y el derroche de los megalómanos.{" "}
      </p>,
      <p>
        La personalidad narcisista e histriónica que comúnmente caracteriza al
        caudillo populista, colisiona con las expectativas de la ciudadanía
        responsable. La gigantesca propaganda de “progreso”, direccionado a
        incentivar el culto a la personalidad, combinado con subsidios
        irresponsables, da cuenta que poco le interesa el desarrollo integral de
        la nación, sino aquellas medidas que impulsen su popularidad. Los
        empleados privilegiados de algunas empresas estatales poco eficientes,
        administradas por el estado, constituirán sus incondicionales; quienes,
        por conveniencia, confusión o temor, defenderán al que consideran: “el
        mejor gobierno”. Se vive una especie de campaña electoral permanente,
        donde el derroche en lo superfluo y la entrega de dádivas paternalistas
        con fondos del estado, parece ser la norma. No importa afectar la
        credibilidad y el riesgo país, lo importante es el aplauso efímero de
        los sectores vulnerables. Es obvio que la asistencia debe dársela a
        quienes requieran en forma oportuna y planificada, procurando reducirla
        a corto plazo; mas no a cambio de transformarles en cifras estadísticas
        en favor de su partido político.{" "}
      </p>,
      <p>
        El carisma, don natural propio de las personas para agradar a sus
        congéneres a través de la palabra y/o personalidad, es benéfico
        orientado en función social; pero resulta perjudicial cuando se usa por
        interés personal para satisfacer el ego. Incluso, ciertos políticos
        moderados pueden transformarse en populistas deshonestos, cuando su
        carisma es direccionado en forma egocentrista. Otros caudillos a veces
        cambian de trayectoria política influenciados por los intereses de sus
        financistas de campaña; o bien por oportunistas vinculados a su entorno
        político… Así, un líder progresista de izquierda, quizá con un discurso
        inicial poco demagógico, podría verse trastocado por extremistas
        fanáticos que en la contienda política se vincularon al partido.
        Entonces, se opera una especie de “secuestro” ideológico, por el cambio
        de objetivos e ideales: el personaje candidato se ha transformado en
        populista. Su discurso inicial que, quizá incluía: impulsó al empleo y
        combate a la corrupción, era loable; más, si pretendiendo revertir los
        derechos conculcados de la masa, confiere poder a personas inexpertas,
        ambiciosas y/o cegadas por el odio, la democracia sufrirá un revés
        irreparable.{" "}
      </p>,
      <p>
        En Venezuela del 2004, si el carismático Hugo Chávez, luego de las
        marchas en favor y contra del régimen hubiese oído a la oposición, el
        Socialismo del S. XXI no hubiera concluido en catástrofe. Las
        manifestaciones populares llevaron al Referéndum Ratificatorio - con
        supervisión de la OEA y el Centro Carter -, el cual confirmó su
        continuación. Una parte de la población fanatizada veía una opción para
        terminar con la inequidad social; la otra, demandaba libertad y
        democracia. Chávez solo escuchó a los serviles de su entorno ideológico;
        quienes, por ambición, conveniencia o temor, aplaudían sus “hazañas”.
        Incumplió su propio discurso que enfatizaba la necesidad de reivindicar
        la soberanía nacional, y condenaba la intromisión extranjera
        imperialista. Al mismo tiempo, centenares de “asesores” cubanos
        atropellaron su débil democracia. Para ellos, la diáspora de venezolanos
        jamás registrada hasta la fecha causada por su política desacertada, no
        es culpa suya; sino de otros países alineados al “imperio yanqui”.
        Indudablemente, algunos de sus camaradas demagogos hasta hoy, en la
        tercera década del S. XXI, aplauden sus “logros”. Sus defensores, aunque
        jamás quisieran estar en los zapatos de los emigrantes, su fanatismo les
        identifica con este criterio:
      </p>,
      <p>
        <i>
          (…) lo atacan acusándolo de seguir a Fidel y llevar a Venezuela al
          castrocomunismo (…), esto implicaría su aislamiento nacional e
          internacional, el mundo debe excomulgarlo: la izquierda por ser un
          gorila y la derecha por ser comunista, e izquierda y derecha juntas,
          por ser un populista. No debe tener apoyo en ninguna parte ni puerta
          de escape.
        </i>
      </p>,
      <p>
        <i>
          El segundo recurso de la guerra se ha formado con la asociación de los
          partidos políticos otrora irreconciliables, desde la extrema derecha
          hasta la extrema izquierda, desde Primero Justicia hasta Bandera Roja,
          incorporando a AD, COPEI (el partido socialcristiano) y posteriormente
          al MAS (Movimiento Al Socialismo). Su tarea derribar a Chávez como
          sea, con los métodos fascistas que usaron en el golpe del 2002(…)
          (Larrea 30-31) .
        </i>
      </p>,
      <p>
        A los caudillos populistas dogmáticos les resulta difícil comprender que
        lo trascendente para la mayoría de la población constituye la libertad,
        la democracia, la seguridad jurídica y un ambiente de trabajo apropiado
        para producir. Su ego abultado les impide concebir que, un estadista
        responsable a veces se verá obligado a tomar medidas temporalmente
        impopulares, con consecuencias inmediatas desfavorables. Los resultados
        benéficos de los ajustes económicos generalmente se observarán a mediano
        o largo plazo; aunque ello implique la reducción drástica de la
        popularidad del gobernante patriota que incluso podría concluir con su
        caída.{" "}
      </p>,
    ],
  },
  {
    section: 28,
    title: "Fanatismo Nacionalista",
    ref: "/FanatismoNacionalista",
    parrafos: [
      <p>
        El sentido de pertenencia y defensa al suelo donde inició nuestra
        conciencia humana, es una emoción noble y natural; por tanto, es
        legítimo aquella identificación histórica de las características
        socio-económicas del País de origen que lleva al sentimiento patriótico.
        Todo hombre o mujer consciente de su identidad geopolítica y sentido de
        pertenencia procurará el progreso material y cultural de su pueblo. En
        este contexto, es justificable el criterio de muchos líderes
        nacionalistas que pretenden mejorar el estándar de vida de su gente;
        mientras otros ciudadanos proclaman, la regulación del ingreso de
        migrantes en sus fronteras a fin de precautelar los intereses de su
        población. Es decir, cuando el sentimiento nacionalista es moderado,
        coadyuva a la consecución de metas y objetivos de un buen gobernante.{" "}
      </p>,
      <p>
        La política nacionalista del magnate populista de derecha, Donal Trump,
        ex jefe del estado norteamericano con un discurso catalogado de
        segregacionista, xenófobo y racista fue rechazado por los sectores
        sociales de izquierda. Pero sus arengas nacionalistas consideradas por
        los detractores poco moderadas y conciliadoras, en relación a la
        protección del País de la inmigración ilegal, si se armonizaba al
        criterio cívico inculcado a todo buen ciudadano: “custodiar e impulsar
        la heredad territorial”. Por tanto, desde la óptica de controlar el
        ingreso de elementos perniciosos a su suelo, con un criterio de Patria,
        no debiere parecer del todo exagerado ¿Qué resultaría si aquello
        ocurriera en nuestro País? Verdaderamente, muchos que condenaban aquella
        política, cambiarían su razonamiento. Igualmente, la muletilla
        izquierdista de culpar la pobreza y la emigración de los países en vías
        de desarrollo hacia Norteamérica, es una verdad a medias, porque omite
        sus verdaderas causas. La banca rota y la pobreza en algunos estados
        subdesarrollados deviene también de la incipiente planificación; la
        indisciplina fiscal; la limitada formación cívica ciudadana, y la
        corrupción rampante.{" "}
      </p>,
      <p>
        Los Estados Unidos de Norteamérica como potencia económica militar
        mundial, mantendrá su liderazgo siempre que su política exterior se
        mantenga orientada a cooperar con la democracia, la libertad y la ayuda
        a los países en vías de desarrollo. Está claro, los estados con
        democracias sólidas que aplican políticas creativas, audaces y
        transparentes, se orientan hacia la estabilidad económica y desarrollo
        nacional. Ciertamente, el sentimiento de patriotismo mesurado crea un
        estado mental ciudadano que coadyuva a impulsar las condiciones de
        desarrollo en nuestros propios países. Pero la democracia debe estar
        generalizada y arraigada en todo el planeta; puesto que su pérdida
        implica comúnmente pobreza y emigración para las naciones que carecen de
        ella. Desde esta perspectiva, parece lógica la política de Trump en esta
        materia; sobre todo, si la inmigración ilegal masiva: “socava la
        prosperidad, destruye las sociedades y da poder a los carteles sin
        escrúpulos”, como lo anota esta cita:{" "}
      </p>,
      <p>
        <i>
          Trump, a su vez, es un nacionalista. Su promesa de “devolver la
          grandeza de América” no implica recuperar Filipinas, ni emprender
          guerras de conquista. Al contrario: Trump quería retirarse de los
          conflictos exteriores que no supongan una amenaza directa sobre
          Estados Unidos y, a cambio, utilizar los recursos del país para
          reconstruir sus infraestructuras y su industria. Es un declarado
          crítico de los neoconservadores que querían crear una nueva Pax
          Americana en Oriente Medio. Trump deseaba construir en su país un muro
          para frenar la inmigración ilegal, y reforzar las fronteras de Estados
          Unidos, no ampliarlas. (Judis, La explosión populista. Cómo la Gran
          Recesión transformó la política en Estados Unidos y Europa.) .
        </i>
      </p>,
      <p>
        Por otra parte, el discurso nacionalista desmesurado es “un recurso
        demagógico” para azuzar al pueblo en los regímenes totalitarios. Con
        similar vehemencia y apasionamiento con que un fanático religioso
        defiende su credo, el caudillo populista pretende notoriedad y liderazgo
        político en el concierto internacional. Pero este apego egotista y
        demagógico a su patria, con un criterio ultra nacionalista a veces
        resulta en decisiones erróneas y la aparición de conflictos internos o
        externos. La identidad nacional deformada, puede derivar en la
        disminución de libertades democráticas: el terrorismo de estado o
        invasiones expansionistas. La propaganda del nacionalismo malsano
        relacionada a la defensa exaltada al suelo patrio lleva aparejado la
        promoción de la xenofobia y la segregación racial. El peligro asecha
        cuando el populista de izquierda o derecha en el poder se identifica con
        la idea de la superioridad de una estirpe frente a otras, y/o cuando
        estos atontados por el síndrome de Hubris, pretenden ejecutar acciones
        por creer que su patria merece preminencia a costa de afectar los
        derechos de otros países.{" "}
      </p>,
      <p>
        Las bibliotecas y las nuevas tecnologías de la información y la
        comunicación social (TICs) del planeta, poseen un vasto cúmulo de
        literatura relativa al antisemitismo, anti catolicismo, anticomunismo,
        xenofobia, homofobia, y otros rasgos que provocan la antipatía humana;
        la cual de por si constituye un riesgo socio-político latente. Aquello
        podría desbordarse cuando un populista fanático e irresponsable adopte
        estos antivalores como parte de su discurso de campaña; y podría salir
        de control de manera desaforada si se adopta como política de estado. Es
        decir, existe el peligro de que los neonazis, caracterizados por su
        profundo antisemitismo y agresividad hacia los migrantes extranjeros; o,
        los adeptos al Ku Klux Klan (KKK), creyentes en “la inferioridad de las
        personas de color” pretendan asolar alguna región del globo. Estos
        insensatos camuflados de patriotas se encuentran disminuidos esperando
        que alguien les saque de su anonimato para infestar la sociedad con su
        irracional ideología como ocurrió en la Segunda Guerra Mundial.
      </p>,
    ],
  },
  {
    section: 29,
    title: "Imposición de Falsos Paradigmas",
    ref: "/ImposiciondeFalsosParadigmas",
    parrafos: [
      <h5>Alteración de la consciencia social </h5>,
      <p>
        Un requerimiento fundamental para la consecución del poder político por
        parte de los caudillos populistas fanatizados de izquierda es: deformar
        la consciencia social de la masa para controlar su voluntad. Quienes
        poseen limitada claridad del materialismo dialéctico e histórico
        comúnmente son atraídos por esta ideología. Si bien se ha afirmado que
        esta teoría de post guerra está de retirada, aún subsiste rezagos en
        ciertos sectores sociales, incluso en fracciones de profesionales y
        académicos respetables. Debe reconocerse que este paradigma ideológico
        considerado de avanzada, requiere un alto nivel de abstracción humana y,
        una praxis social acrisolada para su ejecución práctica. Sin embargo, el
        marxismo que fuera impulsado profusamente después de la S.G.M., incluso
        dentro de los sistemas educativos, no ha tenido concreción exitosa a
        nivel universal. Sus seguidores deben aceptar la innovación ideológica
        que involucra la complejidad geopolítica, y el psiquismo humano que
        tiende hacia la libertad.{" "}
      </p>,
      <p>
        Los fanatizados de este tipo de izquierda sostienen que, la toma del
        poder implica la adopción de medidas de justicia social encaminado hacia
        la “repartición equitativa de la riqueza”, con el claro mensaje de que
        sus líderes, al llegar al poder se trasformarán en los “filántropos” de
        los bienes incautados. Su falsa conciencia les imposibilita darse cuenta
        que no todos poseemos talentos y motivaciones para la producción de
        bienes materiales. Esta carencia de neutralidad ideológica limita a los
        fanáticos considerar al ser humano en su integridad total, con sus
        capacidades y dones especiales, dentro de las diversas ocupaciones
        humanas.{" "}
      </p>,
      <p>
        La lectura y repetición de consignas va creando un sentimiento de odio
        inconsciente que lleva a buscar el desagravio de la injusticia social
        perpetrada por timadores de otros tiempos (burgueses, terratenientes,
        empresarios, patrones, etc.). Esto lleva a los populistas suspicaces a
        inculpar de explotadores a todos los empleadores, sean estos: pequeños
        emprendedores, acaudalados/as, o empresarios/as; y a exculpar cualquier
        deficiencia a los empleados/as o trabajadores/as, tengan o no la razón.
        Semejante al hecho: cuando el reo avezado cae en su propia ley al haber
        sido sorprendido infraganti; los deudos y amigos/as se apresuran a
        invocar los derechos humanos del culpable fallecido, pero omiten
        aquellos de las víctimas.{" "}
      </p>,
      <p>
        A fin de contribuir a cambiar la consciencia social del pueblo, los
        pedagogos de esta línea diseñan un currículo exclusivo para su sistema
        educativo que permita trastocar las concepciones, principios y valores e
        imponer sus paradigmas dogmatizados. Los contenidos y métodos de sus
        programas escolares, procuran adoctrinar a los niños y jóvenes en
        procura de enajenar los ideales, objetivos y conceptos que difieren del
        mundo libre. El “currículum revolucionario” está direccionado a
        posicionar la idea que, gracias al nuevo gobierno habrá un país de
        oportunidades que nadie jamás lo hizo bien. Sus libros saturados de
        discrepancias conceptuales dan cuenta de sus nociones sobre el mundo, la
        sociedad y el pensamiento, con énfasis en la constante lucha entre
        “buenos y malos”.{" "}
      </p>,
      <p>
        Entonces, el sesgo ideológico que poseen los extremistas fanatizados
        sobre las figuras egregias de la humanidad, nos alerta que sus
        diferencias conceptuales implican distintos paradigmas. Para muchos
        marxistas, Jesucristo fue un revolucionario comunista, al manifestar:
        “si quieres ser perfecto, anda, vende lo que tienes, y dalo a los
        pobres, y tendrás tesoro en el cielo; y ven y sígueme” (Mat 19: 21).
        Más, su enseñanza parabólica pretende liberarnos de las cadenas de la
        egolatría y vanidad. Jesús no fue un caudillo político, sino espiritual…
        Por tanto, su sentencia al referirse a los impuestos: “Dad, pues, a
        César lo que es del César, y a Dios lo que es de Dios” (Mateo: 22: 21),
        me exime de comentarios… Los protagonistas de la independencia del Nuevo
        Continente, como George Washington (1732 - 1799); Simón Bolívar (1783 -
        1830); José de San Martín (1778 - 1850); Bernardo O'Higgins (1778 -
        1842) (Sosa P. Alberto 71 - 89) . y otros, no se trató de la lucha de
        líderes izquierdistas, como a veces se pretende.{" "}
      </p>,
      <p>
        Estos patriotas se sitúan en la ideología liberal, en boga en aquellos
        tiempos. Tampoco fueron socialistas, dado que aquella tendencia
        ideológica se desconocía en América… La lucha de estos héroes se encauzó
        por la liberación de sus países del colonialismo imperante.
      </p>,
      <p>
        En el caso del socialismo o comunismo populista, la explotación laboral
        no se da por parte de los burgueses; sino por parte del estado patrono
        con reglas más restrictivas; donde los profesionales de cualquier campo
        recibirán bajos ingresos. El principio socialista: “Distribución de
        bienes, intereses y riquezas en forma igualitaria”, aparentemente parece
        loable; más en la práctica resulta inequitativo; ya que ha derivado en
        una falsa consciencia social que deviene en la ley del menor esfuerzo.
        En este contexto, la llamada explotación al trabajador por parte del
        patrón o empresario que supuestamente se apropia de la plusvalía, es una
        verdad relativa. No es posible alegar abiertamente tal “robo de la
        fuerza laboral”, si existe previamente acuerdos voluntarios y beneficios
        para ambas partes: empleado y empleador. Además, el estado -llamado
        “burgués” por los socialistas- lleva una parte de esta plusvalía vía
        tributos e impuestos que debe pagar el patrono para el funcionamiento de
        su fábrica o empresa. Por su parte, el gobierno consciente de la
        procedencia de estos caudales que ingresan a las arcas del estado debe
        emplearlos apropiadamente en obras de infraestructura y/o ayuda a los
        sectores vulnerables.
      </p>,
      <p>
        En este contexto, no debe desconocerse la digna labor de los
        trabajadores, obreros, campesinos y demás gente honesta que
        históricamente han creado riqueza; y que, gracias a ellos los mercados
        son abastecidos continuamente. La censura es para aquellos que toman su
        nombre para sus mezquinos intereses; quienes aludiendo al “pueblo”
        pretenden tomarse el poder político, y luego gobernar a sus espaldas.
        Los obreros que producen bienes y servicios merecen el máximo respeto de
        los políticos y el gobierno, no solamente por su condición humana; sino,
        además porque la fuerza laboral es aquella que sostiene al Estado. No es
        posible escamotear la consciencia social de los trabajadores, con
        pretexto de sus legítimas reivindicaciones, para transformarlos en
        fanatizados sumisos de un sistema oprobioso ¡Los líderes honestos serán
        siempre glorificados y ovacionados¡; y, la farsa de los
        mitómanos-megalómanos pronto devendrá en la vindicta pública!
      </p>,
      <h5>“Superioridad del Pensamiento de Izquierda” </h5>,
      <p>
        El proselitismo populista de izquierda en su afán de ganar adeptos,
        pretende posesionar criterios equívocos en torno a la temática
        ideológica - política, en amplios sectores de la sociedad. Aquellos que
        rechazan sus quiméricos paradigmas sufren una especie de “bullying
        político”; y a veces se ven abocados a situaciones conflictivas. Muchos
        de ellos/as, replicantes de un discurso forjado no contrastado, al
        referirse a la “justicia social” creen poseer un estatus “de avanzada”,
        superior a la “derecha”; a quienes consideran: “retrógrados”,
        “estáticos”, “desfasados” o “de vieja data”; el cual a veces lleva al
        sector juvenil a avergonzarse de militar en un partido de aquella
        tendencia. Mas, nadie puede denigrar a nadie por su ideología; menos si
        su acción ha demostrado poco respeto por sus congéneres, y se encuentre
        motivado por odio y violencia verbal. Un militante de cualquier
        tendencia política debe sentirse orgulloso/a de pertenecer a su partido,
        porque conoce sus principios e ideales y ser parte de sus loables
        acciones reales.{" "}
      </p>,
      <p>
        Cuando se ven confrontados por sus contrincantes políticos, la
        mentalidad de los fanatizados, les dificulta explicar las razones de los
        regímenes totalitarios que han limitado las elecciones libres y demás
        libertades en nombre de la “revolución”. Entonces, usan la reticencia y
        sarcasmo para evadir los alegatos adversarios y evitar se evidencie su
        trivial razonamiento. Sus respuestas son confusas, fuera de contexto,
        remembrando nombres rimbombantes de artistas, científicos y filósofos
        que abrazaron esta ideología; quienes, en su tiempo en forma sincera
        pretendieron coadyuvar a la verdadera causa de los desposeídos, sin
        percatarse de la futilidad e incoherencia de esta teoría con la práctica
        social.{" "}
      </p>,
      <p>
        El razonamiento populista de izquierda radical, enfocado a atraer
        seguidores, auto concebido superior al de derecha, es indudablemente
        otra fantasía derivada de su fanatismo ideológico. Pretenden conferir a
        sus ideólogos similar distinción de grandeza, otorgado únicamente a los
        patriotas insignes; quienes, por lealtad a su patria o compasión a sus
        congéneres, sin presunción lucharon por la libertad y la democracia. Con
        seguridad aquí no encajan todos los extremistas; sino aquellos de
        ideología moderada (izquierda o derecha) que claman por justicia. Pues,
        no todos quienes levantan sus voces en las calles, plazas, foros y
        gremios, exigiendo los derechos de la gente sencilla, pueden llamárselos
        “patriotas”. Muchos demagogos están a la espera para empezar su carrera
        política y salir del anonimato, pretendiendo anarquizar, embaucar o
        figurar. El discurso extremista inculpador y su actitud violenta, será
        la señal que estamos frente a un potencial elemento fanatizado.{" "}
      </p>,
      <p>
        La estrategia populista, tendiente a atraer seguidores y alcanzar el
        poder político, impulsa todo tipo de artificios para mantener su
        protagonismo: ubicarse en la cúspide y definirse poseedores de la
        verdad, como subrayan los politólogos Duran y Nieto, en esta cita:{" "}
      </p>,
      <p>
        <i>
          Adoptan un aire de superioridad y proclaman que todo es político y,
          que la gente está obligada a entender la política como ellos lo
          sienten, desde sus pasiones. Se suponen analistas agudos porque han
          leído libros o porque fueron leales toda su vida a una misma
          ideología, o sea que fueron incapaces de aprender nada, a pesar de
          vivir en la etapa de cambios más vertiginosa de la historia de
          humanidad. Aman a los fósiles intelectuales, rechazan a quienes
          pretenden aprender y progresar a nivel intelectual. (Jaime y Santiago)
          .
        </i>
      </p>,
      <p>
        Su lucha ilusoria les hace sentirse “mártires” de los “enemigos del
        pueblo”; promueven conspiraciones ficticias a objeto de impulsar el
        sentimiento de compasión en la masa y, sumar el número de seguidores.
        Este complejo de superioridad les hace proferir frases despectivas
        endilgadas a sus contrincantes: “pity yanquis”, “escuálidos”, “burros”,
        “perros rabiosos”, “lacayos del imperio”, entre otros epítetos. Esta
        inculpación gratuita y la segregación a ciertos grupos humanos envenenan
        la consciencia social; lo cual constituye paradigmas nocivos promotores
        del odio, la separación, la desconfianza y la corrupción.
      </p>,
      <p>
        No obstante, la propia consciencia social deformada de ultraizquierda ha
        llevado a su fragmentación; y con ello, la fosilización de estos
        arquetipos filosóficos. Las diferencias ideológicas entre marxistas son
        insalvables. Esta separación ideológica constituye una réplica del campo
        religioso, con su variedad de sectas adueñadas de Dios, que pugnan por
        posesionar su verdad y ganar adeptos. Una pregunta surge de este dilema:
        ¿Cómo homologar los criterios de la ultra – izquierda que hoy apuestan
        por el voto popular, con aquellos que aún creen en la guerra de
        guerrillas?
      </p>,
      <p>
        En efecto, el discurso ideológico de la izquierda radical aceptado por
        muchos en los años de la adolescencia, a veces se fosiliza a través del
        tiempo en los subconscientes colectivos de ciertos sectores sociales.
        Pues, los impulsores del marxismo han calificado a esta teoría como un
        paradigma “sine qua non” para alcanzar una mejor sociedad. Los
        “revolucionarios” aún no comprenden que toda sociedad siempre ha
        gestionado la libertad y la democracia. Solo la democracia participativa
        por medio de un período de preparación de largo aliento, coadyuvará a
        una renovación moral - espiritual humana para las transformaciones de
        las sociedades. En este proceso de mayor humanización, los líderes
        políticos y religiosos están retados a superar en valores éticos y
        morales a las personas corrientes. El hombre será respetado en su
        multilateralidad ideológica, sin ser perseguido por sus ideas. ¡La
        teoría será confrontada, mejorada y ejecutada! El siguiente ideario
        socialista será enriquecido con otros planteamientos teóricos que
        permitan eliminar el dogmatismo, y llevarlo a la práctica de inmediato:
      </p>,
      <p>
        <i>
          El comunismo otorga a las personas abundancia de bienes materiales y
          espirituales y crea las condiciones para la felicidad. Pero para
          lograr todo ello les plantea serias exigencias. Los bienes vitales
          deben conseguirlos con su propio trabajo. La igualdad no puede
          consolidarse hasta que los hombres no se traten entre sí como amigos y
          hermanos. No puede ser plenamente libre aquella persona dominada por
          los prejuicios religiosos. No puede ser feliz el pobre de espíritu, el
          que se sienta ajeno a la alegría del conocimiento, sordo y ciego ante
          la belleza del arte. (G. Shajnazárov, A. Boborykin y Y. Krazy, La
          Educación del Hombre Nuevo.) .
        </i>
      </p>,
      <p>
        Las creencias dogmatizadas y los falsos paradigmas en el sector de la
        derecha populista parecen menos fanatizadas. Es decir, la proporción de
        violencia desatada por los fanáticos de esta ideología en comparación
        con la versión de los prototipos de izquierda, a simple vista es menor.
        No obstante, los vicios populistas que se exponen resultan comunes para
        ambos bandos, aunque parezcan más visibles en el sector de las
        izquierdas.
      </p>,
      <h5>Socialismo “humano”, Capitalismo “inhumano” </h5>,
      <p>
        A través de este falso paradigma, el marxismo procura posesionar en la
        consciencia colectiva: la “virtud del socialismo” y “el engaño del
        capitalismo”. Desde su óptica, para superar esta falencia se debe acabar
        con la clase “burguesa” a través de la conquista del poder político por
        parte del proletariado. Aquello resulta una utopía: los países que
        vivieron ese sistema lo demostraron. La decantada humanización
        socialista resultó una quimera. El buró político y demás burócratas se
        transformaron en los nuevos explotadores de los trabajadores, obreros y
        campesinos. Su estrategia política es el control; la restricción de la
        libertad y la constante beligerancia a quienes no se adaptan al sistema,
        incluyendo torturas, persecuciones y genocidio.
      </p>,
      <p>
        Los populistas de izquierda, en el proceso de convencimiento al pueblo,
        no anuncian las medidas restrictivas que adoptará el gobierno cuando
        tome el control del estado; que incluye a veces el cambio de ideología.
        En su verborrea proselitista sostienen que el sistema “capitalista
        inhumano” ha devenido en pobreza generalizada, que sólo su caudillo
        promoverá la verdadera equidad y bienestar de la gente. Se niegan a
        entender que la igualdad económica – social es quimérica; la cual es
        posible a través de la fuerza, y únicamente a través de la
        generalización de la pobreza; jamás tendiente a su crecimiento. En otras
        palabras, se puede empobrecer a todos atentando la libertad, pero el
        crecimiento económico será paupérrimo.{" "}
      </p>,
      <p>
        En este contexto, el control del mercado y el establecimiento de precios
        asignados por el gobierno deviene en el acaparamiento de bienes de
        consumo, el racionamiento y la anarquía social, lo cual atrae a su vez a
        la corrupción y el aparecimiento del mercado negro. En torno al referido
        control gubernamental, el abogado Edgar Vargas, un articulista Master en
        Derecho Mercantil, escribe:
      </p>,
      <p>
        <i>
          La naturaleza del Estado no es la de ser una entidad benéfica, es un
          monopolio con fines de lucro cuya función es el saqueo de la población
          a través de impuestos con maquillaje ‘legal’ cargado de populismo, es
          por ello que el resultado es y será siempre una sociedad sumamente
          rígida y controlada, servil y dócil, donde existe una casta gobernante
          privilegiada con una población obligada a vivir de las migajas,
          prácticamente impedida a generar riqueza por sí, para sí y superarse.
        </i>
      </p>,
      <p>
        <i>
          Esto no es un fenómeno nuevo, ya ha ocurrido y sigue ocurriendo en
          muchas partes, ocurrió en la Unión Soviética, Alemania del Este, en
          Chile con Salvador Allende, ocurre en Cuba, Norcorea, Venezuela. No es
          coincidencia, tampoco sorpresa, no es culpa de conspiraciones de otros
          gobiernos, es una consecuencia del error fundamental de la teoría
          Marxista.
        </i>
      </p>,
      <p>
        <i>
          (…) El Estado plantea una estructura jerárquica coercitiva que termina
          por formar castas protegidas de ricos sobre un mar de pobres, diciendo
          que quieren combatir la desigualdad siempre la terminan agravando.
          Pero ¿es en realidad la pobreza el problema de los marxistas? No, en
          el discurso populista marxista la pobreza es una virtud, ellos te
          quieren y te necesitan pobre para afianzarse en el poder. (…) la
          igualdad de hecho es intrínsecamente injusta, no pueden pretender que
          alguien con menos experiencia o menos estudios gane un salario igual
          que alguien con más experiencia y preparación, ese es el imperio de la
          mediocridad, la destrucción de la meritocracia, resentimiento puro y
          duro (Vargas) .
        </i>
      </p>,
      <p>
        El mercado laboral y estándar de vida de la gente está en relación no
        solo al nivel de desarrollo del país; sino, además: a la libertad y la
        democracia, a la solidez de las instituciones estatales, los sistemas
        judiciales y los capitales financieros. Sólo basta evidenciar: los
        emigrantes que dejan sus patrias en busca de trabajo y mejores
        oportunidades de vida, provienen de los países donde campea la
        corrupción, la inestabilidad y la política-económica de corte populista.
        Estos grupos humanos así desplazados, víctimas de un sistema oprobioso,
        siempre prefieren radicarse en los países libres con mejor democracia,
        muy poco en aquellos del sistema comunista. No obstante, la ultra
        izquierda ortodoxa aún cree en un gobierno que solucione todo el
        problema del desempleo; en el estado omnipotente y gigantesco que
        controle y regule todos los ámbitos del convivir humano. Ellos proponen
        institucionalizar la destrucción del estado de derecho, por el desprecio
        a las libertades e instituciones que la componen. Su primera medida
        parece ser la generalización del odio; que además de la degradación
        humana, conlleva la inminente amenaza de repetir las hecatombes
        guerreristas.{" "}
      </p>,
      <p>
        Las medidas económicas socialistas radicales, como la redistribución de
        la riqueza a través de impuestos; la violación del derecho de propiedad
        y la seguridad pública; la vigilancia de capitales para evitar la fuga
        de divisas en el mercado bursátil; el control de los medios de
        comunicación; y, la restricción de libertades, deviene en penurias de
        todo orden. Entre los efectos inmediatos se observa: el incremento del
        riesgo país, el desempleo, el incremento de burocracia ineficiente; y,
        el aumento de la corrupción. Los críticos a estos sistemas comparan las
        políticas populistas actuales con aquellas fascistas del siglo pasado,
        en los siguientes términos:
      </p>,
      <p>
        <i>
          El nazismo alemán y el fascismo italiano, por ejemplo, aunque con un
          núcleo ideológico más depurado, y otras importantes diferencias con lo
          que hemos visto en nuestra región, también fueron movimientos
          populistas que hicieron del odio a la libertad individual y la
          adoración del Estado su propulsor fundamental. Lo cierto es que, más
          allá de la complejidad de las comparaciones, ideológicamente gente
          como Mussolini, Hitler, Stalin y Mao estuvieron en la misma
          trayectoria de un Chávez, Perón, Castro, Iglesias, Allende, Maduro,
          Morales, Correa, López Obrador, Kirchner y Bachelet en su segundo
          gobierno, en el cual implementó un programa refundacional con el
          objetivo de terminar el exitoso sistema de libertades prevaleciente
          por más de tres décadas. Guardando las distancias históricas y
          culturales el elemento ideológico antiliberal, antindividualista y
          anticapitalista radical fue tan de la esencia del nazismo y del
          fascismo como lo es del socialismo populista del pasado y del
          socialismo del siglo XXI promovido por Chávez y sus seguidores
          latinoamericanos y europeos en general. (…)
        </i>
      </p>,
      <p>
        <i>
          Lenín, Stalin, Hitler, Chávez, Mao, Mussolini y Castro, por nombrar
          algunos, son, en esencia, representantes de la misma ideología
          totalitaria. De hecho, la sigla NSDAP del partido Nazi era una
          abreviación del Partido Nacional Socialista Obrero Alemán
          (Nationalsozialistische Deutsche Arbeiterpartei). Así las cosas, más
          allá de todas las diferencias, los populismos que llevaron a Europa a
          la ruina son primos hermanos, no sólo del socialismo marxista sino
          también de los populismos socialistas que han condenado a la miseria a
          América Latina. (Kaiser y Álvarez 24 -25) .{" "}
        </i>
      </p>,
      <p>
        Marxistas y socialistas populistas en su afán de atraer la voluntad de
        la gente y posesionar el criterio que: “el socialismo es humano”,
        parecen apadrinarse con los holgazanes e infractores de las leyes y,
        prestar poca importancia al trabajo de científicos, inventores y demás
        empresarios visionarios que posibilitan fuentes de trabajo. Thomas
        Edison, precursor de la luz eléctrica; y Bill Gates el filántropo
        empresario informático, lograron verdaderas revoluciones en el campo
        laboral con impacto en la producción, el empleo y la educación. Gracias
        a la genialidad de estos prohombres innovadores, más el coraje de los
        inversionistas, se han creado millones de fuentes de trabajo a nivel
        planetario. Los estadistas responsables reconocen la capacidad de crear,
        arriesgar e invertir de los financieros que permiten la multiplicación
        del empleo; y como inversionistas de los bienes del estado, son
        conscientes de las dificultades que a veces pueden llevar a la banca
        rota.
      </p>,
      <p>
        Por tanto, el líder político para dirigir un país debe poseer cierta
        experiencia como un empresario digno de facilitar la multiplicación del
        trabajo. Aquello implica la creación y la ejecución de leyes tendientes
        a evitar la sobreexplotación humana; y, la protección de las empresas
        que permitan la generación del empleo digno. Estas deben proveer un
        trato preferente para los propiciadores del trabajo y sus ejecutores:
        inventores, científicos, artistas, empresarios, artesanos y trabajadores
        en general; dado que detrás de cada uno de ellos, existe una historia de
        esfuerzos, reveses y a veces privaciones. Aquello contrasta con la
        conducta de los apáticos acomodaticios; quienes manteniéndose en su zona
        de confort critican a los intrépidos y esforzados emprendedores, y
        exigen “sus derechos”. En torno al esfuerzo poco reconocido, puede
        colegirse de las palabras del eminente científico Nikola Tesla,
        investigador de la corriente alterna, cuando manifestaba: «Todo mi
        dinero lo he invertido en experimentos para realizar nuevos
        descubrimientos que permitan a la humanidad vivir un poco mejor». Es
        decir, los quijotes pioneros a través de sus empresas sirven mejor a los
        sectores vulnerables que los “filántropos de los bienes ajenos”, quienes
        minimizan y a veces detestan a los generadores del trabajo… El best
        seller, Robert Kiyosaki en su libro: “La Ventaja del Ganador”, asegura
        al respecto:{" "}
      </p>,
      <p>
        <i>
          Además, si eres capitalista puedes ser parte de la solución; si eres
          socialista, eres parte del problema. Si esperas que el gobierno
          resuelva tus dificultades, entonces estás en aprietos porque los
          gobiernos de todo el mundo están quebrados.
        </i>
      </p>,
      <p>
        <i>
          En lugar de ser parte del problema, súmate a la solución: vuélvete un
          capitalista genuino, y enfócate más en dar que en recibir. Esos días
          en que esperabas que te pagaran más por hacer menos, llegaron a su
          fin.
        </i>
      </p>,
      <p>
        <i>
          Pero no me malinterpretes, no hay nada de malo en tener ideas
          socialistas, de hecho, necesitamos gente que se preocupe por los
          demás. El problema es que cuando crees que un ‘almuerzo gratis’, es
          algo viable, el socialismo se torna en codicia y, como ya sabes, el
          mundo está repleto de gente codiciosa de ambos tipos: capitalistas y
          socialistas (Kiyosaki) .
        </i>
      </p>,
      <p>
        Entonces, los socialistas moderados y responsables, que reconocen el
        valor del trabajo y emprendimiento responsable han marcado sus
        diferencias con los politiqueros populistas extremistas, éstos que por
        medio de su discurso desaforado en contra de los propiciadores del
        empleo procuran captar el poder a base de su demagogia y fanatismo.
        Quienes a sabiendas que el éxito implica la disciplina en cualquier
        campo, pretenden hacer de la “ley del menor esfuerzo” una plataforma
        política en pro de sus mezquinos intereses. No se puede poner en tela de
        duda el principio de la virtud de trabajo; debe contrarrestarse aquella
        metaética de los hackers y tramposos que pretenden posesionar “sus
        nuevos valores” dentro de un sistema de anarquía.{" "}
      </p>,
      <p>
        Por su parte, el capitalismo como sistema económico donde se producen e
        intercambian bienes y servicios a través de actividades comerciales de
        libre mercado, regulados por la ley de la oferta y la demanda, ha sido
        el régimen más ajustable a la naturaleza de libertad del género humano.
        En los siglos XV al XVIII, los estados monárquicos de Francia e
        Inglaterra adoptaron el régimen llamado mercantilismo. Equella política
        implicaba la intervención casi total del estado en la regulación de la
        economía. Más tarde, la aparición de los fisiócratas y el pensamiento de
        Adam Smith, considerado “el padre del capitalismo”, favoreció el auge de
        la Revolución Industrial; y con ello, el apogeo del capitalismo moderno.
        Este sistema económico social fundamentado en el capital y la propiedad
        privada de los medios de producción, constituye la fuente de la
        generación de riqueza y se ha considerado opuesto al socialismo que
        propone la propiedad colectiva. En ambos sistemas se observan penurias
        económicas para la población; pero en el socialismo se ha condicionado
        con mayor frecuencia las libertades humanas, con las consecuentes
        secuelas negativas.{" "}
      </p>,
      <p>
        Entonces, el criterio de que el capitalismo es “inhumano” es un juicio
        de valor relativo. Este modelo de organización socioeconómico sustentado
        en el libre intercambio de bienes y servicios sin intervención del
        estado, se fundamenta en el modelo de libre mercado. Sus principios
        basados en el libertarismo tienen como criterio básico, el respeto a la
        propiedad privada y el usufructo de los ingresos que resultan de tu
        propio trabajo. No me refiero al híper - capitalismo “salvaje”; sino
        aquel que incluye la economía social de mercado, afianzado en la
        libertad y la democracia. Aquel proceso de producción mixto direccionado
        a la superación de la extrema pobreza; donde todo visionario que
        emprenda cualquier tipo de empresa lícita se sienta libre de crear y
        producir, de emprender e invertir.{" "}
      </p>,
    ],
  },
  {
    section: 30,
    title:
      "Métodos para Mantener el Poder en Regímenes Populistas Totalitarios",
    ref: "/MetodosParaMantenerElPoderEnRegimenesPopulistasTotalitarios",
    parrafos: [
      <h5>Imposición del miedo para Someter y Domesticar las Masas</h5>,
      <p>
        A pesar de la revolución de las nuevas tecnologías de la información y
        la comunicación social (Tics) en estas dos últimas décadas del S. XXI,
        aún existen líderes totalitarios: comunistas, socialistas populistas,
        populistas de derecha, teocráticos, etc. Por medio de sus criterios
        fosilizados y decadentes, en relación al hombre, la sociedad y el
        pensamiento, pretenden mantener a la sociedad sometida a sus dogmas
        político – religiosos. Este anquilosamiento en sus ideologías dogmáticas
        deviene en fanatismo, lo cual les impide aceptar la verdad con su
        carácter cambiante y provisional. Toda dictadura de partido único se
        basa en la eliminación de las libertades y la imposición del miedo como
        estrategias de intimidación en la masa para conservar el poder. Las
        persecuciones a líderes y lideresas contrarios a su ideología es una
        práctica común, mientras por medio de la mordaza y el acoso a la prensa
        libre pretenden imponer su única verdad.{" "}
      </p>,
      <p>
        La carencia de democracia se evidencia cuando los poderes del estado son
        captados por el totalitarismo en el poder. Los magistrados y/o
        representantes del pueblo, no procuran a través del debate y flujo de
        ideas adoptar las mejores alternativas para imponer una justicia neutral
        y transparente. Su legislación está ajustada con los ideales de los
        representantes del buró, caudillo o “comandante en jefe”; entonces,
        subsiste la sensación de que las leyes y resoluciones son aprobadas no
        por convicción, sino por temor.{" "}
      </p>,
      <p>
        De esta manera, el caudillo populista una vez ha captado el poder
        político del estado, inicia el proceso de desinstitucionalización de las
        entidades estatales instituidas; luego se instaura una gigantesca
        campaña de propaganda a favor del gobierno “revolucionario”. El acoso a
        la prensa libre y demás entidades democráticas constituyen estrategias
        para perennizarse en el poder. Se establece una verdadera cruzada de
        difamación y hostilidad como política de gobierno direccionado a quienes
        piensan distinto y critican sus medidas, denominándoles “enemigos del
        pueblo”. Promulgan leyes para atosigar o proscribir la libre empresa:
        banqueros, empresarios, exportadores, importadores, industriales,
        intelectuales y profesionales exitosos son perseguidos a través de una
        guerra psicológica con el propósito de infundir pánico. Todos quienes
        poseen fortunas deben ser “investigados”: como si la tenencia de bienes
        constituyera una transgresión a la ley.
      </p>,
      <p>
        El procedimiento favorito de los extremistas para detentar y mantenerse
        en el poder, consiste en aterrorizar a sus adversarios. La creación de
        grupos armados paralelos a los convencionales, prohibidas en la mayoría
        de cartas magnas del mundo, es su estrategia para defender su gobierno y
        “revolución”. Por medio de nombres sugestivos pretenden justificar la
        maniobra inmoral y la violencia de los mismos. Su función es “defender
        al pueblo o la democracia” inexistente; e incluso a veces se presentan
        como víctimas ante los ojos ciudadanos. Su cinismo les vuelve
        desconfiados hasta de sus propios amigos o camaradas; conocedores de sus
        regímenes oprobiosos se vuelven suspicaces de caer víctima de sus
        propios excesos a través de una insurrección popular.
      </p>,
      <p>
        Mussolini fundó en 1919, la Milizia Volontaria per la Sicurezza
        Nazionale, conocida como “camisas negras” o Guardia Armada Nacional -al
        “servicio de Dios y de la Patria”-, quienes infundieron pánico en Italia
        a partir de su creación. En 1933, cuando Hitler tomó el poder en
        Alemania propagó el terror por medio de los Escuadrones Paramilitares de
        Defensa (SS), creadas para la seguridad de su partido Nazi. Sus métodos
        de genocidio y tortura se demostraron en la “Noche de los Cristales
        Rotos”, cuando atentaron con crueldad la vida y propiedad del pueblo
        hebreo. En 1957, Duvalier, un populista de ultra derecha, al llegar al
        poder, creó la temible fuerza pública particular llamada: “Voluntarios
        para la Seguridad Nacional o Tonton Macoute”; aquello, a fin de sembrar
        el pánico en la población y acallar a sus detractores políticos.{" "}
      </p>,
      <p>
        La “Gran Revolución Cultural Proletaria China” (1966 - 1976), que
        pretendía limpiar los rezagos de “pensamiento burgués del pueblo chino”,
        constituye la etapa más sangrienta desde el ascenso al poder de Mao Tse-
        Tung. El fracaso de su programa económico: “El Gran Salto Adelante” que
        pretendió posesionar a China en el contexto internacional como la
        potencia militar y económica en corto tiempo, le hizo paranoico e
        implacable. La crítica de sus rivales políticos dentro del partido
        comunista que develaron la muerte de millones de seres humanos por
        inanición, en la Gran Hambruna China, debía ser castigada. El tirano
        alegaba que los revisionistas burgueses infiltrados en el gobierno y
        sociedad, pugnaban por restaurar el capitalismo. Entonces, con este
        movimiento pretendía acrecentar el culto a su personalidad; y, sobre
        todo, eliminar a sus rivales políticos del Partido Comunista de China
        (PCCh), a través de la profundización del miedo como método de gobierno.
        La juventud fanatizada por el dictador a través de las consignas del
        “Libro Rojo de Mao”, preparó el levantamiento genocida en la Plaza de
        Tiananmen.
      </p>,
      <p>
        <i>
          El instrumento utilizado para ello fueron los jóvenes, encuadrados en
          el movimiento de los ‘guardias rojos’. Eran estudiantes nacidos bajo
          el comunismo que representaban al ‘nuevo hombre’ solidario y sin
          prejuicios pequeños burgueses que se quería construir…Alentados por la
          esposa de Mao (Jiang Qing) y sus secuaces, se pusieron el uniforme
          verde con la gorra de la estrella roja y empezaron a atacar a los
          mayores (principalmente a los ‘con gafas’, como se conocía a los
          profesores e intelectuales, pero también a los viejos jerarcas del
          partido e incluso a sus propios padres). (Pellini, Historia y
          biografias. Guardias Rojos de Mao en China - Gran Revolución Cultural)
          .
        </i>
      </p>,
      <p>
        La purga masiva durante la mal llamada Revolución Cultural, incluyó la
        persecución a importantes líderes chinos como Liu Shaoqi y Deng
        Xiaoping; la cual concluyó con una cifra de miles de asesinatos,
        encarcelamiento, confiscaciones, trabajos forzados, torturas y
        ejecuciones.{" "}
      </p>,
      <p>
        A inicios del siglo XXI, el gobierno socialista de Chávez creó los
        denominados: “colectivos de la revolución” -organizaciones comunitarias
        chavistas de apoyo a la Revolución Bolivariana, inspiradas en las
        milicias iraníes -; estos colectivos armados supuestamente eran
        encargados de “promover la democracia” y otras “actividades culturales”,
        pero se transformaron en bandas paramilitares… Su tarea era detener las
        marchas de protestas: atacar a periodistas y demás críticos del gobierno
        con acciones de violencia, amedrentamiento y asesinatos (D. García) .
        Posteriormente, la Asamblea Nacional venezolana en 2019, denunció la
        práctica de terrorismo de estado ejercida por Nicolás Maduro a través de
        estos grupos.
      </p>,
      <p>
        Por cierto, toda dictadura por recalcitrante que parezca posee sus
        fanáticos defensores de este tipo de política de estado; quienes, a
        manera de un resentimiento social colectivo, justifican la adopción de
        estas siniestras estrategias para el control total del poder. Sin
        embargo, las mayorías sociales en estos regímenes, poco a poco hacen
        consciencia de las restricciones en que viven. Algunos sectores
        amenazados en sus derechos ciudadanos, emigran; otros, temerosos
        condenan las libertades conculcadas por el sistema. Tardíamente el
        pueblo se da cuenta que, “la revolución” ha tenido un costo demasiado
        elevado que ha disminuido y denigrado la calidad de vida de los grupos
        sociales excluidos. Pero la gran espiral de causas y efectos no siempre
        indultan las acciones perversas de los verdugos del pueblo; así, los
        karmas de estos obscuros personajes comúnmente los lleva a concluir en
        fatales desenlaces. ¡Y, aunque parezca grotesco e inverosímil, sus
        fanáticos/as seguidores, con frecuencia los/las convierten en “héroes o
        heroínas”, por haber conculcado la libertad y la democracia ciudadana!
      </p>,
    ],
  },
  {
    section: 31,
    title: "Culto a la Personalidad",
    ref: "/CultoALaPersonalidad",
    parrafos: [
      <p>
        La dictadura de ultra derecha populista - fascismo, nazismo; etc. -
        tiene puntos de similitud y divergencia con su similar de ultra
        izquierda populista – anarco - comunismo, maoísmo, populismo socialista;
        y otros. Su analogía radica en la exaltación del culto a la
        personalidad: la adulación excesiva al líder carismático a veces con
        dimensiones casi sagradas, siempre encaminado a la perpetuación del
        poder. Su “guion” de valores contrapuestos, variables e improvisados,
        pretende convencer al pueblo que su figura política es la única
        destinada a cambiar el curso de la historia del país. Hitler
        identificado con el título de “führer ”; y Mussolini, de “Il Duce” -
        menciones de líderes supremos -, originalmente pertenecieron al
        socialismo, pero gobernaron opuestos a aquellos principios al llegar al
        poder.
      </p>,
      <p>
        En este tipo de regímenes existe una especie de “acuerdo populista”
        implícito, entre el caudillo carismático y sus adeptos, para impulsar el
        culto a la personalidad en favor de su figura política. Ahí se combina:
        demagogia, prepotencia y tiranía. Los camaradas seguidores temerosos de
        expresar sus críticas, propias de una democracia participativa para la
        enmienda de errores, se abstienen de hacerlo. Su mass media, publica
        alabanzas carentes de mérito: ovaciones exageradas a sus obras por todas
        partes. Al caudillo carismático le han suministrado una sobre dosis de
        vanidad que ha acrecentado su ego personal: el “opio del poder” lo va
        envaneciendo. Es evidente que ya sufre de megalomanía; pues, requiere
        mayor autoridad para gobernar; cual narcotizado del poder se encuentra
        sin consciencia, ni de sus errores ni de sus limitaciones. En ese trance
        se creen “los mejores gobernantes del mundo”; se hacen otorgar los más
        altos títulos honoríficos, académicos o militares. Sus asesores, cual
        “makiavelos” aconsejan a su “Príncipe”, incluso usar el engaño y demás
        medios inmorales para alcanzar su fin de supervivencia y gloria. Al
        respecto, el propio Makiavelo, para adular al dictador escribió en su
        obra:
      </p>,
      <p>
        <i>
          Tener por preceptor a un maestro mitad bestia y mitad hombre no quiere
          decir otra cosa sino que un príncipe necesita saber usar una y otra
          naturaleza; y que la una sin la otra no es duradera.
        </i>
      </p>,
      <p>
        <i>
          (…) Debe entre ellos imitar a la zorra y al león a un tiempo: porque
          el león no se defiende de las trampas, y la zorra no se defiende de
          los lobos. Es necesario, pues, ser zorra para conocer las trampas y
          león para destrozar a los lobos. Los que sólo toman por modelo al león
          no entienden sus intereses. Por tanto, un príncipe prudente no puede
          ni debe mantener fidelidad en las promesas, cuando tal fidelidad
          redunda en perjuicio propio, y cuando las razones que le hicieron
          prometer ya no existen. (…) Pero es necesario encubrir bien este
          natural, y tener gran habilidad para fingir y disimular: los hombres
          son tan simples, y se someten hasta tal punto a las necesidades
          presentes, que quien engaña encontrará siempre quien se deje engañar
          (Maquiavelo 85) .
        </i>
      </p>,
      <p>
        Los crímenes del estalinismo soviético: las persecuciones, asesinatos y
        demás excesos del líder comunista no fueron denunciados oportunamente,
        por temor o lealtad. Jrushchov, a través de su memorable discurso
        pronunciado a puerta cerrada, en el XX Congreso del Partido Comunista de
        la Unión Soviética, al referirse a la “Gran Purga” de 1937-1938,
        expresaba:
      </p>,
      <p>
        <i>
          Tras la muerte de Stalin, el comité central del partido se propuso
          explicar constantemente y con claridad que es inaceptable y ajeno al
          espíritu del marxismo - leninismo glorificar a una persona,
          transformarla en un superhombre, atribuyéndole cualidades
          sobrenaturales semejantes a las de una divinidad: esa persona lo sabe
          todo, lo ve todo, sabe hacerlo todo, piensa por todos nosotros y es
          infalible en su conducta (…).
        </i>
      </p>,
      <p>
        <i>
          Fueron muchos los abusos que se cometieron bajo órdenes de Stalin, y
          desdeñando todos los principios del partido y de la legalidad
          soviética. Stalin era muy desconfiado, receloso hasta extremos
          enfermizos!{" "}
        </i>
      </p>,
      <p>
        <i>
          (…) Sospechaba de todos, incluso de eminentes trabajadores del partido
          que conocía desde hacía años. En todas partes veía enemigos,
          traidores, espías. Dotado de un poder sin límites, actuó con tal
          arbitrariedad, infligiendo a sus víctimas un tormento moral además del
          físico. Se creó así una situación en la que nadie podía expresarse con
          libertad (…). (Burnet 72 -73) .
        </i>
      </p>,
      <p>
        El escritor peruano, Mario Vargas Llosa galardonado con el Premio Nobel
        de Literatura en el 2010, al referirse a otro fanatizado de izquierda,
        Abimael Guzman, alias “Presidente Gonzalo”, Jefe de la organización
        terrorista peruana, Sendero Luminoso, que causó alrededor de 30.000
        muertos y daños aproximadamente de 20 millones de dólares en la década
        de los 80, escribió:
      </p>,
      <p>
        <i>
          Aunque se declara marxista – leninista y, sobre todo, maoísta, Sendero
          Luminoso practica el culto de la personalidad de manera cuasi
          religiosa y ha divinizado a su líder ’la cuarta espada del marxismo’,
          a extremos que sólo Stalin y Mao alcanzaron en sus períodos de
          gloria”. (…) La captura de Guzmán ha despertado grandes ilusiones en
          un país ya harto de los dinamitazos y apagones cotidianos, del toque
          de queda, de vivir en la inseguridad y el miedo. Por ello, millares de
          peruanos, al conocer la noticia, salieron a las calles, exultantes, a
          cantar el himno nacional y embanderaron sus casas. (Vargas Llosa 215
          -217) .{" "}
        </i>
      </p>,
      <p>
        Entonces, si bien el marxismo - leninismo por su naturaleza se ha
        proclamado contrario a la excesiva “admiración” al caudillo; sin
        embargo, muchos seguidores de esta tendencia por medio de imágenes,
        eslóganes y fotos por todas partes, transformaron a sus líderes en
        “seres sobrenaturales”. Desde esta perspectiva, el culto a la
        personalidad impulsada por el fanatismo ideológico - político, no es
        legítimo: “sus proezas” no tienen sustento real. La falsa devoción hacia
        el líder carismático está en relación a la cantidad de ilusos engañados
        que creen ciegamente en las “hazañas” de su líder. Incluso, esta
        “divinización” concedida por los sectores fanatizados a sus dictadores
        favoritos, también se endilga a los capos de la mafia; así como ciertos
        personajes del campo religioso, artístico y deportivo.
      </p>,
    ],
  },
  {
    section: 32,
    title: "Incitación al odio como política de estado",
    ref: "/IncitacionalOdioComoPoliticaDeEstado",
    parrafos: [
      <p>
        El odio como política de estado se observa con frecuencia en los
        regímenes populistas de izquierda, debido a la polarización social
        propiciado desde el oficialismo. El caudillo para mantener el poder,
        estratégicamente se vincula a la masa mayoritaria: “los marginados”; lo
        cual es bueno, si se incluye los demás sectores sociales del País. El
        error surge cuando su política se orienta preferentemente al sector
        social marginal, en detrimento del resto de grupos sociales; mientras el
        segmento de clase media ¬- llamada por los marxistas: “pequeña
        burguesía” -; junto a los acaudalados propietarios de empresas y
        factorías, son insultados y perseguidos. Esta discriminación, equivale a
        un padre que excluye a algunos de sus hijos por el hecho de tener
        talentos especiales. ¡Es injusto agraviar a quienes emplearon sus dotes
        en la producción de bienes o servicios materiales, si estos mismos
        constituyen el brazo derecho del gobierno en ofrecer plazas de empleo!
        ¿Por qué agraviar al profesional o al especialista que paga impuestos al
        estado, que cumplen tareas específicas y se sacrificaron en su
        formación? ¿Por qué ridiculizar a quienes han planificado, trabajado y
        arriesgado durante mucho tiempo con esfuerzo y paciencia? ¡Es indebido!
        No deben ser ni humillados ni denigrados, deben ser apoyados. Los abusos
        de cualquiera de estos profesionales, deben ser sancionados de acuerdo a
        la ley.{" "}
      </p>,
      <p>
        La hostilidad política se incrementa a medida que el tirano verbal en el
        poder, inculpa de las limitaciones sociales al capitalismo y sus
        “defensores criollos”; cuando confronta, insulta y juzga a libre
        albedrío desde cualquier tribuna. Esta retórica de revancha, fanatismo y
        discriminación genera alejamiento, división y suspicacia entre los
        núcleos familiares como consecuencia de la inculpación y diatriba
        gratuita, mismo que debe proscribirse en todas las constituciones
        democráticas del planeta. El resentimiento social creado, no es
        consecuencia de la lucha de clases como muchos pretenden posesionar,
        sino de sus impulsores que obtienen réditos del rencor fútil que corroe
        el alma de la gente sencilla; aquellos megalómanos dedicados a timar la
        consciencia del pueblo con el criterio de mantenerse en el poder. Un
        opositor del socialismo venezolano comenta en torno a esta temática:{" "}
      </p>,
      <p>
        <i>
          Es público y notorio la exacerbación del odio por parte del discurso
          oficialista, mientras el proceso bolivariano más insulta a la clase
          media, más es el rechazo visceral de esta ante los atropellos. Y en
          consecuencia el rechazo casi automático de cualquier medida
          oficialista. Eso es oro para el aparato gubernamental de propaganda,
          por eso hacen fiesta cada vez que graban la molestia de una señora
          furiosa en el este de Caracas, por ejemplo. De esta forma el gobierno
          impide eficazmente que sus bases populares, a pesar de su evidente
          descontento por la crisis, cambien su postura política en apoyo a
          propuestas opositoras. Ya que se ven insultados por los mismos que los
          necesitan para crecer y cambiar la realidad política. Esa división
          racial, económica, política y cultural que promueve el gobierno le
          genera aún grandes dividendos electorales. El odio es el seguro de
          vida de la revolución. Si logramos derrotar el odio de clases,
          estaremos más cerca de un cambio sustancial en Venezuela (Rivas) .
        </i>
      </p>,
      <p>
        La toxicidad de esta malsana emoción que no sólo contamina la salud de
        la población, sino atenta contra la unidad de las familias debe ser
        castigado, y los impulsores deberán responder por las consecuencias de
        sus discursos hostiles. La imposición de este sentimiento negativo como
        estrategia marxista para la lucha contra el “anti pueblo”, fue
        establecida por Ernesto Che Guevara, en los siguientes términos:
      </p>,
      <p>
        <i>
          El odio como factor de lucha; el odio intransigente al enemigo, que
          impulsa más allá de las limitaciones naturales del ser humano y lo
          convierte en una efectiva, violenta, selectiva y fría máquina de
          matar. Nuestros soldados tienen que ser así; un pueblo sin odio no
          puede triunfar sobre un enemigo brutal. (E. Guevara) .
        </i>
      </p>,
      <p>
        Ciertamente, un líder elevado jamás invocará al odio para generar una
        revolución por más trascendental que considere. Este maléfico
        sentimiento, rechazado en todas las religiones debe proscribirse de la
        estirpe humana a través de un proceso de concienciación. Prohombres como
        Mahatma Gandhi, Dalai Lama, Bayard Rustin, Nelson Mandela entre muchos
        otros, han logrado verdaderas rebeliones de liberación por medio de
        procesos revolucionarios pacíficos y conscientes, sin odio. En
        contraste, el único método que adoptan los fanáticos de la izquierda
        populista ha sido la polarización social y la desinstitucionalización
        del estado. Sus discursos saturados de temor, insulto y crítica a sus
        adversarios, trae como efecto: recelo y rencor en otros sectores de la
        población. La clase media y alta amenazada a perder sus patrimonios
        familiares a través de la expropiación y la estatización, trata de huir;
        mientras la mayoría de trabajadores honestos: empleados, obreros,
        indígenas, campesinos, etc., saben que en cualquier sistema deben
        trabajar y ganarse el sustento con sacrificio. Más, para el sector de
        holgazanes envidiosos/as, forajidos y demás ingenuos fanatizados, creen
        que ha llegado su oportunidad. Solo es una entelequia. Si las quiméricas
        medidas (embargos, expropiaciones, etc.) coadyuvaran a solucionar el
        problema del desempleo, la pobreza y la corrupción, quizá se
        justificaría su ejecución; pero aquellas sólo las profundizan y la
        agravan.{" "}
      </p>,
      <p>
        Es democrático confrontar, debatir y sostener con pasión lo que
        consideramos recto en base a la justicia y la razón, con prevalencia del
        excelso sentimiento del amor; pero es antihumano propiciar odio que
        degrada nuestra especie. En cuanto al resentimiento que se pretende
        sembrar con respecto a la política del imperialismo norteamericano; he
        aquí 2 preguntas para la reflexión: ¿Cómo es posible odiar al sistema
        capitalista que ha acogido a los emigrados que huyen de sus naciones por
        la persecución o la pobreza? ¿Por qué estar resentidos con la política
        del País que ha liderado la defensa de la democracia en contra del
        terrorismo internacional y el narcotráfico? Además, en las naciones
        democráticas de economía libre, el ascenso de un patrimonio limitado,
        hacia una posición económica de mayor solvencia, no está vedado para
        nadie.{" "}
      </p>,
      <p>
        El discurso izquierdista que pretende posesionar en el pueblo el
        resentimiento al “imperialismo norteamericano”, no posee un asidero
        real. La supremacía que ciertos estados poseen sobre otros, constituye
        un destino inexorable a sobrellevar en el contexto planetario. Una
        suerte ineludible, comparable a la autoridad patriarcal en la infancia
        humana. Igualmente, las hegemonías político - militares constituyen un
        tipo de intromisión directa o indirecta de colonialismo o imperialismo
        (términos distintos pero relacionados), en los países de menor
        desarrollo. A partir de 1492, las naciones autóctonas de Sudamérica y el
        Caribe sufrieron la conquista y colonización por parte del imperio
        español que sometió al pueblo amerindio a través de su brutal política
        de sobreexplotación del trabajo. La concienciación democrática de los
        pueblos latinoamericanos logró la independencia a través de una larga
        campaña bélica que duró alrededor de un siglo. Si bien, la intromisión
        imperialista es de tipo indirecto, en relación al colonialismo; son
        prácticas que deben ser descalificadas y eliminadas por el derecho
        internacional y demás organizaciones democráticas universales.{" "}
      </p>,
      <p>
        De acuerdo a la ley de la dialéctica, todo está en continuo cambio,
        movimiento y transformación; por tanto, toda potencia económica,
        política y militar tiene una época de oro de desarrollo y hegemonía;
        luego, sus vicios y excesos conducirán a su decadencia y conclusión. Los
        imperios: hitita, egipcio, y romano, en la Antigüedad; asirio, persa y
        carolingio, en el Primer Milenio, d.C.; islámico y otomano, en la Edad
        Media; español, inglés, ruso y francés, en los siglos XV al XX; y en la
        actualidad, Estados Unidos y China, constituyen ejemplos de las
        potencias que han sometido y controlado a otros países. Sin embargo, la
        concienciación en torno a la sucesión “natural” de imperialismos
        hegemónicos a lo largo de los siglos que continuará hasta cuando
        indefectiblemente emerjan otros, nos plantea la intrascendencia de este
        odio creado. Tampoco pretendo, con alma de lacayo, insinuar resignación
        al saqueo de los recursos naturales por parte de las potencias
        extranjeras a las naciones en vías de desarrollo; más, con realismo
        apostemos por la coalición de países de línea democrática, no para la
        guerra; sino para rechazar en forma unánime y contundente las
        pretensiones e imposiciones imperialistas de cualquier tipo.{" "}
      </p>,
    ],
  },
  {
    section: 33,
    title: "Escamoteo de la realidad por medio del lenguaje",
    ref: "/EscamoteoDeLaRealidadPorMedioDelLenguaje",
    parrafos: [
      <p>
        Se ha dicho con razón que las palabras tienen el poder de crear o
        destruir; dado que éstas modelan la mente, para luego transformarse en
        pensamientos y acciones. Los embaucadores políticos a través del cambio
        léxico -semántico de ciertas palabras o frases, pretenden corromper el
        mensaje neutral de las palabras, tendiente a forjar un discurso
        “agradable”. Es decir, por medio de la denominada Neolengua sustituyen
        el sentido natural de las palabras, prescindiendo el significado
        indeseado. El objetivo es que ciertos conceptos dejen de existir en la
        mente de los hablantes, tendiente a dominar su pensamiento. Es común
        escuchar a los políticos cambiar la semántica (significado) de cierto
        vocabulario, a fin de ablandar el mensaje a la audiencia; con ello se
        pretende tergiversar algunas categorías del lenguaje para justificar
        ciertas políticas o medidas económicas que resultan lesivas al pueblo.
        Así, algunas frases o expresiones son reemplazados por eufemismos
        tendiente a disminuir el impacto emocional en la consciencia del oyente.
        Nótese el cambio de mensaje en los siguientes binomios lexicales:
        liquidar por asesinar; reducción de personal por despido; conflicto
        armado, u “operación militar especial” (utilizado por Putin en la
        invasión a Ucrania para defender a la población rusófila) en lugar de
        guerra; pasar a mejor vida por morir; donde el mensaje inicial presenta
        un menor impacto en la consciencia del interlocutor.{" "}
      </p>,
      <p>
        Todo idioma sufre cambios léxico - semánticos a través del tiempo de
        manera natural. En el lenguaje español, el adjetivo bárbaro cambió el
        significado de “extranjero”, a la acepción de “cruel o salvaje”. El
        sustantivo villano, referido a quien vivía en una villa; ahora se
        entiende como “perverso, malo o ruin”. Esta mutación parece normal, dado
        la evolución lingüística devenida de una multiplicidad de factores; pero
        es ilógico que políticos o intelectuales recalcitrantes a propósito
        pretendan escamotear la realidad representada en el subconsciente
        colectivo, a través de cambios semánticos forzosos, con el fin de
        posesionar su criterio ideológico - político. La manipulación del
        lenguaje como arma política, al estilo del Socialismo del Siglo XXI,
        debe descartarse de plano por su irracionalidad. Jamás se puede
        adulterar las palabras: miseria en lugar de justicia; tinieblas por luz;
        o guerra en lugar de paz.{" "}
      </p>,
      <p>
        Kaiser y Álvarez en su libro: “El Engaño Populista”, sostienen: “…
        cualquier cosa etiquetada como ‘neoliberal’, aunque produzca resultados
        extraordinarios, será rechazada, pues el rechazo al concepto es visceral
        y no racional”. Además, en alusión al libro, Politics and the English
        Language de George Orwel, señalan que:{" "}
      </p>,
      <p>
        <i>
          Los peores crímenes pueden ser defendidos simplemente cambiando las
          palabras con las cuales se les describe para hacerles digeribles e
          incluso atractivos. Así por ejemplo a la destrucción de pueblos
          indefensos y al asesinato de inocentes se les llama ´pacificación´ y
          el robo masivo de tierras de campesinos que son expulsados de ellas se
          llama ´transferencia de población´.
        </i>
      </p>,
      <p>
        <i>
          (…) Para Orwel, entonces, una de las armas esenciales del
          totalitarismo, particularmente del comunista, es la manipulación del
          lenguaje. La razón –dice el escritor británico- es que si se corrompe
          el lenguaje se corrompe el pensamiento y con ello se termina por
          destruir la democracia y la libertad, pues ambas reposan sobre
          verdades que ya no son reflejadas en el lenguaje (Kaiser y Álvarez) .{" "}
        </i>
      </p>,
      <p>
        Simón Espinosa, miembro de la Academia Ecuatoriana de la Lengua, recoge
        a manera de sátira burlesca el cambio semántico de ciertas frases
        empleados en los discursos por el ex presidente populista ecuatoriano,
        Rafael Correa (2007 – 2017), donde se ejemplifica el escamoteo de la
        realidad:{" "}
      </p>,
      <p>
        <i>
          (…) Contrato a dedo de derecha: mafia; contrato a dedo de izquierda:
          emergencia. Dictadura de derecha: tiranía; dictadura de izquierda:
          revolución ciudadana. Reparto de bonos de derecha: paternalismo
          neoliberal; reparto de bonos de izquierda: justicia social. Asesores
          gringos que cobran en dólares: ratas colonialistas; asesores españoles
          que cobran en euros: profesionales solidarios. Uso de fondos públicos
          en campaña electoral de derecha: peculado; uso de fondos públicos en
          campaña electoral de izquierda: comunicación social. Gesto obsceno a
          presidente de derecha: libertad de expresión; gesto obsceno a
          presidente de izquierda: atentado a la majestad del poder. Control de
          todos los poderes de derecha: partidocracia; control de todos los
          poderes de izquierda: democracia ciudadana (…). Bajo crecimiento de
          derecha: fracaso neoliberal; bajo crecimiento de izquierda: no hay
          cifras (…). Indígenas de derecha: longos arribistas; indígenas de
          izquierda: pueblos ancestrales. Represión policial de derecha:
          genocidio; represión policial de izquierda: legítima defensa. Demandas
          de derecha: caprichos; demandas de izquierda: derechos ciudadanos.
          (…). Explotación de recursos naturales de derecha: depredación
          indiscriminada; explotación de recursos naturales de izquierda:
          desarrollo sostenible. Concesiones de derecha: atraco al Estado;
          concesiones de izquierda: colaboración público-privada. Obras públicas
          de derecha: inhumanos trozos de concreto; obras públicas de izquierda:
          infraestructura solidaria. Periodistas de derecha: bestias salvajes;
          periodistas de izquierda: intelectuales. (Espinosa) .
        </i>
      </p>,
      <p>
        Por otra parte, los discursos egotistas cargados de categorías de
        condena, carencia o celos proveniente de caudillos políticos, que en su
        afán de conseguir adeptos para ascender o mantener el poder, traen
        aparejados resultados nefastos para la sociedad. El poder de la palabra
        en las arengas negativistas que posesionan un resentimiento social y
        desconfianza colectiva, propios de los sectores políticos o gremios
        adictos a ideologías sectarias, comúnmente deviene en caos y mayor
        pobreza. La “siembra” de estos sentimientos en la psicología de la masa,
        sólo profundiza la frustración y carencia de todo orden; y con ello, un
        clima de ingobernabilidad. ¡No es favorable precipitar el desastre a
        través de la evocación de aspectos negativos! Inversamente, el
        pensamiento positivo de un líder verdadero, basado en la fe, la
        abundancia y el bienestar coadyuvará a direccionar al subconsciente
        colectivo, para mejorar el destino del País; más esto requiere
        despojarse de los dogmas y etiquetas políticas que traen resultados
        adversos. Es decir, el estado mental de decadencia, miedo o pobreza
        evocados por una persona o un grupo social genera aquella condición. El
        Dr. Murphy al referiste a la necesidad de cuidar los pensamientos que
        ingresan a nuestro subconsciente, compara este proceso con el plano de
        un edificio que elabora un arquitecto:
      </p>,
      <p>
        <i>
          Si su plano mental está lleno de pautas mentales de miedo,
          preocupación, ansiedad o penuria, y si se siente desalentado,
          vacilante y cínico, entonces la textura del material mental que está
          usted tejiendo dentro de su espíritu se traducirá en más afán, más
          cuidado, más tensión, más ansiedad y más limitaciones de todo tipo.
        </i>
      </p>,
      <p>
        <i>
          La actividad fundamental y de mayor alcance en la vida es la que usted
          va instalando en su propia mente cada hora, cada día. Su palabra es
          silenciosa e invisible; no obstante, es real.
        </i>
      </p>,
      <p>
        <i>
          Usted está edificando todo el tiempo su casa mental, y su pensamiento
          e imaginación representan su plano. Hora tras hora, momento tras
          momento, puede construir radiante salud, éxito y felicidad mediante
          los pensamientos que concibe, las ideas que alberga, las creencias que
          acepta y las escenas que ensaya en el recóndito estudio de su mente.
          Esta imponente mansión, en cuya perpetua edificación se halla usted
          comprometido, es su personalidad, su identidad a este nivel, la entera
          historia de su vida en esta tierra.
        </i>
      </p>,
      <p>
        <i>
          Consígase un nuevo plano; edifíquelo silenciosamente realizando la
          paz, la armonía, la alegría y la buena voluntad en el momento
          presente. Si medita y afirma estas cosas, el subconsciente aceptará su
          plano y hará que todo ello se lleve a cabo. Por sus frutos los
          reconoceréis. (Murphy) .
        </i>
      </p>,
      <p>
        Estamos retados a modernizar la cuarteta del escritor español, Ramón de
        Campoamor (1817 -1901), que dice: “en este mundo traidor, nada es verdad
        ni mentira, todo es según el color del cristal con que se mira”, por
        otra que diga: “el mundo cuántico requiere, ideas grandes cada día,
        cambiar lo negativo en positivo, hablar con fe y armonía” … ¡El lenguaje
        debe comprendérselo como una herramienta coadyuvante de comunicación y
        perfección humana! y, las palabras deben usarse para representar de
        manera transparente, únicamente su significado. En cualquier campo
        profesional, no es ético engañar a nuestro comprador, a riesgo de ser
        descubierto y excluido. En lo ideológico – político, el lenguaje debe
        regirse por la neutralidad de la semántica: que evite la transgresión
        del mensaje y permita una comunicación diáfana y racional.{" "}
      </p>,
    ],
  },
  {
    section: 34,
    title: "LIBERTAD Y DEMOCRACIA, BASES PARA LA COEXISTENCIA HUMANA",
    ref: "/LibertadYDemocraciaBasesParaLaCoexistenciaHumana",
    parrafos: [
      <p>
        Aun cuando ciertas personas estudiadas en el tema subrayan que la
        libertad y la democracia son valores opuestos: la primera por ser
        intrínseca al ser humano; y la segunda, por considerársela sólo una de
        las formas de administrar al estado; las dos poseen una relación de
        interdependencia. La democracia es el sistema de gobierno fundamentado
        en la soberanía del pueblo; aquello se sintetiza en el principio de
        vincular la mayoría de voluntades humanas a través de la igualdad de
        derechos ante la ley. La existencia de esta forma de gobierno tiene
        relación directa con el criterio de libertad ciudadana; dado que sólo
        los hombres libres procuran el gobierno del pueblo y para el pueblo.{" "}
      </p>,
      <p>
        La libertad como atributo inherente al ser humano le faculta
        desenvolverse libremente en todas sus acciones, sin afectar los derechos
        individuales y colectivos de los demás: relativo básicamente a la
        libertad de pensamiento, de expresión, de asociación, de tránsito, de
        reunión, de elección, de educación y de trabajo. La democracia y la
        libertad deben estar regulados con sabiduría, a fin de evitar su mutua
        vulneración. No es posible afectar la primera con pretexto de dar
        preminencia a todo tipo de libertades; peor aún, menguar la libertad
        individual y colectiva por pretender “mejorar” la democracia, como se
        dio en las experiencias comunistas y fascistas. La libertad es un
        atributo inalienable de todo hombre y mujer por su condición de miembro
        de la especie humana; en tanto que la democracia hace relación al
        entramado jurídico del estado para una coexistencia armónica y de
        calidad.{" "}
      </p>,
      <p>
        Las cuatro libertades humanas esenciales proclamadas por Franklin
        Roosevelt: libertad de expresión, libertad religiosa, libertad de vivir
        sin penuria y libertad de vivir sin miedo, constituyen la primera
        aproximación hacia la democracia. Esta modalidad de gobierno implica un
        sistema tridimensional, integrado por elementos sociales, económicos y
        políticos. Social, porque abarca a todo el conglomerado nacional en su
        conjunto, no únicamente al sector gubernamental; lo político, en cuanto
        implica a las leyes, normas e instituciones para el desenvolvimiento de
        esta forma de vida civilizada; y, lo económico, ya que propicia una
        rutina social de emprendimientos y ocupaciones para el sustento de los
        grupos sociales.
      </p>,
      <p>
        Los fundamentos teóricos de la democracia representativa constituyen: la
        elección a través de sufragio universal para las principales autoridades
        de gobierno administradoras del estado; la independencia de poderes; y,
        la supervisión pública de los actos del gobierno [*]; en cooperación con
        la opinión pública. Los estudiosos de la convivencia democrática han
        dado valiosos y variados aportes sobre el tema, que a veces resultan
        controversiales; pero la síntesis de este debate subraya la necesidad de
        garantizar la libertad de expresión, de reunión, de asociación y de
        libre elección. Estos derechos constituyen los pilares fundamentales de
        la sociedad libre: tesoros invalorables e inmanentes para la
        coexistencia de hombres y mujeres civilizados.
      </p>,
      <p>
        Si bien no existe en el mundo la democracia perfecta; ésta constituye la
        más elevada invención humana en el campo político. El empoderamiento
        social en su conjunto le confiere el principio de soberanía del pueblo.
        Su certeza se mide por el nivel de intervención popular: a mayor
        participación ciudadana, mayor democracia; a menor intervención pública,
        se aleja hacia la autocracia. Los atenienses en las postrimerías del
        siglo VI a.C., quienes usaron primero la frase: “el poder del pueblo”,
        eran partidarios de la democracia directa o asambleas populares para
        debatir los asuntos de su colectividad.{" "}
      </p>,
      <p>
        Indudablemente, las actuales metrópolis masificadas dificultan esta
        forma de gobernanza. Por tanto, luego de la Revolución Francesa, este
        tipo de gobierno se ejecuta por medio del método de la democracia
        indirecta o democracia representativa. Es decir, la autoridad popular se
        ha institucionalizado a través de elecciones periódicas libres, por la
        dificultad de reunión de las multitudes abultadas. Sus delegados
        elegidos periódicamente actúan en su nombre en base a mecanismos
        democráticos de la constitución del estado.{" "}
      </p>,
      <p>
        Hoy que asistimos a un extraordinario nivel de desarrollo científico
        tecnológico en diversas áreas del conocimiento humano; en el campo
        político-social, aún persiste el hostigamiento por parte de ciertos
        dictadores de derecha a los izquierdistas que se oponen al sistema
        capitalista. No obstante, la persecución a los críticos del sistema
        socialista o comunista en algunos países gobernados por los extremistas
        de izquierda, es más nefasto aún. El desarrollo de las ciencias
        alcanzado en el planeta, poco ha contribuido a la coexistencia pacífica
        en libertad y democracia. No se ha logrado descartar el antiguo criterio
        establecido por Plautus (254 - 184 a. C.): “El hombre es el lobo del
        hombre”, vigente por muchos siglos. Es tiempo de apelar a nuestra
        experiencia acumulada hasta esta etapa de evolución histórica y,
        corresponde trascender las extremas ideológicas de izquierda y derecha
        para una coexistencia humana más armoniosa. Indudablemente, la pugna
        enconada entre estas dos ideologías ha permitido un mayor nivel de
        consciencia social; pero su saldo de barbarie debe ser superada de
        manera inmediata.{" "}
      </p>,
    ],
  },
  {
    section: 35,
    title: "Justicia, Libertad y Democracia, Bienes Innegociables",
    ref: "/JusticiaLibertadYDemocraciaBienesInnegociables",
    parrafos: [
      <p>
        La justicia hace relación al respeto, equidad e igualdad ante la ley en
        la que se fundamenta el estado de derecho. Constituyen aquellos actos
        lícitos que se ejecutan de acuerdo a la legislación reglamentada: la
        rectitud de procedimientos en relación con nuestros congéneres; o
        aquella conducta civil ajustada al orden jurídico determinado. José
        Ingenieros, en su obra Las Fuerzas Morales, aclara: “La justicia es el
        equilibrio entre la moral y el derecho”; ella posee un valor superior al
        de las leyes, dado que éstas a veces pueden ser injustas. Añade, además:
      </p>,
      <p>
        <i>
          Acatar la ley es un acto de disciplina, pero a veces implica una
          inmoralidad; respetar la justicia es un deber del hombre digno, aunque
          para ello tenga que elevarse sobre las imperfecciones de la ley. (…)
          El mayor obstáculo al progreso de los pueblos es la fosilización de
          las leyes: si la realidad social varía, es necesario que ellas
          experimenten variaciones correlativas. La justicia no es inmanente ni
          absoluta; está en devenir incesante, en función de moralidad social.
          (Ingenieros 41- 42) .
        </i>
      </p>,
      <p>
        La moral, intrínseca a la coexistencia humana, está relacionada al
        criterio del bien y el mal; se pretende practicarla por medio de las
        normas codificadas para el buen obrar humano. La justicia formal es
        impartida por el estado por medio de magistrados, o jueces que deben
        aplicar las normas reguladas para la convivencia civilizada. Todas las
        personas estamos convocados/as a propiciar a través de nuestras palabras
        y acciones, actos lícitos de acuerdo a la ley, lo cual implica actuar en
        justicia; con igual razón: los políticos, los administradores de
        justicia, y los juristas, dentro de sus funciones, están obligados no
        sólo parecer honestos; sino proceder con rectitud, apegados a la moral y
        el derecho. En el presente milenio, el ciudadano universal consciente de
        nuestra evolución histórica, su tarea es coadyuvar a preservar el
        planeta y procurar la vigencia de la democracia, justicia y libertad.{" "}
      </p>,
      <p>
        Por otra parte, la libertad es el valor supremo que nos faculta el libre
        albedrío e igualdad de derechos en relación con nuestros congéneres.
        Esta soberanía, propia de los seres humanos para actuar libremente sin
        impedimento; sin interferir las facultades de los demás, nos permite
        elegir nuestras alternativas personales y adaptarnos mejor a nuestro
        medio inmediato. Como potestad intrínseca a los humanos poseedores de
        consciencia superior, nadie puede renunciar ni prohibir por más fortuna
        que se ofrezca a cambio. Tampoco es posible sobreponer la libertad de un
        grupo social al cautiverio de otro, con el pretexto de pertenecer al
        auténtico pueblo; dado que todo ser sapiente aspira poseer: justicia,
        libertad, democracia y oportunidades para desarrollarse en armonía.{" "}
      </p>,
      <p>
        La justicia, la libertad y la democracia, bienes inmanentes a los seres
        humanos, fueron logrados en el planeta luego de incesante disputa y
        debate desde tiempos del esclavismo. No es exactamente “la lucha de
        clases” que refiere el marxismo; más bien, una riña entre la ignorancia
        y el oscurantismo en el proceso de hominización. Por tanto, el
        surgimiento de estos valores ciudadanos superiores posee una premisa
        lógica de relación; a mayor concienciación humana - instrucción y hábito
        de la lectura -, mayor democracia y libertad. En este sentido, la
        reflexión del Papa Juan Pablo II (1978 – 2005) [*]: “la evolución es
        compatible con la fe cristiana”, puede relacionarse al criterio de que,
        la evolución de la consciencia humana ha devenido en mayor democracia y
        libertad. Es decir, el multifacético perfeccionamiento de hombres y
        mujeres en su lucha constante por mejorar su estándar de vida, ha
        llevado a descubrir extraordinarios instrumentos de subsistencia y
        coexistencia.
      </p>,
      <p>
        <i>
          [*] Juan Pablo II (Karol Józef Wojtyła), Papa polaco (1920 -2005)
          número 264. En su adolescencia destacado ajedrecista. En su juventud,
          ingresó a la Universidad Jagellónica de Cracovia (clausurada por los
          nazis en 1939); trabajó en una cantera y luego en la planta química
          Solvay. En 1943 fue ordenado sacerdote, posteriormente estudio
          filosofía en Roma, donde obtuvo su doctorado en teología. En 1948, ya
          en Polonia, se desempeñó como profesor de la Facultad de Teología en
          la universidad donde estudió. Fue vicario coadjutor; profesor de
          Teología moral y Ética en el seminario de Cracovia; docente y director
          de la Universidad Católica de Lublin. En 1958 fue electo obispo
          auxiliar en Croacia, por el Papa Pio XII. En 1962, asistió al Concilio
          Vaticano II, donde expuso su pensamiento sobre el ateísmo moderno y la
          libertad religiosa. Fue designado arzobispo de Cracovia, y cardenal en
          1967. En 16- 10- 1978, tras la muerte de Juan Pablo I, fue consagrado
          Sumo Pontífice de la iglesia con el nombre de Juan Pablo II.
          Considerado uno de los líderes más influyentes del S. XX, por su lucha
          contra la expansión marxista y critica al capitalismo “salvaje”. Su
          plan incluyó: Nueva evangelización; Ecumenismo (diálogo con otras
          iglesias cristianas); Compromiso ético y social; Lucha por la paz; y,
          Rigor doctrinal. El 13 -05 - 1981, sufrió un atentado. Dos años
          después visitó a su agresor en la cárcel y le concedió perdón. Un
          segundo atentado sufrió en su visita a Fátima, Portugal.{" "}
        </i>
      </p>,
      <p>
        <i>
          Su acción pastoral lo hizo a través de largas peregrinaciones,
          incluyendo países de otros credos religiosos. Su hechos relevantes: a)
          Visita a una iglesia luterana (Roma, 1983); y una sinagoga ( Roma,
          1986); b) Excomunión del obispo Marcel Lefebvre (1988), c) Visitó
          Grecia (país ortodoxo); d) Ingresó a la mezquita de Damasco en Siria;
          e) Primer encuentro de un papa con una comunidad musulmana
          (Casablanca, 1985); f) Encuentro con el último presidente de la U.
          R.S. S., Mijaíl Gorbachov (1989); y, g) Visita Tierra Santa (2000)
          donde pide perdón en el Muro de los lamentos y en el Museo del
          Holocausto por los pecados de los cristianos al perseguir a los
          judíos.{" "}
        </i>
      </p>,
      <p>
        <i>
          El 1-05- 2011 fue beatificado en la ciudad del Vaticano. Los dos
          hechos sobresalientes de su pontificado se sintetizan en:
        </i>
      </p>,
      <p>
        <i>
          - Aceptar la evolución dentro de la creación: ("Si el cuerpo humano
          tiene su origen en la materia viva que le precede", "el alma
          espiritual está directamente creada por Dios"). AFP, Ciudad del
          Vaticano - 23 oct 1996 - 17:00 ECT. https://el
          país.com/diario/1996/10/24/sociedad/8461080007 _850215.html.
        </i>
      </p>,
      <p>
        <i>
          - El 12-03-2000, en su homilía solemne pidió perdón por los pecados de
          la iglesia. El pontífice recordó las persecuciones sufridas por los
          cristianos, subrayando que la Iglesia pide y otorga perdón al mismo
          tiempo.
          https://www.clarin.com/ediciones-anteriores/papa-pidio-perdon-veces-pecados-iglesia_0_HyYZDdi10Yx.html.
        </i>
      </p>,
      <p>
        En síntesis: la justicia, la libertad y la democracia son bienes
        innegociables por ser efecto de la constante lucha y evolución de la
        consciencia humana. Estos valores inherentes al hombre y la mujer deben
        ser custodiados celosamente. Aquellos que pretendan conculcarlos,
        deberían ser penados/as. Además, toda sociedad que posee cierto estatus
        de logro, en relación a estos bienes supremos, jamás puede tener una
        regresión de derechos; si se hallaren degradados, es deber ciudadano
        luchar con ímpetu para recuperarlos e impulsarlos. Es tiempo de
        identificar a aquellos que, a cuenta de “alcanzar la verdadera
        libertad”, proponen experimentos políticos que coartan estas riquezas
        soberanas. La concienciación ciudadana de que: justicia, libertad y
        democracia van de la mano con el desarrollo holístico de la sociedad,
        nos compromete a todos a promoverlas y mejorarlas.{" "}
      </p>,
    ],
  },
  {
    section: 36,
    title: "La Elección de la Democracia Superior",
    ref: "/LaEleccionDeLaDemocraciaSuperior",
    parrafos: [
      <p>
        Si la entidad humana respondiera únicamente a su estructura somática -
        conjunto de células que forman los órganos y sistemas -, la
        adaptabilidad a cualquier sistema social impuesto, sería simple; pero no
        es posible homologarla, porque nuestra especie dotada de cerebro
        inteligente, formada por el consciente y subconsciente, procesa una
        combinación de ideas, sentimientos y emociones. Igualmente, para elegir
        un tipo de democracia elevada, se hace necesario comprender que, el
        estado humano igualitario en derechos y oportunidades es factible; pero
        es utópico la igualdad total. Desde esta óptica, la democracia actual de
        tipo occidental, con todas sus falencias, si está orientada a propiciar
        la unidad, la tolerancia y la convivencia pacífica en la diversidad
        social. Aunque cuando, se requiere mayor concienciación para ejercerla,
        ya que la democracia es un proceso humano infinito de incesante búsqueda
        y perfeccionamiento.{" "}
      </p>,
      <p>
        Los importantes aportes de politólogos e investigadores en torno a una
        democracia ideal, en un mundo de constante cambio y transformación,
        constituyen respetables aproximaciones teóricas provisionales,
        incluyendo el presente trabajo; en consecuencia, son relativos. Algunas
        doctrinas llamadas “irrefutables”, por parte de seguidores fanatizados,
        sólo constituyen acercamientos a la verdad. En este contexto, la
        incoherencia teórica-práctica del planteamiento marxista de fundar una
        sociedad exenta de clases, no ha logrado plasmar su cometido. Los
        discursos de sus ideólogos: Lenin, Mao, Ho Chi Minh, Castro y muchos
        otros, experimentaron cambios a través del tiempo, respecto de sus
        propuestas iniciales; incluso, algunos países luego de haber sido
        devastado por la guerra, la pobreza, las persecuciones o el terror
        generalizado, bajo el estandarte de la equidad social, debieron regresar
        al cause democrático.{" "}
      </p>,
      <p>
        La divergencia entre patronos y trabajadores ha derivado en constantes
        conflictos sociales, devenida de una mezcla de codicia e ignorancia,
        propia de una consciencia aún limitada. Esta controversia entre los
        componentes del binomio dialéctico en el campo económico: ricos versus
        pobres, presente en la sociedad humana a partir del modo producción
        esclavista, resulta complejo armonizarlos, dado su concatenación con
        otros pares dialécticos: producción-consumo; trabajo-ocio;
        planificación-improvisación; democracia-dictadura; talento-incapacidad,
        etc. Así, el mercado laboral base de todo sistema económico, compuesto
        por la mano de obra y la distribución de la renta, ha seguido un camino
        tortuoso difícil de ser regulado a partir de la aparición del excedente.
        Para encarar este dilema social el marxismo propone la dictadura del
        proletariado; la eliminación de la clase explotadora, y así edificar la
        nueva sociedad sin “explotadores ni explotados”. Sobre el tema, los
        estudiosos de esta línea sostenían:
      </p>,
      <p>
        <i>
          Primero, el Estado socialista es, y es lo esencial, el instrumento del
          poder político de la clase que no explota a nadie, pero tiene por
          misión librar de la explotación a todo el pueblo trabajador. Segundo,
          la clase obrera no se afana por eternizar su posición dominante:
          necesita el Estado sólo por un determinado período histórico como
          instrumento para edificar una sociedad nueva. Al cumplir su misión
          dicha sociedad debe desaparecer para ceder el terreno a la
          autoadministración social comunista (…).
        </i>
      </p>,
      <p>
        Es decir, a los marxistas no parecen interesarles la democracia formal o
        superior, aquella que impulsa la libertad y se perfecciona dentro del
        estado de derecho. Ellos, contraponen su propia democracia; ya que, a su
        criterio ésta dimana de la “moral burguesa corrupta”. Por ello, los
        defensores señalados enfatizan que, la libertad sólo es factible en un
        sistema de igualdad total; dado que la libertad entre desiguales deviene
        en injusticia; y, subrayan:
      </p>,
      <p>
        <i>
          La igualdad no puede consolidarse hasta que los hombres no se traten
          entre sí como amigos y hermanos. No puede ser plenamente libre aquella
          persona dominada por los prejuicios religiosos. No puede ser feliz el
          pobre de espíritu, el que se sienta ajeno a la alegría del
          conocimiento, sordo y ciego ante la belleza del arte (G. Shajnazárov,
          A. Boborykin y Y. Krasin, La Educación del Hombre Nuevo) .
        </i>
      </p>,
      <p>
        Por otra parte, los ortodoxos defensores del capitalismo, plantean la
        democracia de tipo liberal como la única alternativa válida; enfatizan
        que la democracia, el liberalismo y la economía de mercado constituyen
        los elementos sociales de un todo articulado… Más, desde una óptica
        neutral, cualquier estado con un sistema democrático libre, por
        incipiente que sea, puede perfeccionarlo; pero no es factible desde un
        régimen cerrado, carente de libertad e impermeable a la criticidad. El
        proceso de liberación de los dogmas y prejuicios religiosos no es
        posible eliminarlos por decreto.{" "}
      </p>,
      <p>
        Es evidente que, mayor avance en el campo social se observa desde las
        democracias formales de corte liberal; sus avances impensados a partir
        de su aparición en Grecia, constituyen ejemplos de su evolución
        histórica. El Estado patrono en las “democracias” socialistas, no ha
        logrado cubrir las expectativas económicas de la mayoría de ciudadanos,
        que se ha visto obligado a subsidiar a los grupos vulnerables. Además,
        desconocer el libre albedrío del individuo en la elección y consecución
        de sus metas ocupacionales, implica coartar su dignidad; pues los
        centros de formación profesional en este sistema, se orientarán de
        acuerdo a las necesidades estatales. En este contexto, el marxismo poco
        ha contribuido a mejorar la calidad de vida de la gente; y a veces ha
        resultado en confusión y retroceso social. Entender una sociedad
        “igualitaria”, exenta de clases sociales resulta una entelequia: un
        salto al vacío. Significa desconocer la potencialidad humana como unidad
        bio - psico – social; donde todo se encuentra interconectado dentro de
        un sistema cuántico: que implica el campo energético, biológico, mental,
        emocional y espiritual.{" "}
      </p>,
      <p>
        José Díez, un reconocido economista español y autor de varias obras
        socio–políticas, rescata el valor de los emprendedores privados, pero
        critica al capitalismo inhumano, en los siguientes términos:
      </p>,
      <p>
        <i>
          En su Manifiesto comunista, Marx y Engels apostaron por dar fin al
          conflicto acabando con el empresario. Sin embargo, los países que han
          intentado ponerlo en práctica han destrozado su economía, pues el
          empresario es una pieza clave en la riqueza de las naciones. De este
          modo, el comunismo resuelve el problema de la distribución de la renta
          haciendo pobre a toda la sociedad. En el otro extremo se encontraría
          el sistema ultra liberal y el capitalismo salvaje que permite la
          acumulación de riqueza en muy pocas manos, agravando el conflicto con
          la clase trabajadora (…) (Díez, La Economía no da felicidad pero ayuda
          a conseguirla) .{" "}
        </i>
      </p>,
      <p>
        Indudablemente, las democracias en los países que han adoptado el modelo
        político – social capitalista, no son perfectas; pero desde aquí existen
        mejores perspectivas de perfeccionamiento a través de la auto - crítica
        y la lucha social. Los comunistas rezagados, indiferentes a la libertad
        individual, poseen otras percepciones en cuanto a los derechos
        ciudadanos. Para ellos, las democracias burguesas poseen instituciones
        de carácter limitado al servicio de los propietarios de los medios de
        producción; sólo aquellas basadas en la “soberanía del pueblo y la
        igualdad de derechos”, son las ideales. Teóricamente parece coherente,
        más en la práctica resulta lo contrario; si se contrapone la “dictadura
        del proletariado” a la gobernanza participativa; es decir, el dominio de
        un grupo social sobre otro, resulta iluso tal igualdad.{" "}
      </p>,
      <p>
        Por otra parte, debe recordarse que todas las democracias son frágiles y
        perfectibles; es deber de todos contribuir a su cuidado y
        perfeccionamiento. Algunos investigadores del tema sostienen que la
        democracia representativa se encuentra alejado de la problemática
        ciudadana; por lo que es necesario mayor conectividad entre gobernados y
        gobernantes. Es decir, aún no poseemos la gobernanza participativa
        ideal; se requiere una mayor profundización de la democracia. Un estudio
        sobre el tema, señala:{" "}
      </p>,
      <p>
        <i>
          (…) es que la democracia más que ser una situación estática es un
          proceso tendiente a su mejoramiento por lo que si existen etapas para
          alcanzar la democracia ideal, su expresión más elemental sería la
          poliarquía (democracia real) (Corona, De la O Tamez y Miranda) .
        </i>
      </p>,
      <p>
        Todos los estados del mundo: sean de economía liberal y los comunistas,
        poseen estructura de poder y ordenamiento jerárquico definidos. Aquellos
        ubicados en la cúspide, forzosamente poseen mayores responsabilidades y,
        por tanto, mayores privilegios en relación al pueblo llano. La
        diferencia entre los dos sistemas radica: en la primera existe la
        posibilidad de emprender, ejercer y discrepar con mayor libertad; en la
        segunda, estos derechos son conculcados. Entonces, desde el criterio de
        que la gente siempre prefiere un crecimiento en libertad, los
        socialistas y comunistas deben reiniciar una innovación de sus
        paradigmas, donde el primer requerimiento al llegar al poder, debiere
        ser: garantizar la democracia y las libertades a todo nivel [*].
      </p>,
      <p>
        <i>[*] Subrayado del autor </i>
      </p>,
    ],
  },
  {
    section: 37,
    title: "El merecimiento de la Libertad y la Democracia",
    ref: "/ElMerecimientoDeLaLibertadYLaDemocracia",
    parrafos: [
      <p>
        La libertad y la democracia son privilegios del hombre civilizado, su
        consecución demandó siglos de concienciación para merecerlas; es decir,
        la sociedad para ejercer sus derechos ciudadanos, debió primero poseer
        un aceptable nivel de conciencia social. Por ello, desde tiempos
        inmemoriales, la historia da cuenta que hombres y mujeres conscientes de
        sus derechos ciudadanos, permanentemente se batieron en duelo contra la
        discriminación y tiranía. Si el pueblo ignora el valor de estos
        privilegios colectivos, su oscurantismo le vuelve vulnerable a sistemas
        oprobiosos. Esta gnosis humana influye de manera directa en su calidad
        de vida; además, frena a raya la demagogia de los políticos charlatanes.{" "}
      </p>,
      <p>
        La búsqueda de sistema democrático pleno de libertad requiere el
        reconocimiento ciudadano, su capacidad de merecerla, reinventarla y
        mantenerla. Si la población mayoritaria no se concibiera semejante a sus
        congéneres en cuanto a sus derechos, el criterio de igualdad ante la ley
        sería difícil de establecer en la sociedad. Pero, el hombre como entidad
        bio-psico-social y pensamiento autónomo, es digno de una democracia
        elevada, porque nuestra consciencia superior compuesta de atributos,
        virtudes y falencias, nos permite monitorear nuestras acciones y optar
        por la mejor alternativa. Es decir, esta civilización evolucionada dista
        substancialmente de la denominada, “horda primitiva”, por su autonomía e
        iniciativa. Nuestro instinto no es eminentemente gregario (actuación en
        conjunto sin dirección planificada), propia de los hermanos de la escala
        inferior; sino que cada individuo en forma consciente e independiente
        puede participar de las actividades humanas como: política, religión,
        negocios, etc.; e incluso, distinguirse con originalidad e
        independencia. En la sociedad democrática, el ciudadano tiene la
        facultad de pertenecer, escoger o liderar, la organización social o
        política a libre albedrío.
      </p>,
      <p>
        Al sentirnos dignos de una democracia plena, nuestra consciencia nos
        permite comprender que la igualdad ante la ley, no implica una
        homologación ciudadana total. Significa concebirnos soberanos, únicos y
        capaces de adoptar la mejorar alternativa en cualquier ámbito del
        quehacer humano. Aquello, se ha pretendido escamotear por parte de la
        ortodoxia marxista y otros regímenes populistas autoritarios, creyentes
        del partido único antidemocrático, que tratan de uniformar y castrar el
        pensamiento socio-político-religioso del pueblo. Así algunos politólogos
        sostienen que las democracias liberales acentúan en la libertad, y
        descuidan la igualdad; mientras que el socialismo, procura fortalecer la
        igualdad en desmedro de la libertad. ¡No es aceptable someter la
        libertad por pretender impulsar la democracia! El homo sapiens
        evolucionado es una realidad cuántica, producto de una totalidad
        energética íntimamente relacionada, con un sello de unicidad y
        disimilitud, compuesto de cuerpo, sique y alma, que nos transforma en
        seres autónomos: idénticos en ciertos aspectos, pero indudablemente
        disímiles a la vez. Los hombres y mujeres inteligentes, siempre serán
        adversos/as a una sociedad dogmática carente de libertades ¡No somos
        números estadísticos! somos almas trascendentes capaces de pensar y de
        crear.{" "}
      </p>,
      <p>
        Por ello, la consecución de una democracia depurada, además está ligada
        a la constante formación ideológica – política del hombre civilizado,
        con criterio neutral. Cuando la “concienciación” posee un enfoque
        populista - politiquero, lleva al adoctrinamiento ideológico sesgado,
        antidemocrático, que puede derivar en dictaduras carentes de libertad.
        En este contexto, el currículo de todo sistema educativo democrático
        debe impulsar, aparte de la instrucción científica: la libertad, la
        democracia, la consciencia social, la conservación del planeta, el
        pensamiento positivo, la tolerancia y el bienestar. Es claro que los
        países con un mayor nivel educativo poseen democracias más consolidadas;
        y a su vez, una renta per cápita más elevada. Unamuno tuvo mucho de
        verdad cuando decía: “solo el que sabe es libre y más libre el que más
        sabe”.
      </p>,
      <p>
        Todo ciudadano del mundo ha dado muestras de su vocación democrática en
        contra de la discriminación y la implantación de regímenes totalitarios.
        Ello implica la concienciación para la protesta y resistencia social en
        caso de haberse conculcado la democracia. La restauración del estado de
        derecho es un deber ciudadano que debe ejercerse con todo ímpetu,
        evitando el odio y la violencia. ¡Nadie se avergüence por exigir la
        propiedad del pueblo: libertad y democracia! El ejemplo de luchadores
        sociales por la emancipación humana, como Gandhi, Mandela y millones
        más, nos desafía a manifestarnos multitudinariamente con disciplina y
        sin temor… El discurso de Martin Luther King, “I have a Dream”, ¡es un
        idóneo modelo de proclama en demanda de libertad! Esta pieza de oratoria
        política es considerada universalmente como las mejores en su género
        para ejercer este derecho. He aquí un fragmento de ella:
      </p>,
      <p>
        <i>
          No habrá paz ni tranquilidad en los Estados Unidos mientras no se les
          reconozcan a los negros sus derechos civiles. El torbellino de la
          rebelión seguirá sacudiendo los cimientos de nuestro país hasta que
          llegue el radiante día de la justicia. (…) Evitemos cometer actos
          injustos en nuestra lucha por conquistar el lugar que nos corresponde.
          Guardémonos de colmar nuestra sed de libertad bebiendo el cáliz del
          rencor y del odio (vítores y aplausos).
        </i>
      </p>,
      <p>
        <i>
          Libremos esta lucha sin apartarnos nunca del elevado camino de la
          dignidad y la disciplina. Evitemos que nuestra protesta degenere en
          violencia.
        </i>
      </p>,
      <p>
        <i>
          Debemos tener siempre la grandeza de responder a la fuerza física con
          la fuerza espiritual. Y el extraordinario activismo al que nos hemos
          entregado los negros no puede llevarnos a desconfiar de todos nuestros
          hermanos blancos: muchos de ellos están presentes aquí, porque han
          tomado consciencia de que su destino es inseparable del nuestro
          (vítores y aplausos) y su libertad está indisolublemente ligada a la
          nuestra. No podemos caminar solos.
        </i>
      </p>,
      <p>
        <i>
          (…) Sueño con que un día, en las rojas colinas de Georgia, los hijos
          de los antiguos esclavos, y los hijos de los antiguos dueños de
          esclavos puedan sentarse juntos en la mesa de la hermandad. Sueño con
          que un día el estado de Mississippi, un estado que se sofoca con el
          calor de la injusticia y de la opresión, se convierta en un oasis de
          libertad y justicia. Sueño con que un día mis cuatro hijos vivan en un
          país donde no se les juzgue por el color de su piel, sino por los
          atributos de su personalidad” (Burnet) .
        </i>
      </p>,
    ],
  },
  {
    section: 38,
    title: "Concienciación para Fortalecer la Democracia",
    ref: "/ConcienciacionParaFortalecerLaDemocracia",
    parrafos: [
      <p>
        La democracia, entendida como un bien social abstracto, inherente al
        ciudadano universal, demanda de todos: conocerla, custodiarla y
        fortalecerla de manera permanente; de su vigencia y perfeccionamiento
        depende la esperanza y las condiciones de vida de la población. La
        concienciación para fortalecer y cuidar la democracia nos permite
        catalogarnos como seres altamente evolucionados. El politólogo
        ecuatoriano, Dr. Rodrigo Borja, señala al respecto: “Se debe enseñar a
        los niños, desde la más temprana edad, a que sean demócratas, del mismo
        modo que se les enseña que sean limpios y que tengan buenas costumbres”.
        Además, este investigador establece que ella es una realidad abstracta
        en proceso de perfección, cuando dice:
      </p>,
      <p>
        <i>
          La democracia, como forma de Estado, es un modelo puramente conceptual
          que jamás se dio ni puede darse en su realidad más pura. Solamente hay
          aproximaciones mayores o menores al modelo abstracto. La democracia es
          un ideal, una meta, a las que hay que acercarse en lucha permanente no
          obstante saber que nunca se las alcanzará plenamente y que el orden
          quimérico de total identificación entre gobernantes y gobernados nunca
          dejará de ser una generosa utopía (Borja) .
        </i>
      </p>,
      <p>
        La búsqueda de la democracia de calidad con alternativas de crecimiento
        social y personal en libertad, es tarea de todos; más, debemos partir
        desde nuestras propias limitaciones. Como humanos somos proclives a
        cometer errores, pero también somos capaces de enmendarlos. Esto implica
        concienciar que somos corresponsables de haber elegido a los líderes
        políticos que nos han gobernado. Quizá en forma indirecta hemos apoyado
        a los demagogos, cuando aceptamos las arengas acusadoras de los
        politiqueros que inculpan: los males del país, al sistema, a sus
        detractores políticos o al gobierno anterior. Aún persiste poca
        concienciación de parte de los electores que ha facilitado llegar al
        poder a nefastos gobernantes. Olvidamos que el populismo sobrevive en la
        demagogia; la retórica coyuntural mediática y, en el discurso forjado al
        gusto de las masas. No hemos castigado a los farsantes en las urnas;
        tampoco parece avergonzarnos la corrupción rampante. Muchos, hemos
        escogido el camino fácil: endilgar los defectos y deficiencias a “los
        otros/as” (gobiernos o partidos contrincantes); y omitimos la
        autoevaluación ciudadana general. Los gobernantes son el reflejo de las
        propias limitaciones ciudadanas. El salto cuántico en nuestra democracia
        se producirá, cuando comprendamos la necesidad de hacernos una auto
        concienciación profunda.{" "}
      </p>,
      <p>
        Los seguidores del populismo izquierdista se sienten víctimas del
        sistema oprobioso, basado en el neoliberalismo del libre mercado: “las
        medidas del F.M.I.”, “la prensa pro imperialista”, “la privatización de
        las empresas del estado”, etc. La contraparte de derecha culpa a: “los
        comunistas”, “la estatización”, “la fuga de capitales”, “la entrega de
        bonos sin mérito alguno”, etc. En cierta medida, la mayoría ciudadana ya
        somos pequeños populistas, cuando creemos que los males sociales son
        culpa ajena, no la nuestra. Se ha aceptado como válido, el razonamiento
        de los expertos acusadores de los problemas sociales y nuestra visión se
        ha obnubilado. Aun no se ha aprendido del espejo venezolano, donde sus
        líderes socialistas, para mantenerse en el poder, culpan la crisis del
        país (devaluaciones, desempleo, hambruna, emigración); no al sistema
        corrupto carente de libertades y su política económica desacertada: sino
        a “los lacayos del imperialismo”, “la derecha corrupta”, “los
        guarimberos”, entre otros.{" "}
      </p>,
      <p>
        En este contexto, las afirmaciones: “la pobreza y desempleo es causada
        por la desatención del gobierno”; “la explotación laboral y los salarios
        bajos, son ocasionados por los ricos y la oligarquía criolla”; “el
        ineficiente desarrollo del País es debido al neoliberalismo”, son
        verdades relativas. Estas justificaciones propias del populismo de
        izquierda, nos hacen sentir, “víctimas” del denominado sistema injusto;
        los cuales, repetidos constantemente van calando hondo el subconsciente
        colectivo, y es propenso a replicase en una sociedad de escasez. Pero la
        carencia es más evidente en los regímenes totalitarios seguidores del
        marxismo – leninismo que han privado las libertades ciudadanas y
        eliminado la propiedad privada. El Dr. Armando de la Torre, en una de
        sus conferencias magistrales en la U.F.M., afirma que a finales de la
        S.G.M., algunos estados latinoamericanos eran ricos; y que hoy, (tercera
        década del Siglo XXI), debido a la intromisión de estas ideologías han
        empobrecido severamente algunos países; entre ellos: Cuba, Venezuela,
        Argentina, entre otros. Subraya, que: “la mejor forma de crear riqueza
        es competir con una visión de largo plazo” (De la Torre) .{" "}
      </p>,
      <p>
        Los politiqueros prescinden explicar que la miseria y la falta de
        empleo, no es única responsabilidad del gobierno; sino también de los
        ciudadanos limitados/as por su falta de creatividad para el
        emprendimiento. ¡Muchos son conscientes, pero “lo omiten por
        estrategia”! ¡No tienen las agallas para manifestar que: “la minga” para
        superar el subdesarrollo requiere trascender el estado mental de
        ineficacia [*] olvidan que el desarrollo está vinculado a la fe y el
        pensamiento positivo; al trabajo honesto y creativo; a la adopción de
        políticas económicas apropiadas, audaces y transparentes. Es decir, el
        despegue económico tiene relación directa con la educación de calidad;
        la honestidad y la práctica de valores; lo cual se relacionan en un
        círculo virtuoso en el nivel de bienestar ciudadano.
      </p>,
      <p>
        <i>[*] Subrayado del autor.</i>
      </p>,
      <p>
        La democracia es la vía idónea para generar cambios sociales
        substanciales a través del debate y la participación social; incluso,
        por su intermedio se puede acceder a cualquier otro sistema de gobierno,
        incluyendo un régimen de corte marxista... El requisito único es que,
        todos los cambios políticos deben encontrarse codificados taxativamente
        en la Carta Magna para evitar falsas interpretaciones que atenten contra
        la democracia. El filósofo Karl Popper añade al respecto: “la democracia
        es el régimen por medio del cual se puede provocar cambios sin necesidad
        de derramamiento de sangre”. Desde esta perspectiva, si la mayoría
        ciudadana concientizada, exenta de presión y demagogia, por propia
        voluntad escoge el sistema de extrema izquierda, entonces constituye una
        alternativa válida. Aun cuando el modelo a adoptarse rompa con el
        paradigma democrático y la experiencia resulte traumática, debe
        respetarse su decisión. ¡Porque más democrático es el decreto del pueblo
        soberano!, pero no olvidemos que toda creación humana es perfectible y
        está sometida a constante escrutinio social; entonces, concluido el
        período de gobierno, el pueblo debe volver a las urnas para escoger su
        destino democrático y producir el relevo administrativo de rigor.{" "}
      </p>,
      <p>
        El ejercicio democrático codificado en la constitución, con sus
        artículos de cumplimiento obligatorio, debe prohibir de manera clara la
        reelección indefinida. Esta muestra constituye la condición “sine qua
        non” para el funcionamiento de la democracia. La alternabilidad de toda
        autoridad y magistratura, impide al populismo permanecer en el poder
        arbitrariamente: lo contrario se transforma en una dictadura. Por ello,
        la carta magna debe garantizar otras modalidades directas de
        participación popular: como el referéndum, el plebiscito, la revocatoria
        del mandato, entre otras. En tanto, las formas indirectas de
        intervención ciudadana: la opinión pública, los partidos políticos, los
        movimientos sociales, las ONGs, y los sindicatos, también coadyuvan a
        garantizar la vigencia del sistema. Otras propuestas importantes que
        impliquen cambios, y “mejoras” al modus vivendi de la población,
        requerirá debate previo, y consulta al soberano.{" "}
      </p>,
      <p>
        En los regímenes dogmáticos de partido único, a pesar de las
        restricciones, constantemente se observan manifestaciones en contra del
        autoritarismo y demás taras del sistema. Igualmente, y con mayor
        libertad, los ciudadanos del mundo libre protestan en contra de las
        políticas gubernamentales. Toda democracia está en construcción y
        siempre habrá que mejorarla. Hoy, a décadas del histórico discurso de
        Martin Luter King en contra de la segregación racial en los Estados
        Unidos ¬-agosto de 1963-, presenciamos masivas manifestaciones a nivel
        mundial en rechazo al abuso de poder. Millones de manifestantes se han
        movilizado desafiando las medidas obligatorias de la cuarentena
        impuestas por los gobiernos en precaución al contagio del virus [*]. La
        muerte del ciudadano de color, George Floyd por un exceso policial en
        Mineápolis, ocurrida el 25 de mayo de 2020, aunque podría considerarse
        un caso aislado, nos alerta de que toda democracia requiere cuidado y
        perfección.{" "}
      </p>,
      <p>
        <i>
          [*] La pandemia de Corona Virus o COVID - 19, inició en la ciudad de
          Wuhan, capital de Hubei en China, el 17 de noviembre de 2019. La
          enfermedad produjo muerte y recesión económica; golpeó las democracias
          del planeta por el confinamiento obligatorio que en algunos países
          duró más de un año. Los gobiernos debieron tomar la medida extrema de
          aislamiento forzoso para evitar el contagio masivo y el colapso de los
          sistemas sanitarios y funerarios en sus países. El toque de queda
          prohibió salir de casa, excepto las actividades en los hospitales,
          clínicas y farmacias. Los gobiernos propiciaron el teletrabajo que
          permitió laborar a medias en el sistema educativo y demás entidades
          gubernamentales. El cierre de aeropuertos y toda clase de transporte
          terrestre paralizó la mayoría de fábricas, compañías y casas
          comerciales, lo cual devino en la crisis jamás vista después de la
          Gran Depresión de 1930. En muchos países los sistemas de salud
          colapsaron por la alta cantidad de enfermos que ingresaban
          diariamente. El número de fallecidos, que en algunos países procuraron
          no delatar, debieron inhumarse de inmediato con la presencia de 1 a 2
          deudos por temor al contagio, sin los honores acostumbrados, donde su
          exceso dificultó a las funerarias y crematorios cumplir su tarea. En
          Manaos, Brasil se vieron obligados a realizar sepulturas improvisadas
          en fosas comunes por la cantidad de decesos diarios. En la ciudad de
          Guayaquil – Ecuador, unos pocos murieron en las calles, y a veces los
          cadáveres fueron retenidos varios días en los domicilios por la
          dificultad de inhumación. El gobierno socialista de Nicaragua ordenó
          el “entierro exprés” por las noches para evitar causar alarma en la
          población. La proliferación de cepas del virus cada vez más
          peligrosas, hizo que, en ciertos países a pesar del proceso de
          vacunación emprendida a inicios del primer semestre del 2021, continúe
          los elevados índices de mortandad. En mayo de ese Año, igual que en la
          mayoría de países, las cifras oficiales en la India establecieron
          4.000 fallecimientos diarios, pero las evidencias señalaban un número
          mucho mayor.{" "}
        </i>
      </p>,
    ],
  },
  {
    section: 39,
    title: "Transparencia para trascender el Subdesarrollo",
    ref: "/TransparenciaParaTrascenderElSubdesarrollo",
    parrafos: [
      <p>
        La transparencia en el manejo de los bienes públicos por parte de
        gobernantes, magistrados y ciudadanos en general, hace relación directa
        con una democracia civilizada y de alta calidad. Las leyes de un sistema
        de justicia condicionado, no han logrado detener la corrupción; esto es
        materia de la formación en valores del ciudadano democrático. Debemos
        concienciar que la deshonestidad es el cáncer que afecta a toda la
        sociedad en un círculo vicioso; y que, los bienes o dineros mal habidos
        del estado, desde lo más insignificante hasta los grandes caudales,
        pertenecen a toda la población en su conjunto.{" "}
      </p>,
      <p>
        Los actos de corrupción en sus diferentes modalidades que trasgreden los
        intereses de la sociedad, revelan la disminuida consciencia democrática
        ciudadana. Es tiempo de asumir, nuestra corresponsabilidad como seres
        inteligentes para encarar los males sociales. La deshonestidad social
        debido a la limitada practica de valores se manifiesta de manera directa
        en las instituciones del estado. Es ilógico que un gobernante exija
        transparencia a sus ministros y/o gobernados si su actuación personal es
        amoral. Por nuestra parte, los ciudadanos tenemos derecho de exigir
        transparencia a nuestros mandatarios, si nuestra conducta esta apegada a
        derecho. Es inapropiado escandalizarse o señalar a quienes han caído en
        actos de corrupción, sin habernos hecho nuestra propia mea culpa.{" "}
      </p>,
      <p>
        El hecho de timar a su propia Patria, es un acto de deslealtad a ella y
        a la sociedad en general, incluyendo las familias de los infractores.
        Algunos ciudadanos observamos indiferentes este tipo de atracos; y poco
        nos inmutamos creyendo que aquello no nos afecta: debe entenderse que
        cuando el/la deshonesto/a ha robado al estado, nos han hurtado a todos.
        Desde esta óptica, quien ha cometido un atraco a las arcas de su País;
        su acción debe ser considerada más execrable, en relación al infractor
        que roba una cartera en la calle. Su posición social de ventaja, por ser
        un servidor del estado, lo hace al hecho más culposo. El transgresor de
        poca monta, quizá posea una justificación que le llevó a cometer el
        ilícito; el segundo no posee, por percibir salario del estado.{" "}
      </p>,
      <p>
        Algunos, quizá por desconocimiento o ligados a un criterio populista
        creen que la corrupción es algo natural, porque “todos lo hacen”; y, por
        estar generalizada en la sociedad, es imposible revertirla. Más, el
        primer paso para trascender el desfalco, el sicariato y el narcotráfico
        presente en los estamentos del estado, requiere un cambio de paradigmas
        vigentes en el subconsciente colectivo, que debe ser combatida desde
        distintos frentes. Si bien, es una gestión de largo aliento; a través de
        un liderazgo político apropiado, no sólo es posible controlarla, sino
        someterla y reducirla significativamente. Para ello, es vital la
        concienciación cívica ciudadana proyectada desde el hogar y la escuela,
        hacia la sociedad.{" "}
      </p>,
      <p>
        En el sistema democrático: políticos, magistrados, directivos y
        administradores de todo rango deben recordar que son sujetos y objetos
        de evaluación, control y rendición de cuentas. La gestión dinámica y
        transparente de quienes ostentan las primeras magistraturas del País, de
        inmediato será reconocido por el pueblo; si ello es inverso, estará
        sometido a la vindicta pública. La concienciación democrática convierte
        al ciudadano en un buen contribuyente económico-político-social del
        estado. Si bien, el rol del gobernado difiere del gobernante, sus
        aportes son vitales para su desenvolvimiento, ya que todos somos sus
        servidores naturales.{" "}
      </p>,
      <p>
        Así mismo, la observancia de las leyes y el cumplimiento de nuestras
        obligaciones ciudadanas, nos hacen seres sociales positivos, dignos
        partícipes de una sociedad civilizada; porque el libre albedrío para
        opinar, emprender y actuar que nos confiere la libertad, no es un salvo
        conducto para transgredir los valores y derechos de nuestros congéneres.
        En este proceso, la rectitud de todo/a ciudadano/a juega un papel
        trascendental para mover el engranaje democrático en beneficio a toda la
        sociedad en su conjunto.{" "}
      </p>,
      <p>
        La difícil tarea de erradicar la corrupción que afrenta la dignidad del
        país, requiere la adopción de una serie de medidas de largo aliento.
        Aquello demanda el compromiso de los poderes del estado; la ciudadanía
        en general; y, la asistencia internacional. Significa impulsar la
        institucionalidad democrática para revolucionar la ética ciudadana con
        acciones concretas, como: perfeccionar el sistema educativo hacia una
        formación en valores; vigilar la estricta independencia de los poderes
        del estado que permita identificar y sancionar a los infractores de
        “cuello blanco”; amonestar y ejercer constitucionalmente la revocatoria
        del cargo, por el incumplimiento de las ofertas de campaña, a toda
        autoridad de elección popular; insertar a los reclusos en el mercado
        laboral, a través de la ley que imponga el trabajo obligatorio, para la
        regeneración conductual mientras cumplan sus condenas; optimar los
        reglamentos para la selección de servidores/as dignos/as y probos
        (autoridades seccionales, jueces; etc.); reconocer a las personas
        naturales y jurídicas cuando constituyen verdaderos ejemplos de
        superación y honestidad; sancionar a jueces y juristas “defensores” de
        avezados infractores que dejan a la sociedad en defección; garantizar la
        vigencia de una genuina libertad de expresión para alertar sobre los
        actos dolosos que ocurren en los estamentos del estado.{" "}
      </p>,
      <p>
        La benéfica pero ardua labor de transparentar la sociedad requiere la
        aceptación y el comprometimiento de todos. Esta magna acción debe
        concebirse como: “La Cruzada de la Patria, por el Buen Vivir y la
        Oportunidad para todos”. Los marxistas expertos en diferenciar entre la
        inequidad y la desigualdad social deben comprender que la mayor
        injusticia social, es la corrupción. ¡No es absoluto aquello, que los
        infractores sean “consecuencia del sistema”, cuando existe la conducta
        laboriosa y holgazán! Pues, mientras los honestos perciben sus ingresos
        en razón a sus esfuerzos, otros, en base a sus artimañas, roban los
        dineros del pueblo… José Diez, en su libro: “La Economía no da
        Felicidad, pero ayuda a conseguirla”, señala al respecto:{" "}
      </p>,
      <p>
        <i>
          La corrupción es consustancial a la naturaleza humana, y no entiende
          de latitudes. La corrupción existe en todos los rincones del mundo, la
          diferencia reside en cómo se enfrentan los distintos países a ella.
          Hay sociedades que se vacunan contra la corrupción gracias a la
          libertad de prensa e intelectual y que fomentan instituciones fuertes
          e independientes del poder político, principalmente la justicia. Y
          cuando algún responsable se ve envuelto en un caso de corrupción, la
          sociedad lo repudia y lo obliga dimitir. En los países donde las
          instituciones son más débiles, los corruptos siempre encuentran una
          mano amiga para mantenerse en el poder (Díez, La Economía no da
          Felicidad pero ayuda a conseguirla) .{" "}
        </i>
      </p>,
    ],
  },
  {
    section: 40,
    title: "Transversalismo: El Nuevo Paradigma ",
    ref: "/TransversalismoElNuevoParadigma",
    parrafos: [
      <p>
        Desde las postrimerías del siglo XIX hasta los inicios del siglo XXI,
        una disputa ideológica se ha agudizado en el planeta al interior del
        espectro político clásico: izquierda y derecha. Con el criterio de
        zanjar la secular disputa entre las dos corrientes antagónicas: el
        capitalismo y el socialismo, se ha pretendido hacer uso de diversos
        medios de coerción a fin de asegurarse el control geopolítico del
        planeta -zona pivote o Heartland de Mackinder-. Esta controversia
        amenazó al mundo llevar a La Tercera Guerra Mundial, y se polarizó
        después de la S.G.M., con el surgimiento de la denominada Guerra Fría.{" "}
      </p>,
      <p>
        En América Latina, la desconfianza se acentuó con la entronización del
        socialismo cubano-ruso a partir de 1959. La pugna ideológica alentada
        por sectores beneficiarios de la misma, a través de 7 décadas, derrochó
        millonarias cantidades de dinero por concepto de la carrera
        armamentista: adoctrinamiento ideológico-político; y la logística para
        la guerra de guerrillas. Aun cuando, muchas personas han justificado tal
        despilfarro culpando a uno u otro bando por las secuelas de la guerra no
        declarada; aquello nos ha limitado como sociedad dar un paso adelante en
        el campo social. Indudablemente, si el dinero y energía empleados para
        esta causa se hubiesen orientado a los marginados, o para la
        preservación del planeta, aquello verdaderamente hubiera resultado en
        una “gran revolución” en favor de ella, y sus huéspedes.{" "}
      </p>,
      <p>
        En este orden de cosas, el término transversalismo cobra importancia,
        dado el razonamiento del justo medio aristotélico, sintetizado en la
        búsqueda de la medida apropiada entre la perfección y la mediocridad;
        entre el defecto y el exceso. La moderación en todos los aspectos como
        una aspiración humana para encaminarse hacia el bienestar. Esta
        corriente ideológica opuesta a concepciones dogmáticas, pretende
        establecer un orden político-social armonioso basado en el consenso y el
        diálogo.{" "}
      </p>,
      <p>
        Este razonamiento lógico nos permite colegir que, en ciencias sociales e
        incluso en las experimentales, no siempre existen dicotomías absolutas.
        Entre la riqueza y la pobreza, existen niveles de fortuna e indigencia
        económicas intermedias. Al igual que en el aspecto psicológico: en la
        felicidad y la desdicha, existen estados psíquicos medios que puede
        considerarse aceptables. En el campo ideológico, la adopción de una
        doctrina moderada permite una identificación política coherente, para
        efectos de crecimiento y realización social. El objetivo: eliminar el
        criterio de “izquierda” o “derecha” política del subconsciente
        colectivo, y reducir la pugna entre tendencias antagónicas de carácter
        ideológico-político-religioso. Aquello, ayudará a preservar la estirpe
        humana y direccionarla hacia misiones elevadas de mayor trascendencia
        para la vida: la solidaridad entre congéneres y la preservación del
        planeta.{" "}
      </p>,
      <p>
        Algunos politólogos serios, desde hace mucho tiempo han procurado
        superar la dicotomía ideológica, capitalismo versus socialismo, a fin de
        alcanzar un mejor nivel de vida global. Inclusive, algunos gobiernos
        democráticos han aplicado programas de orientación moderada, tendientes
        a armonizarse con los sectores sociales que coinciden con los ideales
        del centrismo; ya sea con matices del centro-izquierda o centro-derecha.
        A veces, el fanatismo de la ultra izquierda ha retrasado la toma de
        decisiones en algunos países. Su dogmatismo no les permite trascender el
        razonamiento de su único punto de vista; para quienes, todo el conjunto
        social formado por el centro político, se relaciona a la ideología de
        derecha, incluyendo la centroizquierda. Lenin criticaba esta corriente
        ideológica, considerándola: “un utopismo traicionero producto del
        reformismo burgués” o, una forma encubierta de contrarrevolución.{" "}
      </p>,
      <p>
        El centrismo ideológico, también llamado por algunos politólogos:
        Tercera Vía, no es una tendencia de apoliticismo vacío; tampoco un
        Neocapitalismo carente de criterio político. El transversalismo
        constituye una síntesis política hacia la profundización de la
        democracia. Es una posición ideológica ubicada entre el socialismo y el
        capitalismo, sin prevalencia: ni de “izquierda” ni de “derecha”. Se
        orienta hacia la moderación para trascender el enfrentamiento estéril y
        fútil del extremismo. Este criterio surge de la observación de los
        efectos reales de la politiquería dogmática de ambos extremos, del cual
        se infiere que: ¡el fanatismo ideológico-político resultó banal y
        contraproducente para la especie humana a través del tiempo y el
        espacio!{" "}
      </p>,
      <p>
        Ciertas alternativas políticas de “izquierda” o “derecha”, satanizadas
        por ciertos grupos sociales en algún tiempo, posteriormente han sido
        adoptadas indistintamente en muchos países. En Europa, el partido de
        centro izquierda se podría ubicar entre los reformistas; incluso el
        ecologismo y demo-cristianismo, podrían incluirse en esta tendencia. En
        Estados Unidos de América, el conservadurismo ubica al liberalismo en la
        izquierda. Es decir, en la política se hace más claro el principio de
        impermanencia, donde “todo es relativo”; pues, la realidad social está
        en construcción y cambio: es sujeto y objeto de perfectibilidad.
        Entonces, no es racional idealizar un sistema filosófico, social o
        político, a riesgo de caer en un apasionamiento fatuo, carente de
        sustento real. ¡Es hora de levantarnos contra el fanatismo extremista
        que conduce a la pugna fratricida!{" "}
      </p>,
      <p>
        En esencia, el transversalismo puede considerarse una corriente
        ideológica-política de constante búsqueda al servicio de la sociedad;
        por tanto, es mutante. Su base ideológica de tendencia moderada, destaca
        al hombre evolucionado idóneo para resolver su conflictividad a través
        de la indagación y la discusión razonada. Los extremistas pretenden
        ridiculizarlo con el epíteto: “partido escoba”, que supuestamente
        pretende captar los residuos de otros para ganar elecciones; pero el
        centrismo es más que un partido político electorero, es una ideología de
        convergencia universal. En torno a esta corriente se alinean otras
        similares en favor de la justicia, la transparencia, la libertad y la
        fraternidad. Constituye la síntesis del debate ideológico - político al
        margen de etiquetas, mordazas y balas asesinas. No es un giro ni a la
        derecha ni a la izquierda: es el camino hacia la investigación profunda
        y el diálogo civilizado sin mediación del ego humano.
      </p>,
      <p>
        Los sobrevivientes de genocidios, torturas y persecuciones de cualquier
        sistema nefasto; iluminados por sus aciagos sufrimientos dan testimonio
        del valor de la libertad, el diálogo y el perdón. Los falsos paradigmas,
        basados en una seudo-concreción ideológica, que muchos fanatizados
        defienden como su suprema verdad, apenas son verdades cambiantes y
        relativas. Por tanto, zanjar la polémica a través del diálogo
        constructivo hacia la adopción de las mejores alternativas, provenientes
        de la “izquierda” o “derecha”, es el reto del centrismo que devendrá en
        efectos benéficos inmediatos. El término medio político, no es de
        mediocres, como se pretende posesionar: requiere un alto nivel de
        abstracción humana.
      </p>,
      <p>
        En este contexto, es el propio homo sapiens de origen divino o
        evolucionado, poseedor de consciencia superior, a quien le corresponde
        la magna tarea de rescatar su planeta del colapso producido por la
        avaricia desenfrenada de sus congéneres. Entonces, convencido de que el
        gran cambio inicia a través del diálogo, el consenso y la moderación;
        invoco el centrismo, como nuevo paradigma político que nos conduzca al
        encuentro del hombre nuevo*: solidario, transparente y comprometido con
        su habitad natural.
      </p>,
      <p>
        <i>*Subrayado del autor</i>
      </p>,
    ],
  },
  {
    section: 41,
    title: "Acercamiento Ideológico al Centro Político: La Tercera Vía",
    ref: "/AcercamientoIdeologicoAlCentroPoliticoLaTerceraVia",
    parrafos: [
      <h5>La Centroderecha</h5>,
      <p>
        La ideología de centroderecha o derecha moderada, donde convergen
        posturas del centro político, tuvo sus orígenes alrededor de 1930 en la
        escuela de Friburgo. Sus raíces provienen del liberalismo de finales del
        S. XVII; otros aportes constituyen: el conservadurismo que emergió
        después de 1789; y ciertos elementos de la Democracia Cristiana (DC) en
        el siglo XIX. Es decir, sus idearios en torno a la preservación de las
        costumbres y valores del pueblo; así como el criterio nacionalista,
        provienen de su matriz de derechas. Comúnmente esta tendencia es
        preferida por personas relacionadas al comercio y al mundo financiero.
        Su principio de mantener el statu quo social, ha llevado a sus oponentes
        de centroizquierda a llamarla: ideología de la burguesía.{" "}
      </p>,
      <p>
        Algunos partidos políticos de tendencia moderada alineados a la
        centroderecha como la Democracia Cristiana poseen diferencias con sus
        similares de derecha no extrema, pero mantienen su base ideológica. De
        esta manera, la DC posee características de centroderecha en los
        aspectos económicos, morales y culturales; pero también se identifica
        con la centroizquierda en asuntos políticos y sociales, por lo que
        fueron inicialmente condenados por la propia iglesia. No obstante, aun
        cuando su ideología fundamental se acopla con los principios del
        capitalismo; sus defensores dicen tratarse de un “capitalismo con rostro
        humano”. Desde esta perspectiva, debe reconocerse cierta afinidad con la
        corriente Social Cristiana, cuando establece que: “el deber del estado
        es cuidar de sus ciudadanos” (Wikipedia) .
      </p>,
      <p>
        Desde esta óptica, el centrismo de derecha es proclive a adoptar con
        cautela, lo mejor del capitalismo (libertad y emprendimiento), ídem del
        socialismo (ayuda al sector vulnerable); además, respalda el rol del
        estado como garante del orden y seguridad interna y externa. La política
        de libre mercado no está excluida, es examinada en relación con otras
        corrientes para su óptima aplicación. Se considera cercana a la política
        de economía mixta como modelo económico, donde se combina la libre
        empresa y el intervencionismo estatal en áreas estratégicas. Pero lo más
        significativo constituye, la convocatoria al diálogo abierto al resto de
        tendencias ideológicas. Estos coloquios para la consecución de puntos de
        convergencia ya han iniciado en algunos países, principalmente entre la
        centroderecha y la centroizquierda.{" "}
      </p>,
      <p>
        La temática en discusión puede sintetizarse en los siguientes puntos:{" "}
      </p>,
      <p>
        <li>Conservación de las costumbres y valores del pueblo;</li>
      </p>,
      <p>
        <li>
          Disciplina Fiscal y Reducción del gasto público para evitar déficits y
          frenar la inflación;
        </li>
      </p>,
      <p>
        <li>Medidas para mejorar el modelo de economía social de mercado; </li>
      </p>,
      <p>
        <li>
          Reducción de impuestos para estimular el consumo, el ahorro e
          inversión;{" "}
        </li>
      </p>,
      <p>
        <li>
          Racionalización de programas sociales parar evitar el abuso de los
          subsidios del estado;
        </li>
      </p>,
      <p>
        <li>
          Privatización de las empresas estado no esenciales al interés
          nacional;
        </li>
      </p>,
      <p>
        <li>
          Comercio internacional libre, dentro de los acuerdos de interés
          nacional y regional.{" "}
        </li>
      </p>,
    ],
  },
  {
    section: 42,
    title: "La Centroizquierda",
    ref: "/LaCentroizquierda",
    parrafos: [
      <p>
        Algunos expertos en ciencias políticas sostienen que la corriente Social
        Demócrata podría considerarse como el origen del centrismo político.
        Aquello se produjo luego de la escisión ideológica en la AIT (Asociación
        Internacional de Trabajadores), que concluyó en la división entre
        revolucionarios (comunistas) y reformistas. Estos últimos se
        subdividieron en laboristas, socialistas y socialdemócratas. Años más
        tarde, los reformistas y socialistas reunidos en Bruselas iniciaron la
        II Internacional (1889 – 1916), conocida como la Internacional
        Socialista (IS). Su objetivo era fundar un colectivismo democrático que
        permita al pueblo optar su propia gobernanza dentro de un marco de
        libertad; aquello se lograría al reemplazar al capitalismo por el
        denominado “Socialismo Democrático”, donde los intereses públicos tengan
        preferencia por sobre los privados, a través de transiciones graduales y
        pacíficas. Su principio fue, oponerse al cambio revolucionario violento
        y la dictadura comunista. Después de la S.G.M., los socialdemócratas de
        occidente presentaron su propuesta: una vía diferente al capitalismo de
        mercado norteamericano y al comunismo soviético. Y, al concluir el siglo
        XX, sus seguidores se alejaron de la visión marxista dado los
        acontecimientos históricos suscitados en 1989 con la caída del Muro de
        Berlín.{" "}
      </p>,
      <p>
        Aun cuando ciertos politólogos sostienen que la centroizquierda tuvo sus
        orígenes en el siglo XIX, esta ideología cercana al socialismo con
        distintivos capitalistas surge a principios del siglo XX;
        particularmente, luego del derrumbamiento del socialismo ruso en 1991.
        Esta corriente denominada “Tercera vía”, alcanzó mayor notoriedad con el
        aporte de la Teoría de la Estructuración del inglés Anthony Giddens.
        Algunos políticos latinoamericanos, como el argentino Fernando de la
        Rúa, han adoptado esta corriente política considerándola “el nuevo
        camino”. El propósito central es armonizar el crecimiento económico con
        equidad social; impulsar la educación, la salud, el trabajo, la
        reducción de la pobreza y la seguridad social. En la actualidad, en que
        a decir de Giddens: “el socialismo fracasó”; y el enfoque tradicional
        socialdemócrata ofrece limitadas alternativas al capitalismo absorbente,
        es un imperativo la modernización ideológica ciudadana*. Por tanto, no
        es posible convertirnos en trampolines políticos de los fanatizados de
        ideologías caducas; tampoco de los neoliberales extremados adictos al
        capital y el mercado. Debemos apropiarnos de mayor resiliencia y
        permeabilidad a la crítica y la autocrítica para trascender el
        fanatismo; ya que, “La meta general de la política de la tercera vía
        debería ser ayudar a los ciudadanos a guiarse en las grandes
        revoluciones de nuestro tiempo: la globalización, las transformaciones
        de la vida personal y nuestra relación con la naturaleza” (Giddens) .
      </p>,
      <p>
        <i>* Subrayado del autor.</i>
      </p>,
      <p>
        Para Giddens, la globalización es un “fenómeno sui generis del mundo
        actual”, no una tendencia del capitalismo como sostiene el marxismo.
        Está caracterizada por el gigantesco crecimiento de los mercados
        financieros a nivel mundial; y la revolución telemática que ha logrado
        transformar el criterio del tiempo y el espacio en nuestras vidas. Más,
        si bien el libre comercio internacional es dinamizador del crecimiento
        económico, es función de los estados la regulación de los mercados. Ello
        implica mecanismos de integración regional y trasnacional para fundar la
        democracia cosmopolita, donde la Unión Europea y las Naciones Unidas
        deben poseer un rol de mayor responsabilidad.{" "}
      </p>,
      <p>
        Los principales temas de debate de la centroizquierda se sintetizan:
      </p>,
      <p>
        <li>
          Defensa de la economía mixta como modelo económico - político viable,
          con énfasis en la variable social (similar al criterio de
          centroderecha);{" "}
        </li>
      </p>,
      <p>
        <li>
          Como vía de redistribución de ingresos: mantener un alto nivel de
          impuestos y rechazar recortes fiscales;{" "}
        </li>
      </p>,
      <p>
        <li>
          Promover el "estado de bienestar" ciudadano. Es deber del estado
          implementar políticas de equidad social y desarrollo, para que todos
          se acojan a los beneficios sociales básicos;
        </li>
      </p>,
      <p>
        <li>
          Mantener el equilibrio fiscal y conservar la regulación de los
          mercados que evite abusos de la empresa privada y se proteja al
          consumidor;
        </li>
      </p>,
      <p>
        <li>
          Impulsar una legislación laboral más amplia y proteccionista sobre los
          derechos de los trabajadores;
        </li>
      </p>,
      <p>
        <li>
          No a la privatización de las empresas del Estado. Las empresas
          privadas que pueden mantenerse son aquellas de interés nacional con
          poca participación del estado;
        </li>
      </p>,
      <p>
        <li>
          En materia de comercio exterior, limitar el libre comercio a través de
          un moderado proteccionismo para preservar los intereses nacionales y
          lograr un comercio justo.
        </li>
      </p>,
      <p>
        En síntesis, para un genuino acercamiento ideológico hacia el centro
        político, debe concebirse un debate desligado de las concepciones de “la
        derecha” y “la izquierda” *; no únicamente por estar opuesta al
        comunismo y al capitalismo; sino, sobre todo porque en la práctica
        descarta el dogmatismo y adopta el debate democrático civilizado serio
        de mente abierta. La nueva alternativa adopta como eje central al hombre
        y mujer, altamente capacitados para las negociaciones a través de la
        racionalidad y sensibilidad. Los cambios sociopolíticos inspirados en la
        libertad y la democracia, deben ejecutarse desde la institucionalidad de
        un estado de derecho y libertad. Para este cometido, tanto la centro
        derecha como la centro izquierda moderadas, deben abandonar sus actuales
        denominaciones, para adoptar uno nuevo: “transversalismo democrático”.{" "}
      </p>,
      <p>
        <i>*Subrayado del autor</i>
      </p>,
    ],
  },
  {
    section: 43,
    title: "Perfil del Estadista Ideal para el Ejercicio del Poder",
    ref: "/PerfilDelEstadistaIdealParaElEjerciciodelPoder",
    parrafos: [
      <p>
        ¿Es la falsa filantropía de los populistas la que atrae a la gente a
        votar por los demagogos políticos sin experiencia en la producción de
        bienes y servicios y gestión administrativa? ¿Puede un político gobernar
        sin experticia en la administración del estado, concerniente al capital,
        el mercado y la estructura geopolítica internacional? Estos y otros
        cuestionamientos surgen, cuando regímenes improvisados gobiernan a
        espaldas de los ciudadanos, protagonizando actos de corrupción y abusos
        de poder. En este escenario, el mayor responsable resulta ser el propio
        pueblo, debido al desconocimiento y apatía a involucrarnos en la
        temática ideológica-política. La indiferencia social a indagar el perfil
        ético-profesional de los aspirantes a la jefatura del estado; sumado a
        ello: la pasión mediática coyuntural ha devenido en confusión al momento
        de expresar nuestra voluntad en las urnas. Así, el voto del pueblo no
        siempre ha sido la expresión de soberanía para elegir al candidato
        merecedor de gobernar y emprender los cambios que requiere el País. Y,
        el ciudadano frecuentemente se ha constituido en “un trampolín” de
        improvisados aprendices de dictadores populistas de fácil palabreo, que
        se dicen: “redentores de los marginados”.{" "}
      </p>,
      <p>
        Desde otra perspectiva, la ejecución de obras de infraestructura física
        por parte de un mandatario, no le convierte en un gobernante ideal: ya
        que aquello es su función natural como administrador de los bienes del
        estado. Empero, si además ha promovido la consciencia del pueblo por
        medio de leyes sabias y ha realizado un ejercicio administrativo pulcro;
        sobre todo, si ha impulsado la educación, la libertad y la democracia,
        entonces será reconocido y recordado como un estadista paradigma. No es
        lógico ovacionar a un/a docente por preparar e impartir sus clases
        responsablemente: aquello es su tarea inexcusable; más, si ha promovido
        aprendizajes significativos y con ello ha transformado positivamente la
        mente y el espíritu de sus pupilos, ciertamente debería ser
        reconocido(a).
      </p>,
      <p>
        Previamente debe reconocerse que, no es posible demandar perfección
        absoluta a un(a) líder o lideresa candidato(a) a la primera magistratura
        del país en una contienda política, ya que es parte de nuestra realidad
        social. Pues, la consecución del “gobierno perfecto” es quimérica; para
        los críticos no habrá personalidad política que valga. Y, si aludimos al
        criterio cuántico de perfección exigida a nuestros gobernantes, aquello
        está relacionado a la eticidad de su entorno social; es decir, al pueblo
        al cual pertenece. Resulta utópico que los ciudadanos exijamos un
        gobierno íntegramente honesto, si la mayoría de sus bases sociales se
        encuentren limitados en cuanto a sus valores morales. No obstante, si un
        significativo número de personas libres y conscientes anhelan una
        democracia elevada con progreso social en libertad, es seguro su
        consecución. Entre muchos ciudadanos(as) dignos(as), se procurará que el
        perfil que se busca, se encuentre entre los (as) mejores. Entonces,
        corresponde a través de nuestra fe y visualización colectiva, alcanzar
        el perfil ético-profesional del estadista idóneo para dirigir la
        superestructura física-energética y socio-política del estado.
      </p>,
      <p>
        Idealmente, el estadista es un ser humano multilateralmente versado en
        ciertos conocimientos y experiencias en cuestiones del Estado; practica
        los valores humanos con ventaja a los del ciudadano común y corriente.
        Es un líder visionario, desprendido de la riqueza material y capaz de
        gobernar su ego personal. Como persona decidida, se enfrenta a la
        problemática socio-política de la gente: los intereses colectivos, los
        conflictos sociales, y el cuidado del planeta. El uso de las nuevas
        tecnologías de la información y la comunicación social (TICs), es vital
        para una fluida comunicación e interacción a todo nivel. Winston
        Churchill, el estadista escritor e historiador británico, resume el
        perfil de este adalid ideal, al señalar: “El político se convierte en
        estadista cuando comienza a pensar en las próximas generaciones y no en
        las próximas elecciones”.{" "}
      </p>,
    ],
  },
  {
    section: 44,
    title: "Rasgos Psicológicos y Éticos del Estadista Real",
    ref: "/RasgosPsicologicosyEticosdelEstadistaReal",
    parrafos: [
      <p>
        <li>
          Es un ser humano poseedor de una gran voluntad y control emocional,
          motivador de valores morales, coherente y equilibrado: inteligente,
          honesto, respetuoso, compasivo y conciliador;{" "}
        </li>
      </p>,
      <p>
        <li>
          Posee conocimientos prácticos del trabajo y la justicia, acompañado de
          una consciencia holística en todas las áreas. Su criterio antirracista
          y compasivo en torno a la familia humana, le hace concebirse como un
          ciudadano universal.{" "}
        </li>
      </p>,
      <p>
        <li>
          Conserva un espíritu mediador y mente abierta; evita la confrontación
          y procura interactuar con todos: sin distingos de raza, credo,
          filiación política, nivel social y poder económico.{" "}
        </li>
      </p>,
      <p>
        <li>
          Mantiene una alta capacidad de resiliencia psico-somática que le
          permite sobreponerse al agotamiento físico e intelectual y demás
          conflictos propios de su investidura. Aquello coadyuva a superar la
          crítica de sus adversarios y a disipar la ansiedad al encarar
          situaciones sociales difíciles.{" "}
        </li>
      </p>,
    ],
  },
  {
    section: 45,
    title: "Currículo Vitae y Formación política",
    ref: "/CurriculoVitaeYFormacionPolitica",
    parrafos: [
      <p>
        <li>
          Es ideal que un gobernante democrático posea una buena formación
          académica, básicamente de tercero o cuarto nivel con un alto nivel
          humanístico-social; preferentemente con experiencia y/o conocimientos
          en política internacional, diplomacia y ciencias afines. Aquello
          permitirá una fluida interacción en el contexto internacional para la
          realización de convenios, negocios, y otros actos castrenses propios
          de sus funciones.
        </li>
      </p>,
      <p>
        <li>
          Tener, a más de los diplomas y documentos propios de su profesión, la
          certificación de las autorías de sus trabajos literarios o científicos
          en cualquier campo.{" "}
        </li>
      </p>,
      <p>
        <li>
          Mantener una identidad política definida de fácil reconocimiento
          ciudadano, con poca mutación ideológica (el cambio de partidos
          políticos, no constituye una trayectoria ideal). Recuérdese: la
          migración política es propia de los populistas.
        </li>
      </p>,
      <p>
        <li>
          Poseer un plan de gobierno de su autoría socializado y perfeccionado
          al interior de su partido con la ayuda de especialistas. Ello
          permitirá una fácil evaluación del nivel de cumplimiento de las
          ofertas de campaña, al término de su gestión.{" "}
        </li>
      </p>,
      <p>
        <li>
          Haber laborado en actividades de servicios o en la producción de
          bienes: sea en la industria, la banca, los negocios, o en el sector
          gubernamental. Esto resumirá la coherencia entre la teoría y su
          práctica social.{" "}
        </li>
      </p>,
    ],
  },
  {
    section: 46,
    title: "REFERENCIAS BIBLIOGRÁFICAS",
    ref: "/ReferenciasBibliograficas",
    parrafos: [
      <p>
        1. Aviléz, E. (s/f). Enciclopedia del Ecuador. Recuperado el 11 de Enero
        de 2021, de Enciclopedia del Ecuador:
        http://www.enciclopediadelecuador.com/personajes-historicos/dr-jose-maria-velasco-ibarra/
      </p>,
      <p>
        2. BBC Mundo. (04 de 2018). BBC,News. La oficina del Alto Comisionado de
        la ONU para los Derechos Humanos. Recuperado el 27 de Noviembre de 2020,
        de BBC Mundo: https://www.bbc.com/mundo/noticias-america-latina-43323616
      </p>,
      <p>
        3. Bisbal, M. (2006). La libertad de expresión en la filosofía de John
        Stuart Mill. Anuario de filosofía del derecho(23), 13-36. Obtenido de
        file:///C:/Users/DELL~1/AppData/Local/Temp/Dialnet-LaLibertadDeExpresionEnLaFilosofiaDeJohnStuartMill-2476026.pdf
      </p>,
      <p>
        4. Bolinaga, I. (2007). nowtilus.com. Recuperado el 6 de Noviembre de
        2020, de Nowtilus: https://www.nowtilus.com/descargas/BHFascismo.pdf
      </p>,
      <p>
        5. Borja, R. (I de 2018). www.enciclopediadelapolitica.org/democracia.
        Recuperado el 12 de Enero de 2021, de enciclopediadelapolitica:
        https://www.enciclopediadelapolitica.org/democracia.
      </p>,
      <p>
        6. Burnet, A. (2017). 50 Discursos que Cambiaron al Mundo (Primera ed.).
        (A. Burnet, Ed.) Moscú: Turner Publications S.L.
      </p>,
      <p>
        7. Cervera, C. (02 de 2018). Recuperado el 26 de Noviembre de 2020, de
        ABC historia:
        https://www.abc.es/historia/abci-locura-psicopata-caligula-durante-cuatro-anos-mas-siniestros-imperio-romano-201802020214_noticia
      </p>,
      <p>
        8. Chávez, H. (12 de 2013). El Libro Azul. (Segunda ed.). (F. Ávila, R.
        Romero, M. Bonnefoy, & I. Yglesias, Edits.) Caracas: Ediciones Correo
        del Orinoco. Obtenido de
        http://www.consulvenevigo.es/subido/LIBRO%20AZUL%20DESCARGA.pdf
      </p>,
      <p>
        9. Circulo de Lectores. (2009). Dicccionario Enciclopédico Circulo.
        Bogotá, Colombia: D´Vinni.
      </p>,
      <p>
        10. Conceptos, E. d. (2018). https;//concepto. de/populismo/. Recuperado
        el 20 de Diciembre de 2020, de Enciclopedia de Conceptos:
        https://concepto.de/populismo/. Enciclopedia de Conceptos (2018)
      </p>,
      <p>
        11. Corona, L., De la O Tamez, K., Miranda, A., Tamez, D. l., Karen,
        Gallardo, A., . . . Rodríguez, B. (2013). Democracia y elecciones
        (Primera ed.). (S. Fajuri, G. Martínez, & A. Gallardo, Edits.)
        Salamanca: Ratio Legis. Obtenido de
        www.triejal.mx/Publicaciones/democracia_elecciones. Pdf. Sara Eugenia
        Fajuri y Otros. Salamanca, 2013.
      </p>,
      <p>
        12. De Ascárate, P. (Mayo de 2005). http://www.filosofia.org. Recuperado
        el 21 de Diciembre de 2020, de filosofia.org:
        http://www.filosofia.org/cla/ari/azc03.htm
      </p>,
      <p>
        13. Díez, J. C. (2015). La Economía no da felicidad pero ayuda a
        conseguirla (Primera ed.). Barcelona: Penguin Random House Grupo
        Editorial España.
      </p>,
      <p>
        14. Eckhart, T. (2021.). El Poder del AHORA. Un Caino Hacia la
        Realización Espiritual. El Final del Drama de su Vida. Colombia: Penguin
        Random House, Grupo Editorial. Grijalbo.
      </p>,
      <p>
        15. Enciclopedia Salvat. (1982). Enciclopedia Salvat. En E. Salvat,
        Enciclopedia. Tomo 4 (pág. 1023). Barcelona, España: Imprenta Hispano
        América, S.A.
      </p>,
      <p>
        16. Espinosa, S. (01 de 2014). Diccionrio Ciudadano. Doble Discurso del
        Correismo en una brillante Recopilación. Recuperado el 5 de Enero de
        2020, de Blogger:
        https://balconalcaribe.blogspot.com/2014/01/semantica-del-doble-discurso-de-la.html.
      </p>,
      <p>
        16. Francisco, Martínez Hoyos;. (12 de 09 de 2019). El Legado de Pablo
        Escobar. Obtenido de
        https://www.lavanguardia.com/historiayvida/historia-contemporanea/:
        https://www.lavanguardia.com/historiayvida/historia-contemporanea/
      </p>,
      <p>
        17. Fumero, M. (18 de 08 de 2017). latribuna.hn2017/08/18. Recuperado el
        24 de Noviembre de 2020, de latribuna: www. latribuna.hn2017/08/18/.
      </p>,
      <p>
        18. García, D. (07 de 07 de 2017).
        bbc.com/mundo/noticias-america-latina-40527998. que son los colectivos y
        como operan para "defender la revolución bolivariana" en Venezuela.
        Recuperado el 29 de Diciembre de 2020, de BBC Mundo: :
        https://www.bbc.com/mundo/noticias-america-latina-40527998
      </p>,
      <p>
        19. García, I. (06 de Noviembre de 2014). Diario Las Américas.
        Recuperado el 20 de Octubre de 2020, de ¿Qué fue de los grandes
        disidentes cubanos de los noventa? LA ABANA: www.diariolasamericas.com{" "}
      </p>,
      <p>
        20. Giddens, A. (1999). memoria.fahce.unlp.edu.ar/art_revistas/pr.3815/.
        (M. D. Profesor investigador de la UNLR., Ed.) Recuperado el 11 de Enero
        de 2020, de La tercera vía. La renovación de la socialdemocracia.
        Taurus,:
        http://www.memoria.fahce.unlp.edu.ar/art_revistas/pr.3815/pr.3815.pdf.
      </p>,
      <p>
        21. Guevara, E. ". (Mayo de 2007). file.wordpress.com. Recuperado el 19
        de Octubre de 2020, de latinoamericanos:
        https//latinoamericanos.file.wordpress.com
      </p>,
      <p>
        22. Guevara, E. (05 de 1967). Ernesto Guevara. Crear dos, tres ...
        muchos VietNam es la consigna. Cuadernos de Ruedo ibérico. París, abril
        -mayo. Obtenido de http://www.filosofia.org/hem /ri12094. Ernesto
        Guevara.
      </p>,
      <p>
        23. Hitler, A. (2006). Consideraciones Políticas Resultantes de mi
        Estadía en Viena. En A. Hitler, Mi Lucha (pág. 65). Lima: Ebisa.
      </p>,
      <p>
        24. Infobae. (27 de Enero de 2019). Infobae.com/america/colombia.
        Obtenido de infobae.com:
        https://www.infobae.com/america/colombia/2019/01/27/el-mexicano-el-ministro-de-guerra-de
        pablo-escobar
      </p>,
      <p>
        25. Ingenieros, J. (2002). Las Fuerzas Morales. En J. Ingenieros, Las
        Fuerzas Morales (Primera ed., págs. 41 - 42). Lima, Perú: Ediciones
        Cultura Peruana.
      </p>,
      <p>
        26. Jaime, D. B., & Santiago, N. (2017). La Política en el Siglo XXI.
        Arte Mito o Ciencia. Buenos Aires: Penguin Random House Grupo Ediorial,
        S.A. Humberto I 555, Buenos Aires. www.megustaleer.com.ar.
      </p>,
      <p>
        27. Judis, J. (2017). La explosión populista. Cómo la Gran Recesión
        transformó la política en Estados Unidos y Europa. (Primera ed.). New
        York: Ediciones Deusto.
      </p>,
      <p>
        28. Kaiser, A., & Álvarez, G. (2016). El Engaño populista. Por qué se
        arruinan nuestros países y como rescatarlos. (Tercera ed.). Bogotá:
        Planeta.
      </p>,
      <p>
        29. Kiyosaki, R. (2012). El poder de la educación financiera. En R.
        Kiyosaki, La Ventaja del Ganador (págs. 323-324). Editora Aguilar.
      </p>,
      <p>
        30. Kossatchev, A. (2006). Catastroika Soviética.Requiem por un País
        desaparecido. Ultra Izquierda Latinoamericana como Reflejo del
        Bolchevismo Ruso. En A. Kossatchev, Catastroika Soviética. Loja: Casa de
        la Cultura Ecuatoriana y la Emabajada Rusa.
      </p>,
      <p>
        31. La Nación. (22 de 12 de 2006). lanacion.com.ar. Recuperado el 26 de
        Noviembre de 2020, de La Nación:
        https://www.lanacion.com.ar/el-mundo/falleció-niyazov-el-excentrico-dictador-de-
        turkmenistan
      </p>,
      <p>
        32. La Santa biblia. (1960). Libro Segundo de Moisés. Leyes sobre los
        esclavos. En D. R. Casiodoro, Exodo, Leyes sobres los Esclavos (pág.
        77). América Latina: Sociedades bíblicas en América Latina.
      </p>,
      <p>
        33. Larousse. (2005). Historia de la Roma Antigua. Santiago, Chile:
        Editorial Santiago Ltda.
      </p>,
      <p>
        34. Larrea, M. (2006). La Triple Victoria de Chávez o la guerra por
        otros medios. (Cuarta ed.). Quito: Laser Editores.
      </p>,
      <p>
        35. Maquiavelo, N. (1990). El Principe.XVIII: De qué modo los Principes
        deben Guardar la Fe dada (Primera ed.). (L. Cueva, Ed.) Loja: Dpto de
        Publicaciones de la Facultad de Jurisprudencia, UNL.
      </p>,
      <p>
        36. Márquez, Nicolaz. (2013). El Cuentero de Carondelet. Rafael y la
        Ideología Populista. Editorial Contracultura.
      </p>,
      <p>
        37. Marx, C. (1971). Critica de la Economía Política. El desarrollo de
        la producción capitalista. En C. Marx, El capital (pág. 689). Buenos
        Aires: Ediciones Signos S.R.L.
      </p>,
      <p>
        38. Matínez Hoyos, F. (12 de Septiembre de 2019).
        https://www.lavanguardia.com/. Obtenido de El Legado de Pablo Escobar:
        https://www.lavanguardia.com/historiayvida/historia-contemporanea/
      </p>,
      <p>
        39. Murphy, J. (2006). "El Poder de la Mente Subconsciente". Tecnicas
        Prácticas en las Curaciones Mentales. p. 88 - 89. Mexico, D. F. 1a
        Edición 1999. 13a Impresion, Julio 2006: Editorial Diana. Mexico.
      </p>,
      <p>
        40. Naciones Unidas. (1948). Naciones Unidas. La Declaración de los
        Derechos Humanos. Recuperado el 2 de Diciembre de 2020, de
        https://www.un.org/es/:
        https://www.un.org/es/universal-declaration-human-rights/
      </p>,
      <p>
        41. National Geographic. (2013). Reinos e Imperios de Próximo Oriente.
        Valladolid: Editec.
      </p>,
      <p>
        42. Ortiz, R. (21 de noviembre de 2018).
        org/biografías_lideres_políticos/africa/libia/muammar_al_gaddafi.
        Muammar al-Gaddafi. Recuperado el 27 de Noviembre de 2020, de cidob:
        https://www.cidob.org/biografias_lideres_politicos/africa/libia/muammar_al_gaddafi.
        Muammar al-Gaddafi.
      </p>,
      <p>
        43. Pellini, C. (2014). Historia y biografias. Guaardias Rojos de Mao en
        China - Gran Revolución Cultural. Recuperado el 25 de Enero de 2021, de
        Historias y Biografías: https://historiaybiografias.com/
      </p>,
      <p>
        44. Pericot, L. (1983). La división de los tiempos prehistóricos. En M.
        d. Pericot Luis, La Humanidad Prehistórica (pág. 22). Navarra: Salvat
        Editores, S.A.
      </p>,
      <p>
        45. Popov, N. (febrero de 1989). Perestroika. Quién está por encima del
        poder. SPUTNIK. Selecciones de la Prensa Soviética, 43.
      </p>,
      <p>
        46. Quintero, R. (1980). El mito del populismo en el Ecuador (Primera
        ed.). (FLACSO, Ed.) Quito: FLACSO editores.
      </p>,
      <p>
        47. Ramírez Olga. (09 de 12 de 2018).
        agnitio.pe/artículos/diferencia-entre-terrorismo-e-insurgencia.
        Recuperado el 22 de Octubre de 2020, de Agnitio:
        http://agnitio.pe/artículos/diferencia-entre-terrorismo-e-insurgencia-elementos.
      </p>,
      <p>
        48. Rivas, C. (01 de 03 de 2014).
        panfletonegro.com/v/2014/03/01/el-odio-como-politica-de-estado/.
        Recuperado el 28 de Diciembre de 2020, de panfleto negro:
        https://www.panfletonegro.com/v/2014/03/01/{" "}
      </p>,
      <p>
        49. Robert, O. (1994). Terranova. En O. Robert, Protagonistas del Mundo.
        Economía y Política (pág. 11). Santa Fe de Bogotá: Terranova editores.
      </p>,
      <p>
        50. Rodríguez, A. (2001). Realidad. Revista de Ciencias Sociales y
        Humanidades. "Izquierda y derecha en política. Significación
        Equívoca.(82), 469.
      </p>,
      <p>
        51. Rodríguez, O. (2009). Derechas y Ultraderechas en el mundo. (Segunda
        ed.). (S. XXI, Ed.) Buenos Aires: Siglo XXI editores.
      </p>,
      <p>
        52. Rosental, M. (1965). Diccionario Filosófico (Primera ed.). (P.
        Iudin, Ed.) Bogotá: Ediciones Nacionales.
      </p>,
      <p>
        53. Shajnazárov, G., Boborykin, A., Krasin, Y., & Sujodéiev, V. (11985).
        La Educación del Hombre Nuevo. En G. Shajnazárov, A. Boborykin, Y.
        Krasin, & V. Sujodéiev, Ciencia de la Sociedad. Moscú: Progreso.
      </p>,
      <p>
        54. Sosa P. Alberto. (1994). Protagonistas del Mundo. Historia General.
        Bogotá: Terranova Editores, Ltda.
      </p>,
      <p>
        55. Stamateas, B. (2017). Como identificar y tratar a las personas que
        te complican la vida para relacionarte sanamente. En B. Stamateas, Gente
        Tóxica. Lima, Perú: Biblioteca nacional del Perú.
      </p>,
      <p>
        56. The Economist. (19 de 12 de 2016). www.economist.com. Obtenido de
        The Economist: https://www.economist.com/the economist explains
      </p>,
      <p>
        57. Vargas Llosa, M. (2012). Desafíos a la libertad. (P. R. House, Ed.)
        Madrid: Grupo Editorial España.
      </p>,
      <p>
        58. Vargas, E. (17 de 01 de 2017). LOS 5 ERRORES FUNDAMENTALES DEL
        MARXISMO. Creer que el Socialismo es Humano. Creer que la desigualdad es
        el peor de los males. Recuperado el 29 de Diciembre de 2020, de
        lapatilla:
        https://www.lapatilla.com/2017/01/29/edgar-vargas-los-5-errores.
      </p>,
      <p>
        59. Wikipedia. (2016). Recuperado el 11 de Enero de 2020, de Wikipedia:
        https://es.wikipedia.org/wiki/Democracia_cristiana
      </p>,
      <p>
        60. Wikipedia. (30 de 01 de 2018). wikipedia/ La enciclopedia libre.
        Esclavitud en la antigua Roma . Recuperado el 14 de Noviembre de 2020,
        de Wikipedia: https://es.wikipedia.org/wiki/Historia de la esclavitud.
      </p>,
      <p>
        61. wikipedia. (s.f.). www.https://es.wikipedia.org. Recuperado el 6 de
        Noviembre de 2020, de wikipedia:
        www.https://es.wikipedia.org/wiki/Neonacismo
      </p>,
    ],
  },
];

export default dataMenu;

import { Button } from "react-bootstrap";
import imgPortada from "../assets/img/Portada-libro-floril.jpg";
function Portada() {
  return (
    <div class="container" style={{ marginTop: "20px" }}>
      <div class="row align-items-start">
        <div class="col-md">
          <input
            type="image"
            img
            src={imgPortada}
            class="img-fluid"
            alt="photo"
            style={{ height: "800px" }}
          />
        </div>
        <div class="col-md">
          <div style={{ alignItems: "center", marginBottom: "10px" }}>
            <Button
              variant="success"
              href="https://librosloja.com/libropdf/libro.pdf"
            >
              {" "}
              Descargar PDF{" "}
            </Button>
          </div>
          <h2>PRESENTACIÓN</h2>
          <p style={{ textAlign: "justify" }}>
            El texto constituye una introspección profunda sobre la
            interconexión entre la <b>libertad y la democracia</b> como esencia
            de los sistemas de gobierno civilizados. Explora su complejidad
            semántica y resalta las contradicciones inherentes a su aplicación.
            Se cuestiona la percepción común de que las elecciones a través del
            voto popular garanticen una democracia superior; y alerta la
            influencia de dogmatismos políticos-religiosos en las redes sociales
            que ponen en peligro la libertad. Examina críticamente a través de
            la historia, la demagogia de líderes populistas de izquierda y
            derecha con falsas promesas de cambios positivos en las campañas
            electorales en nombre de los sagrados principios de la libertad y la
            democracia. Estos gobernantes dogmáticos populistas, transformados
            en dictadores extremistas, no solamente han degradado las libertades
            individuales; sino, además han causado hecatombes sociales como las
            guerras, la pobreza, la emigración y la angustia popular. Y, los
            ciudadanos con derechos y obligaciones no estamos exentos de esta
            responsabilidad; somos corresponsables en la elección de nuestros
            destinos.
          </p>
          <p style={{ textAlign: "justify" }}>
            Es decir, este enfoque histórico-político pretende una
            concienciación <b>para superar los dogmatismos de izquierda y derecha </b>
            que amenaza la institucionalidad democrática, la libertad y la paz
            mundial. Nos recuerda cómo, héroes y heroínas cultores de justicia y
            transparencia en todas las sociedades se han batido en duelo contra
            la opresión, injusticia y corrupción. ¡Se aboga por un nuevo
            paradigma político!; una auténtica concienciación ideológica a nivel
            individual que trascienda hacia la sociedad en su conjunto. Aquello
            es posible, en las sociedades cultas superar estas disputas banales,
            eliminando falsos paradigmas, para adoptar el “justo medio”
            ideológico, el <b>Transversalismo</b>. Aquello permitirá al hombre y mujer
            en el S. XXI, emprender en tareas más trascendentales: la
            preservación del planeta, el desarrollo de nuestras potencialidades
            psíquicas-espirituales y el perfeccionamiento de la justicia, la
            libertad y la democracia.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Portada;
